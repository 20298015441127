(function () {

    'use strict';

    var settings = {
        target: '.mod-simple-promo-teaser-iphone-2017'
    };

    var setButtonPos = function () {

        $(settings.target).each(function () {

            if ('mobile' === vf.util.layout(true)) {

                $(this).find('.btn').css('top', '');
                $(this).find('.btn').css('left', '');
                $(this).find('.btn').css('margin-left', '');

            }
            else {

                $(this).find('.btn').each(function () {

                    $(this).css('top', $(this).data('top') + 'px');
                    $(this).css('left', $(this).data('left') + 'px');
                    $(this).css('margin-left', (-1 * $(this).outerWidth() / 2) + 'px');

                });

            }

            return true;

        });


    };

    var init = function () {

        $(window).on('vf::fontLoaded vf::resize orientationchange load', setButtonPos);

    };


    vf['simple-promo-teaser-iphone-2017'] = {
        settings: settings,
        init: init
    };

}(vf));
