(function () {//eslint-disable-line strict

    vf.tooltip = {
        settings: {
            target: '.mod-tooltip',
            htmlwrapper: '<div style="position:relative;"></div>',
            closeHtml: '<span class="close"><svg class="i-xsml"><use xlink:href="/simplicity/svgdefs.svg#i-tooltip-close-invert-lrg"/></svg></span>',
            closeIcon: 'i-tooltip-close-b-xsml',
            openIcon: 'i-tooltip-b-xsml',
            html: '<div class="arrow_box"><svg style="float:left;" class="i-sml"><use xlink:href="/simplicity/svgdefs.svg#i-warning-bl-lrg"/></svg>{{closeHtml}}<p>{{content}}</p><div class="cf"></div></div>'

        },

        init: function (context) {
            var s = this.settings;
            $(s.target, context).addBack(s.target).each(function () {
                if (0 === context.find('i').length) {
                    $(this).append('<svg style="display:block" class="i-xsml"><use xlink:href="/simplicity/svgdefs.svg#i-tooltip-invert-lrg"/></svg><svg style="display:none" class="i-xsml"><use xlink:href="/simplicity/svgdefs.svg#i-tooltip-close-invert-lrg"/></svg>');
                } else {
                    $(this).append('<i style="display:none" class="i-tooltip-close-b-xsml"/>');
                }
                var html = s.html;
                if ('true' !== $(this).data('opt').hideCloseOnCorner) {
                    html = html.replace('{{closeHtml}}', s.closeHtml);
                } else {
                    html = html.replace('{{closeHtml}}', '');
                }
                var content = '';
                if ($(this).data('opt').text) {
                    content += $(this).data('opt').text;
                }

                if ($(this).data('opt').html) {
                    var id = $(this).data('opt').html;
                    content += $('#' + id).html();
                }


                $(this).wrap(s.htmlwrapper).parent().before(html.replace('{{content}}', content));

                $(this).on('click', function () {
                    vf.tooltip.show($(this));
                });

                var $tooltip = $(this);

                $(this).parent().prev().find('span.close').on('click', function () {
                    $.proxy(vf.tooltip.show, $tooltip);
                });
            });
        },

        show: function (context) {
            context.parent().prev().toggle();
            context.children().toggle();
        },
        closeAll: function (context) {
            context.find('.arrow_box').css('display', 'none');
        }
    };
}(vf));
