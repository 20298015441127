(function () {//eslint-disable-line strict

    var settings = {
        target: '.mod-dark-site-overlay',
    };

    var init = function(){

        // in case there are more then one: just use the first!
        var $overlaySource = $($(settings.target).get(0));

        $('body').append($overlaySource);

    };

    vf['dark-site-overlay'] = {
        settings: settings,
        init: init
    };

}(vf));
