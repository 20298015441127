(function() {
    'use strict';
    vf['ws2-tiles'] = {
        settings: {
            target: 'mod-ws2-tiles',
        },

        init: function() {

            var tilesHolder = document.getElementsByClassName(this.settings.target);

            for (var i = 0; i < tilesHolder.length; i++){
                var myHolder = tilesHolder[i];
                var tiles = myHolder.getElementsByClassName('ws2-tile');
                // check for four tiles
                if (4 === tiles.length){
                    myHolder.classList.add('four-tiles');
                }
                // loop trough tiles
                for (var j = 0; j < tiles.length; j++){
                    var myTile = tiles[j];
                    // loop trough items
                    if ('svg' === myTile.children[1].tagName.toLocaleLowerCase()){
                        myHolder.classList.add('icon-container');
                    } else if ('picture' === myTile.children[1].tagName.toLocaleLowerCase()){
                        myHolder.classList.add('vendor-tile-container');
                    }
                }
            }
        }
    };
}(vf));
