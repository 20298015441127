(function () {

    'use strict';

    var settings = {
        overlaySelector: '.mod-mph-iphone-2017',
        contentSelector: '#content > .belt'
    };

    var $overlay;
    var $content;

    var addOffsetToContent = function () {
        var offset = $overlay.height() - $('header[role=banner]').height() + 20;

        $content.css({
            'padding-top': offset + 'px'
        });

    };

    var setHeight = function () {

        var $center = $(settings.overlaySelector).find('.mph_center');

        $center.css('height', '');
        $center.find('.h1').css('padding-top', '');

        var top = parseInt($center.css('top'), 10);
        var height = $center.outerHeight();

        var mphHeight = top + height;

        if (mphHeight < $(window).height()) {

            var newCenterHeight = $center.height() + $(window).height() - mphHeight;

            $center.height(
                newCenterHeight
            );

            $center.find('.h1').css('padding-top', (newCenterHeight - height) / 2 + 30);

            top = parseInt($center.css('top'), 10);
            height = $center.outerHeight();

            $(settings.overlaySelector).height(top + height);

        }
        else {

            $(settings.overlaySelector).height(top + height);
        }

    };


    var init = function () {
        $overlay = $(settings.overlaySelector);
        $content = $(settings.contentSelector);

        $(window).on('vf::resize', setHeight);
        setHeight();

        $(window).on('vf::resize', addOffsetToContent);
        addOffsetToContent();
    };

    vf['mph-iphone-2017'] = {
        settings: settings,
        init: init
    };

}(vf));

