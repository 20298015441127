(function () {

    'use strict';

    var vlux = (function () {

        var isInitialised = false;

        var vluxApi;
        var vluxSubscriptions;
        var vluxProducts;

        var initialise = function (scope) {

            scope = (undefined === scope) ? 'global' : scope;

            var deferred = $.Deferred(); // eslint-disable-line new-cap

            if (true === isInitialised) {
                deferred.resolve();
            }
            else {

                var globalApiPathname = '/api-static/ng/ecommerce';
                var globalApiPathnameStatic = '/api-static/ng/ecommerce';

                vluxApi = new vf.VluxAPI({
                    'pathname': globalApiPathname,
                    'pathnameStatic': globalApiPathnameStatic
                });

                vluxSubscriptions = new vf.VluxSubscriptions(vluxApi, 'mobile');

                vluxProducts = new vf.VluxProducts(vluxApi);

                $.when(vluxSubscriptions.loadData(), vluxProducts.loadData(scope)).done(function () {

                    isInitialised = true;

                    deferred.resolve();

                });

            }


            return deferred.promise();

        };

        var getDevices = function (tariffId, row, slotmachineHref, limit) {

            var deferred = $.Deferred(); // eslint-disable-line new-cap

            limit = (undefined === limit) ? 10 : limit;

            var vluxOffers = new vf.VluxOffers(vluxApi, vluxSubscriptions, {
                btx: 'newContract',
                salesChannel: 'Online.Consumer'
            });

            var getLowerstOnetimeOffer = function (products) {

                var lowerstOnetime = 100000;
                var lowerstOffer;
                var productWithLowest;

                for (var i = 0; i < products.product.length; i++) {

                    var oneTime = vluxOffers.getOnetimeFeeForDeviceById(
                        vluxOffers.getOfferByTariffAndDeviceId(tariffId, products.product[i].id),
                        products.product[i].id
                    );

                    oneTime = parseFloat(oneTime);

                    if (oneTime < lowerstOnetime) {
                        lowerstOnetime = oneTime;
                        productWithLowest = products.product[i];
                        lowerstOffer = vluxOffers.getOfferByTariffAndDeviceId(tariffId, products.product[i].id);
                    }
                }

                return {
                    offer: lowerstOffer,
                    product: productWithLowest
                };
            };


            $.when(vluxOffers.loadData()).done(function () {

                var devices = [];

                var micrositeApi = new vf.MicrositeAPI(vluxApi, vluxProducts);
                micrositeApi.getMobileRowByName(row).done(function () {

                    $.each(micrositeApi.getProducts(), function (i, virtualDevice) {

                        if (0 >= limit) {
                            return false;
                        }

                        var offer = null;
                        var hardware;
                        if (virtualDevice.products) {

                            var lowerstOffer = getLowerstOnetimeOffer(virtualDevice.products);
                            if (undefined === lowerstOffer.offer) {
                                return true;
                            }
                            offer = lowerstOffer.offer;
                            hardware = vluxProducts.getDeviceById(lowerstOffer.product.id);

                        } else {
                            offer = vluxOffers.getOfferByTariffAndDeviceId(tariffId, virtualDevice.id);
                            hardware = vluxProducts.getDeviceById(virtualDevice.id);

                        }

                        var image;
                        try {
                            image = vluxProducts.getImageByProduct(hardware);
                        }
                        catch (e) {
                            return true;
                        }

                        var price;

                        try {
                            price = vluxOffers.getOnetimeFeeForDeviceById(offer, hardware.id);
                        }
                        catch (e) {
                            return true;
                        }

                        devices.push({
                            'id': virtualDevice.id,
                            'imageSrc': image,
                            'name': virtualDevice.label,
                            'price': parseFloat(price).toFixed(2).replace('.', ',')
                        });

                        limit -= 1;
                        return true;


                    });

                    deferred.resolve(devices);

                });


            });

            return deferred.promise();
        };

        var getName = function () {
            return 'vlux';
        };

        return {
            getName: getName,
            initialise: initialise,
            getDevices: getDevices
        };

    })();

    vf.connect.vlux = vlux;

}(vf));
