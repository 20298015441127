(function () {//eslint-disable-line strict

    var settings = {
        target: '.mod-info-text',
        handleTextDefault: {
            full: 'Mehr',
            reduced: 'Weniger'
        }
    };

    var $handleTpl = $('<span class="info-text-handle"><span></span><svg class="i-xsml"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/simplicity/svgdefs.svg#"></use></svg></span>');


    var handleTpl = function (title, type) {
        var $tpl = $handleTpl.clone();

        $tpl.addClass('show-' + type);

        $tpl.find('span').html(title);

        if ('full' === type) {
            $tpl.find('use').attr('xlink:href', $tpl.find('use').attr('xlink:href') + 'i-arrow-down-sml');
        }

        if ('reduced' === type) {
            $tpl.find('use').attr('xlink:href', $tpl.find('use').attr('xlink:href') + 'i-arrow-up-sml');
        }

        return $tpl;
    };

    var prepare = (function () { // eslint-disable-line no-unused-vars
        $(settings.target).each(function () {

            var $details = $(this).find('.info-text-details');
            if (undefined === $details.get(0)) {
                return true;
            }

            var textFull = $(this).attr('data-full-text');

            if (undefined === textFull) {
                textFull = settings.handleTextDefault.full;
            }

            var textReduced = $(this).attr('data-reduced-text');

            if (undefined === textReduced) {
                textReduced = settings.handleTextDefault.reduced;
            }

            $details.before(handleTpl(textFull, 'full'));
            $details.after(handleTpl(textReduced, 'reduced'));

            if (true === $(this).hasClass('full')) {
                return true;
            }

            $(this).addClass('reduced');

            return true;
        });
    })();

    var handleToogle = function () {

        var $infoText = $(this).parents(settings.target);

        if (true === $(this).hasClass('show-full')) {
            $infoText.removeClass('reduced');
            $infoText.addClass('full');
        }
        else if (true === $(this).hasClass('show-reduced')) {
            $infoText.removeClass('full');
            $infoText.addClass('reduced');
        }

    };

    var init = function () {

        $(settings.target).on('click', '.info-text-handle', handleToogle);

    };

    vf['info-text'] = {
        settings: settings,
        init: init
    };

}(vf));
