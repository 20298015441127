(function() {

    'use strict';

    vf['reference-gallery'] = {
        settings: {
            target: '.mod-reference-gallery',
            filterMenuButton: '.mod-filter-menu-button',
            filterBody: '.mod-filter-body',
            filterTile: '.mod-filter-tile',
            filterAll: '.mod-filter-all',
            filterCloseButton: '.mod-filter-close-button',
            filterKeywords: [],
            referenceTile: '.mod-reference-tile'
        },

        init: function(context) {
            var s = this.settings;
            $(s.target, context).addBack(s.target).each(function() {

                if (false === $(s.filterMenuButton).hasClass('init')) {

                    $(s.filterMenuButton).addClass('init');

                    $(s.filterMenuButton, s.target).on('click', function() {
                        $(this).toggleClass('active');
                        $(s.filterBody, s.target).slideToggle();
                        return false;
                    });

                    $(s.filterTile, s.target).on('click', function() {

                        if (!$(this).hasClass(s.filterAll.substr(1))) {
                            // if a filter is selected remove active state of 'all'-filter
                            $(s.filterAll).removeClass('active');

                            // toggle actie class of selected filter tile
                            $(this).toggleClass('active');

                            if (-1 === $.inArray($(this).attr('data-filter-keyword'), s.filterKeywords)) {
                                // keyword not found, so push filter keyword to keywords array
                                s.filterKeywords.push($(this).attr('data-filter-keyword'));
                            } else {
                                // keyword found, so remove it
                                s.filterKeywords.splice($.inArray($(this).attr('data-filter-keyword'), s.filterKeywords), 1);
                            }

                            // if filter array is empty active the 'all'-filter tile
                            if (0 === s.filterKeywords.length) {
                                $(s.filterAll, s.target).addClass('active');
                            }

                        } else {
                            // else select the 'all'-filter and remove active state of each other filter
                            $(s.filterTile, s.target).each(function() {
                                $(this).removeClass('active');
                            });
                            $(s.filterAll, s.target).addClass('active');

                            // clear keywords array
                            s.filterKeywords = [];
                        }

                        // filter references
                        $(s.referenceTile, s.target).each(function() {
                            $(this).addClass('hidden');
                        });

                        setTimeout(function() {
                            $(s.referenceTile + '.hidden', s.target).each(function() {
                                $(this).css('position', 'absolute');
                            });

                            $(s.referenceTile, s.target).each(function() {
                                // if filter array is empty show all reference tiles
                                if (0 === s.filterKeywords.length) {
                                    $(this).css('position', '');
                                    $(this).removeClass('hidden');
                                } else {
                                    if (-1 === $.inArray($(this).attr('data-filter-keyword'), s.filterKeywords)) {
                                        //$(this).addClass('hidden');
                                    } else {
                                        $(this).css('position', '');
                                        $(this).removeClass('hidden');
                                    }
                                }
                            });
                        }, 300);

                        return false;

                    });

                    $(s.filterCloseButton, s.target).on('click', function() {
                        $(s.filterMenuButton).removeClass('active');
                        $(s.filterBody, s.target).slideToggle();
                        return false;
                    });

                    /* Video dialog behavior */
                    $('.reference-video .video-link', s.target).on('click', function() {
                        var referenceVideoLink = $(this);
                        var dialogId = $(this).attr('data-href');
                        var videoDialog;
                        var videoIframe; // eslint-disable-line no-unused-vars

                        if ($(dialogId).hasClass('video-started')) {
                            var videoDialog = document.getElementById(dialogId.substr(1));
                            videoIframe = videoDialog.getElementsByTagName('iframe')[0].contentWindow;
                            //videoIframe.postMessage('{"event":"command","func":"playVideo","args":""}', '*');
                            $(dialogId).addClass('video-is-running');
                        } else {
                            $(dialogId).addClass('video-started');
                            $(dialogId).addClass('video-is-running');
                            setTimeout(function() {
                                if (600 <= $(window).width()) {
                                    $('a.youtube-play').trigger('click');
                                }
                                videoDialog = document.getElementById(dialogId.substr(1));
                                videoIframe = videoDialog.getElementsByTagName('iframe')[0].contentWindow;
                            }, 500);
                        }

                        $('.di-close').on('click', function() {
                            $(dialogId).removeClass('video-is-running');
                            //videoIframe.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
                            var youtubeId = $('.youtube', dialogId).attr('id');
                            referenceVideoLink.attr('data-href', youtubeId);
                            referenceVideoLink.append('<div class="di-content claviature-video-overlay"></div>');
                            $(dialogId).remove();
                            $('body').css('overflow', '');
                        });
                    });



                    /* Disable the hover effect while page scrolling */
                    var timer;
                    $(window).on('scroll', function(){
                        clearTimeout(timer);
                        $(s.referenceTile, s.target).css('pointer-events', 'none');
                        timer = setTimeout(function() {
                            $(s.referenceTile, s.target).css('pointer-events', '');
                        }, 100);
                    });


                    /* so the buttons are not triggreren while the css animation is running ...
                    this only applies for touch devies */
                    if (true === vf.util.is_touch_device) {
                        $(s.referenceTile, s.target).find('.reference-detail-button').css('pointer-events', 'none');
                        $(s.referenceTile, s.target).find('.reference-video a').css('pointer-events', 'none');
                        $(s.referenceTile, s.target).find('.reference-pdf a').css('pointer-events', 'none');

                        $(s.referenceTile, s.target).on('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend',
                            function() {
                                $(s.referenceTile, s.target).each(function() {
                                    if ('rgb(255, 255, 255)' === $(this).css('background-color') ) {
                                        $(this).find('.reference-detail-button').css('pointer-events', 'auto');
                                        $(this).find('.reference-video a').css('pointer-events', 'auto');
                                        $(this).find('.reference-pdf a').css('pointer-events', 'auto');
                                    } else {

                                        $(this).find('.reference-detail-button').css('pointer-events', 'none');
                                        $(this).find('.reference-video a').css('pointer-events', 'none');
                                        $(this).find('.reference-pdf a').css('pointer-events', 'none');
                                    }
                                });
                            });

                        $(s.referenceTile, s.target).mouseleave(function() {
                            $(s.referenceTile, s.target).find('.reference-detail-button').css('pointer-events', 'none');
                            $(s.referenceTile, s.target).find('.reference-video a').css('pointer-events', 'none');
                            $(s.referenceTile, s.target).find('.reference-pdf a').css('pointer-events', 'none');
                        });
                    }
                }
            });
        }
    };
}(vf));
