/**
 * @component iphone-x
 * @author Marcel Weiler
 *
 * Javascripts
 */

(function() {
    'use strict';
    // write your code
})();
