(function () {//eslint-disable-line strict

    var settings = {
        target: '.mod-stage-show',
        item: '.show-item',
        slideshow: {
            duration: 2000,
            delay: 4000
        }
    };

    var stageHeight = 450;
    var showModus = 'video'; //eslint-disable-line no-unused-vars

    var $videosWrap;

    var getStartItem = function () {

        var startVideo = $(settings.target).data('start-video') - 1;

        if (startVideo >= $videosWrap.length) {
            startVideo = 0;
        }

        return startVideo;

    };

    var getNextItem = function () {

        var $actualVideoWrap = $(settings.target).find('.current');
        var actualIndex = $actualVideoWrap.data('index');

        // order
        // try to get a video that has not alreay played and that is not the actual video
        // try to get a video that is not the actual video even it has played
        // loop  is the actual video

        var $playOneOfThese = $videosWrap
            .parents(settings.target)
            .find(settings.item + '.loaded')
            .not('.shown')
            .not('[data-index=' + actualIndex + ']');

        if (0 === $playOneOfThese.length) {
            $playOneOfThese = $videosWrap
                .parents(settings.target)
                .find(settings.item + '.loaded')
                .not('[data-index=' + actualIndex + ']');
        }

        if (0 === $playOneOfThese.length) {
            return $actualVideoWrap;
        }

        var random = Math.floor(Math.random() * $playOneOfThese.length);

        return $($playOneOfThese.get(random));
    };

    var offsetVideo = function ($videoWrap) {

        var height = $videoWrap.width() * $videoWrap.data('ratio');

        var offsetTop = 0;

        var elemOffsetTop = $videoWrap.data('offset-top');

        if (false === $.isNumeric(elemOffsetTop)) {
            elemOffsetTop = 0;
        }

        if (undefined !== elemOffsetTop) {
            offsetTop += parseInt(elemOffsetTop, 10);
        }

        /*
        if('desktop' === vf.util.layout(true)){
            var additionalOffsetTop = -100;
            if(height <= stageHeight + 100){
                additionalOffsetTop = stageHeight - height;
            }

            offsetTop += additionalOffsetTop;

        }
        */

        $videoWrap.css('top', offsetTop + 'px');

        if (height + offsetTop > stageHeight) {
            $videoWrap.parents('.mod-stage-show.modus-video').css({
                height: '',
                overflow: '',
            });
        }
        else {
            $videoWrap.parents('.mod-stage-show.modus-video').css({
                height: height + offsetTop,
                overflow: 'hidden',
            });
        }

    };


    var videoShow = function () {

        var mediaelementPlayers = {};

        var getMediaelementplayerByElement = function ($videoWrap) {
            var index = $videoWrap.data('index');

            return mediaelementPlayers[index];
        };
        var setMediaelementplayerByElement = function ($videoWrap, mediaelement) {
            var index = $videoWrap.data('index');
            mediaelementPlayers[index] = mediaelement;
        };

        var playNext = function ($currentVideoWrap, $nextVideoWrap) {

            $currentVideoWrap.css({
                'top': '1000px'
            });

            offsetVideo($nextVideoWrap);

            var nextMediaelement = getMediaelementplayerByElement($nextVideoWrap);
            nextMediaelement.play();
        };

        var initAndLoadVideo = function ($videoWrap) {

            var deferred = $.Deferred(); //eslint-disable-line new-cap

            var $video = $videoWrap.find('video');
            if (0 === $video.length) {
                deferred.reject($videoWrap);
            }

            $video.mediaelementplayer({
                alwaysShowControls: false,
                pauseOtherPlayers: true,
                videoWidth: '100%',
                enableAutosize: true,
                startVolume: 0.0,
                playbackRate: 3.0,
                success: function (mediaElement) {

                    setMediaelementplayerByElement($videoWrap, mediaElement);

                    $videoWrap.addClass('loaded');
                    mediaElement.addEventListener('play', function () {
                        $videoWrap.addClass('current');
                    });

                    mediaElement.addEventListener('ended', function () {
                        $next = getNextItem();
                        $videoWrap.removeClass('current');
                        $videoWrap.addClass('shown');


                        playNext($videoWrap, $next);
                    });

                    deferred.resolve(mediaElement);
                },
                error: function () {
                    deferred.reject($videoWrap);
                }
            });

            return deferred.promise();
        };

        $(settings.target).addClass('modus-video');

        $videosWrap.each(function () {
            $(this).find('.slideshow').remove();

            var $video = $(this).find('video');

            $(this).attr('data-ratio', $video.height() / $video.width());
        });


        var startVideo = getStartItem();

        $videosWrap.each(function (index) {

            if (index === startVideo) {
                return true;
            }

            $(this).find('img.video-loading').remove();

            return true;
        });


        var $videoWrapToPlayFirst = $($videosWrap.get(startVideo));

        offsetVideo($videoWrapToPlayFirst);


        initAndLoadVideo($videoWrapToPlayFirst)
            .then(
            function (mediaElement) {
                $videoWrapToPlayFirst.find('img.video-loading').remove();
                //$videoWrapToPlayFirst.find('img.video-loading').css('opacity', 0.5);
                mediaElement.play();

                // now: the first video is loaded and already plays
                // so load the other videos, too
                $videosWrap.each(function () {
                    if ($(this).hasClass('loaded')) {
                        return true;
                    }
                    initAndLoadVideo($(this));
                    return true;
                });

            },
            function ($videoWrap) {
                $videoWrap.remove();
            }
            );


        $(window).resize(function () {
            offsetVideo($(settings.target).find('.current'));
        });

    };

    var slideShow = function () {

        var $current = $(settings.target).find('.current');

        var $next = getNextItem();
        $next.addClass('next');

        $next.animate({
            opacity: 1
        }, {
            duration: settings.slideshow.duration,
            step: function (now) {
                $current.css('opacity', 1 - now);
            },
            done: function () {
                $current.addClass('shown');
                $current.removeClass('current').removeAttr('style');

                $next.addClass('current').removeClass('next').removeAttr('style');

                window.setTimeout(slideShow, settings.slideshow.delay);
            }
        });

    };

    var imageShow = function () {

        $(settings.target).addClass('modus-image');

        $videosWrap.each(function () {
            $(this).find('.video').remove();
        });

        var startVideo = getStartItem();

        $($videosWrap.get(startVideo)).addClass('current');

        $(settings.target + ' .slideshow img').one('load', function () {

            var $videoWrap = $(this).parents(settings.item);

            $videoWrap.addClass('loaded');

            var $slideshow = $videoWrap.find('.slideshow');

            var $img = $slideshow.find('img');
            $slideshow.addClass('bg-cover');
            $slideshow.css('background-image', 'url(' + $img.attr('src') + ')');
            $img.remove();

            $slideshow.height(stageHeight);


        }).each(function () {

            if (this.complete) {
                $(this).load();
            }
        });

        window.setTimeout(slideShow, settings.slideshow.delay);
    };

    var rescaleTablet = function () {
        var unscaledHeight = 450;

        if (true === $(settings.target).hasClass('level-3')) {
            unscaledHeight = 400;
        }

        stageHeight = Math.ceil($(settings.target).width() * unscaledHeight / 1248);

        $(settings.target).height(stageHeight);
        $(settings.target).find('.show-item .slideshow').height(stageHeight);
    };

    var rescaleDesktop = function () {
        var unscaledHeight = 450;

        if (true === $(settings.target).hasClass('level-3')) {
            unscaledHeight = 400;
        }
        stageHeight = Math.ceil($(settings.target).width() * unscaledHeight / 1248);
        if (450 > stageHeight) {
            $(settings.target).height(stageHeight);
            $(settings.target).find('.show-item .slideshow').height(stageHeight);
        } else {
            //console.log("rescaled height:" + stageHeight + "width: " + $(settings.target).width());
        }

    };

    var init = function () {

        var layout = vf.util.layout(true);

        if ('mobile' === layout) {
            showModus = 'none';
            return;
        }

        $videosWrap = $(settings.target + ' ' + settings.item);

        $videosWrap.each(function (index) {
            $(this).attr('data-index', index);
        });

        stageHeight = $(settings.target).height();
        // showmodus is only defined on load
        // there is no resize switching
        if ('desktop' === layout) {
            videoShow();
        }
        else if ('tablet' === layout) {

            if (0 === $('.enterprise').length) {
                rescaleTablet();
            }

            imageShow();
        }

        $(window).resize(function () {

            var checkLayout = vf.util.layout(true);

            if ('tablet' === checkLayout) {

                if (0 === $('.enterprise').length) {
                    rescaleTablet();
                }

            }
            else {
                if (0 === $('.enterprise').length) {
                    rescaleDesktop();
                    //$(settings.target).css('height', '');
                    //$(settings.target).find('.show-item .slideshow').css('height', '');
                }
            }

        });

    };

    vf['stage-show'] = {
        settings: settings,
        init: init
    };

}(vf));
