(function() {//eslint-disable-line strict

    vf.tabs = {
        settings: {
            target: '.mod-tabs',
            nav: '.mod-tabs-nav',
            content: '.mod-tabs-content'
        },

        init: function(context) {

            var s = this.settings;
            $(s.target, context).addBack(s.target).each(function() {
                var container = $(this);
                var navItems;

                var nav = $(s.nav, container).filter(function() {
                    return $(this).closest(s.target)[0] === container[0];
                });

                var contents = $(s.content, container).filter(function() {
                    return $(this).closest(s.target)[0] === container[0];
                });
                // set the width fix, the shadow on the right shouldn't display
                container.css('width', container.width());

                nav.each(function() {
                    navItems = $(this).children('li');
                    navItems.each(function() {
                        var navLinks = $(this).children('a');
                        navLinks.each(function() {
                            var link = $(this);
                            /*@TODO What does this line */var target = link.children('a').length ? link.children('a') : link; //eslint-disable-line no-unused-vars
                        });
                    });
                });

                vf.tabs.calculateMobileTabWidth(container, navItems);

                contents.each(function() {
                    $(this).hide();

                    var jumpMarkToAnotherTab = $(this).find('.reload-page-jumpmark');
                    if (0 < jumpMarkToAnotherTab.length) {
                        jumpMarkToAnotherTab.on('click', function() {
                            var jumpmark = $(this).attr('href');
                            if (undefined !== jumpmark && 0 < jumpmark.length && 'no-data' !== jumpmark) {
                                window.location.assign(jumpmark);
                                window.location.reload();
                            }

                        });
                    }
                });

                vf.tabs.initActiveTab(container, navItems, contents);


                var linkHash = window.location.hash;
                if ('' !== linkHash) {
                    $(function() {
                        try {
                            $('a[href="' + linkHash + '"]').parent('li').trigger('click');

                            $('html, body').animate({
                                scrollTop: $(linkHash).offset().top - $('a[href="' + linkHash + '"]').parent('li').outerHeight() + 'px'
                            }, 'slow');

                            if (600 > $(window).width() && 2 < navItems.length) {
                                if (1 === $(linkHash, container).length) {
                                    var scrollWidth = (($('a[href="' + linkHash + '"]').parent('li').index() - 1) * $('a[href="' + linkHash + '"]').parent('li').outerWidth()) + ($('a[href="' + linkHash + '"]').parent('li').outerWidth() / (navItems.length - 1));
                                    container.children('ul').animate({
                                        scrollLeft: scrollWidth + 'px'
                                    }, 'slow');
                                }
                            }
                        } catch (e) {}
                    });
                }


                navItems.on('click', function() {
                    navItems.each(function() {
                        $(this).removeClass('active');
                    });
                    var link = $(this).children('a');
                    var tabId = link.attr('href');
                    $(this).addClass('active');

                    contents.each(function() {
                        $(this).hide();
                        $(this).removeClass('active');
                    });

                    $(tabId).show();
                    $(tabId).addClass('active');

                    if ('object' === typeof vf['eq-height']) {
                        vf['eq-height'].init();
                    }

                    $(window).trigger('vf::tabsSwitch');

                    return false;
                });

                $(window).on('resize', function() {
                    vf.tabs.calculateMobileTabWidth(container, navItems);
                });

            });

        },

        calculateMobileTabWidth: function(container, navItems) {
            // set the width to 100% to get the original right size
            container.css('width', '100%');
            // set the width fix, the shadow on the right shouldn't display
            container.css('width', container.width());

            navItems.each(function() {
                if (600 > $(window).width()) {
                    if (2 >= navItems.length) {
                        var width = container.children('ul').outerWidth() / 2;
                        $(this).css('min-width', width);
                    } else {
                        var width = (container.children('ul').outerWidth() * 40 / 100);
                        $(this).css('min-width', width);
                    }
                } else {
                    $(this).css('min-width', '');
                }
            });
        },

        initActiveTab: function(container, tabs, contents) {
            var tabNumberFromCMS = container.attr('data-opentab'); // must be between 1 and <num tabs>
            var isNoTabPreselected = 'undefined' === (typeof tabNumberFromCMS) || '' === tabNumberFromCMS || 0 >= tabNumberFromCMS.length;

            var setSelectedTabAndContent = function(selectedTab, selectedContent) {
                $(selectedTab, container).addClass('active');
                $(selectedContent, container).addClass('active').show();
            };

            if (isNoTabPreselected) {
                var hasAtLeastOneTab = 0 < tabs.length;
                if (!hasAtLeastOneTab) {
                    return; // skip if no tabs have been defined at all in tab bar
                }

                setSelectedTabAndContent(tabs[0], contents[0]);
                return;
            }

            var selectedTabIndex = vf.tabs.getValidTabIndex(tabNumberFromCMS, tabs.length);
            setSelectedTabAndContent(tabs[selectedTabIndex], contents[selectedTabIndex]);

            vf.tabs.centerActiveTabHorizontally(container, tabs[selectedTabIndex]);
        },

        getValidTabIndex: function(selectedTabAttribute, numberOfTabs) {
            var selectedTabIndex = Math.max(0, parseInt(selectedTabAttribute) - 1);
            if (selectedTabIndex > numberOfTabs - 1) {
                return 0;
            }

            return selectedTabIndex;
        },

        centerActiveTabHorizontally: function(container, selectedTab) {
            var resize = function(tabContainer, selTab) {
                var tabBar = tabContainer.children(vf.tabs.settings.nav).first();

                var overflowLength = tabBar[0].scrollWidth;
                var displayWidth = tabBar.width();

                if (overflowLength <= displayWidth) {
                    return;
                }

                tabBar.scrollLeft(0);

                var selectedTabJQ = $(selTab);
                var selectedTabLeft = selectedTabJQ.position().left;
                var tabWidth = selectedTabJQ.width();

                var centerOffset = selectedTabLeft - (displayWidth / 2) + (tabWidth / 2);
                tabBar.scrollLeft(centerOffset);
            };

            $(window).on('resize', function() {
                resize(container, selectedTab);
            });
            resize(container, selectedTab);
        }
    };

}(vf));
