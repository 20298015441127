/**
 * Helper which allows to read Url Parameter and stringify them back
 * also allows to filter only the needed parameters
 */
(function () {
    'use strict';

    var extractParamStringFromUrl = function (url) {
        if (url && 'string' !== typeof url) {
            throw new Error('If url is provided it should be a string');
        }

        url = url || window.location.search;

        if (!url || 0 > url.indexOf('?')) {
            return '';
        }

        var paramString = url.substr(url.indexOf('?') + 1);
        return paramString;
    };

    var getUrlParamsAsObject = function (url, filter) {
        var paramString = extractParamStringFromUrl(url);

        if (1 > paramString.length) {
            return {};
        }
        // remove the starting "?"
        var params = {};
        var pairs = paramString.split('&');
        pairs.forEach(function (pair) {
            var keyValue = pair.split('=');
            if (!filter || (filter && -1 < filter.indexOf(keyValue[0]))) {
                var key = keyValue.shift();
                params[key] = keyValue.join('=');
            }
        });
        return params;

    };


    var toString = function (obj) {
        var paramArray = [];
        for (var key in obj) {
            paramArray.push(key + '=' + obj[key]);
        }
        if (0 < paramArray.length) {
            return '?' + paramArray.join('&');
        }
        return '';
    };

    var mergeParams = function (url1, url2, filter) {
        var paramString1 = getUrlParamsAsObject(url1);
        var paramString2 = getUrlParamsAsObject(url2, filter);
        return Object.assign(paramString1, paramString2);
    };

    var mergeParamsAsString = function (url1, url2, filter) {
        var params = mergeParams(url1, url2, filter);
        return toString(params);
    };

    var mergeUrls = function (url1, url2, filter) {
        var url = url1.split('?')[0];
        var params = mergeParams(url1, url2, filter);
        return url + toString(params);
    };


    window.vf['url-parameter-parser'] = {
        'getUrlAsObject': getUrlParamsAsObject,
        'mergeParams': mergeParams,
        'mergeUrls': mergeUrls,
        'toString': toString,
        'mergeParamsAsString': mergeParamsAsString
    };
})();
