(function () { //eslint-disable-line strict
    vf['lh-nav'] = {
        settings: {
            target: '.mod-lh-nav',
            showSubMenu: '.sub-menu-show',
            hideSubMenu: '.sub-menu-hide'
        },

        init: function (context) {
            var s = this.settings;

            $(s.target, context).addBack(s.target).each(function () {
                $(s.showSubMenu, s.target).on('click', function (e) {
                    var el = $(this);
                    el.closest('li').addClass('open');
                    el.hide();
                    el.siblings().show();
                    e.preventDefault();
                });

                $(s.hideSubMenu, s.target).on('click', function (e) {
                    var el = $(this);
                    el.closest('li').removeClass('open');
                    el.hide();
                    el.siblings().show();
                    e.preventDefault();
                });


                var dropdownWidth = $('.tg-head', '.left-hand-navigation').width();
                var dropdownBreadcrumbItems = $('.tg-head > div').children('span');
                var visibleDropdownBreadcrumbItemsWidth = $(dropdownBreadcrumbItems.get(dropdownBreadcrumbItems.length - 2)).width() + $(dropdownBreadcrumbItems.get(dropdownBreadcrumbItems.length - 1)).width() + 20;
                if (600 > $(window).width()) {
                    if (2 <= dropdownBreadcrumbItems.length) {
                        if (visibleDropdownBreadcrumbItemsWidth > dropdownWidth) {
                            $(dropdownBreadcrumbItems.get(dropdownBreadcrumbItems.length - 2)).hide();
                        }
                    }
                } else if (600 <= $(window).width() && 768 > $(window).width()) {
                    if (3 <= dropdownBreadcrumbItems.length) {
                        visibleDropdownBreadcrumbItemsWidth = $(dropdownBreadcrumbItems.get(dropdownBreadcrumbItems.length - 3)).width() + $(dropdownBreadcrumbItems.get(dropdownBreadcrumbItems.length - 2)).width() + $(dropdownBreadcrumbItems.get(dropdownBreadcrumbItems.length - 1)).width() + 20;
                        if (visibleDropdownBreadcrumbItemsWidth > dropdownWidth) {
                            $(dropdownBreadcrumbItems.get(dropdownBreadcrumbItems.length - 3)).hide();
                        }
                    }
                }

                $(window).on('resize', function () {
                    dropdownWidth = $('.tg-head', '.left-hand-navigation').width();
                    if (600 > $(window).width()) {
                        if (3 <= dropdownBreadcrumbItems.length) {
                            $(dropdownBreadcrumbItems.get(dropdownBreadcrumbItems.length - 3)).hide();
                        }
                        if (2 <= dropdownBreadcrumbItems.length) {
                            visibleDropdownBreadcrumbItemsWidth = $(dropdownBreadcrumbItems.get(dropdownBreadcrumbItems.length - 2)).width() + $(dropdownBreadcrumbItems.get(dropdownBreadcrumbItems.length - 1)).width() + 20;
                            if (visibleDropdownBreadcrumbItemsWidth > dropdownWidth) {
                                $(dropdownBreadcrumbItems.get(dropdownBreadcrumbItems.length - 2)).hide();
                            } else {
                                $(dropdownBreadcrumbItems.get(dropdownBreadcrumbItems.length - 2)).show();
                            }
                        }
                    } else if (600 <= $(window).width() && 786 > $(window).width()) {
                        if (3 <= dropdownBreadcrumbItems.length) {
                            visibleDropdownBreadcrumbItemsWidth = $(dropdownBreadcrumbItems.get(dropdownBreadcrumbItems.length - 3)).width() + $(dropdownBreadcrumbItems.get(dropdownBreadcrumbItems.length - 2)).width() + $(dropdownBreadcrumbItems.get(dropdownBreadcrumbItems.length - 1)).width() + 20;
                            if (visibleDropdownBreadcrumbItemsWidth > dropdownWidth) {
                                $(dropdownBreadcrumbItems.get(dropdownBreadcrumbItems.length - 3)).hide();
                            } else {
                                $(dropdownBreadcrumbItems.get(dropdownBreadcrumbItems.length - 3)).show();
                            }
                        }
                    }
                });
            });
        }
    };
}(vf));
