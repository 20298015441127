(function() {

    vf['radio-table'] = {
        settings: {
            target: '.mod-radio-table'
        },

        init: function(context) {
            var s = this.settings;

            $(s.target, context).addBack(s.target).each(function() {
                var table = $(this);

                $('input:checked', table).closest('tr').addClass('checked');

                table.on('click', 'td', vf['radio-table'].selectRadio);
                table.on('change', 'input[type=radio]', vf['radio-table'].highlightRow);
            });
        },

        selectRadio: function(e) {

            if ($(e.target).is('label, input, a, button, .tg-tooltip *') || 'sml' === vf.util.layout()) {return;}

            $(e.target).closest('tr').find('input[type=radio]').trigger('click');

        },

        highlightRow: function(e) {
            var rows = $('tr', e.delegateTarget);

            rows.removeClass('checked');
            $(e.target).closest('tr').addClass('checked');
        }
    };
}(vf));
