/**
 * @component enterprise-modal
 * @author Jochen Mueller
 *
 * Javascripts
 */

(function() {
    'use strict';
    // write your code
})();
