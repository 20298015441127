(function() {
    'use strict';

    var settings = {
        target: '.mod-tariff-detail-akko',
    };

    var calcClosedHeight = function($this) {
        $this.removeClass('active');
        var detail1Height = parseInt($this.find('.detail .icon-list li:nth-child(1)').css('height'));
        var detail2Height = parseInt($this.find('.detail .icon-list li:nth-child(2)').css('height'));
        return detail1Height + detail2Height;
    };

    var setDetailHeight = function(context) {
        var s = settings;

        $(s.target, context).addBack(s.target).each(function() {
            var $this = $(this);
            var detailsHeight = calcClosedHeight($this);
            $this.find('.detail').css({ 'height': detailsHeight + 22 + 'px' });
            if (2 >= ($this.find('.detail .icon-list li').length + $this.find('.detail .details-link').length)) {
                $this.find('.foot').css({ 'display': 'none' });
            }
        });
    };

    var toggleAccordion = function(e) {
        var s = settings;
        var $this = $(e.target).closest(s.target);
        var listHeight = parseInt($this.find('.detail .icon-list').css('height'));
        if (0 < parseInt($this.find('.detail .details-link').length)) {
            listHeight += parseInt($this.find('.detail .details-link').outerHeight());
        }

        if ($this.hasClass('active')) {
            $this.find('.detail').animate({
                height: calcClosedHeight($this) + 22 + 'px'
            }, 500 );
            $this.removeClass('active');
        } else {
            $this.find('.detail').animate({
                height: listHeight + 17 + 'px'
            }, 500 );
            $this.addClass('active');
        }
    };

    var init = function(context) {
        var s = settings;

        $(s.target).find('.foot').on('click', toggleAccordion);

        $(window).on('vf::resize', setDetailHeight(context));
        setDetailHeight(context);
        // windows chrome fix to prevent first element of showing three instead of list entries
        setTimeout(function() { setDetailHeight(context); }, 200);

    };
    vf['tariff-detail-akko'] = {
        settings: settings,
        init: init,
        setDetailHeight: setDetailHeight
    };
}(vf));
