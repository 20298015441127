(function() {

    'use strict';

    var settings = {
        overlaySelector: '.mod-mph',
        contentSelector: '#content > .belt'
    };

    var $overlay;
    var $content;

    var addOffsetToContent = function(){
        var offset = $overlay.height() - $('header[role=banner]').height() + 20;

        $content.css({
            'padding-top': offset + 'px'
        });

    };

    var init = function(){
        $overlay = $(settings.overlaySelector);
        $content = $(settings.contentSelector);

        $(window).on('vf::resize', addOffsetToContent);
        addOffsetToContent();
    };

    vf.mph = {
        settings: settings,
        init: init
    };

}(vf));

