$(function() {

    'use strict';

    var settings = {
        target: '.mod-herospace2-basic',
        contentSelector: '#content > .belt'
    };

    var $pager;
    var $content;

    var sliderHight = 0;
    var moduleHeight = 0;

    var useDotNavi = false;

    var $bxslider = null;

    var slidesTracked = {

    };

    var defaultTrackingCategory = '';

    var sendingTracking = function() {

        // Not a good spot for this, but needed quickly
        $(window).trigger('vf::herospace2-item-slide');

        if ('' === defaultTrackingCategory) {
            defaultTrackingCategory = $($('#nav-menu .nav-selected').find('a').get(0)).text();

            if ('' === defaultTrackingCategory) {
                defaultTrackingCategory = $($('ul[role="menubar"] li.nav-aux-current a').get(0)).text();
            }
            defaultTrackingCategory = defaultTrackingCategory.replace(/\s/g, '');
        }

        var trackingCategory = defaultTrackingCategory;

        /**
         * This function listens on beforeSlide, BUT $bxslider.getCurrentSlide() returns the slide index that will be active after slider
         * So i resolve the slide by fetching the active navi elem
         */
        var $naviOfSlide = $('#bx-pager a.active');

        // obviously no pager element is currently active, take the very first one
        if (0 === $naviOfSlide.length) {
            $naviOfSlide = $($('#bx-pager a').get(0));
        }

        var theme = $naviOfSlide.data('internal-campaign');
        if ('' === theme || undefined === theme || null === theme) {
            theme = $naviOfSlide.text();
        } else {
            trackingCategory = '';
        }

        if (undefined !== theme && '' !== theme) {
            theme = theme.replace(/\s/g, '');
            theme = theme.replace(/%/g, 'Prozent');
        }

        trackingCategory = $.trim(trackingCategory);
        theme = $.trim(theme);

        // prevent tracking double impressions of the same parameter
        if (undefined !== slidesTracked[trackingCategory + '-' + theme]) {
            return;
        }

        slidesTracked[trackingCategory + '-' + theme] = true;

        if (undefined === window._ddq) {
            window._ddq = [];
        }

        var idString = trackingCategory + ':' + theme;
        if ('' === trackingCategory) {
            idString = theme;
        }

        _ddq.push([
            'banner view', {
                id: idString
            }
        ]);
    };

    /**
     * Update item
     * @param {string} - viewport - either desktop or mobile
     * @param {object} - item - a reference to the slide / list element
     */
    var updateItem = function(viewport, $item) {

        if ('mobile' === viewport) {
            $item.css({
                //'background-image': 'url(' + $item.data('img-sml') + ')'
                'background-image': 'url(' + $item.data('img-sml') + ')',
                'height': +sliderHight + 'px'
            });
        } else {
            $item.css({
                //'background-image': 'url(' + $item.data('img-lrg') + ')'
                'background-image': 'url(' + $item.data('img-lrg') + ')',
                'height': +sliderHight + 'px'
            });
        }

        var $badgeBox = $item.find('.badges-box');
        var $badgeSmall = $item.find('.badge-image.only-sml');
        var $badgeLarge = $item.find('.badge-image.only-lrg');
        // positon VO
        var position;

        // Mobile
        if ('mobile' === viewport) {
            position = globalPosToLocalPos({
                'top': $badgeSmall.data('position-y'),
                'left': $badgeSmall.data('position-x')
            });
        }
        // Desktop and Tablet and Tablet with Burger speciial case
        else {
            position = globalPosToLocalPos({
                'top': $badgeLarge.data('position-y'),
                'left': $badgeLarge.data('position-x')
            });
        }
        // position the badge box (bade images are nested within)
        $badgeBox.css({
            'top': position.top + 'px',
            'left': position.left + 'px'
        });
    };

    /**
     * Apple specific stuff
     * modifies the css of the apple box directly
     * @param {string} - viewport - either desktop or mobile
     */
    var setApplePositions = function(viewport, $item) {

        var $appleBox = $item.find('.apple-box');
        if (0 === $appleBox.length) {
            return;
        }



        var centerCenterPosition = $appleBox.data('center-center');
        var centerCenterPositionMobile = $appleBox.data('center-center-mobile');
        //var width = $appleBox.data('600px');



        var position;

        if ('mobile' !== viewport) {
            position = globalPosToLocalPos({
                'top': $appleBox.data('position-y-large'),
                'left': $appleBox.data('position-x-large')
            });
        } else {
            position = globalPosToLocalPos({
                'top': $appleBox.data('position-y-small'),
                'left': $appleBox.data('position-x-small')
            });
        }

        // apple stuff sometimes positoned x centered

        if (600 <= (centerCenterPosition && window.innerWidth) || (centerCenterPositionMobile && 600 > window.innerWidth)) {
            position.left -= $appleBox.width() / 2;
        }

        $appleBox.css({
            'top': position.top + 'px',
            'left': position.left + 'px'
        });

        //manipulate appleBox to enable two buttons in a row
        if (null !== $item.find('.apple-box .buttons-align-in-row').width()) {
            $item.find('.apple-box').find('br').remove();
            $item.find('.apple-box .buttons-align-in-row').css('width', ($item.find('.apple-box .buttons-align-in-row a.btn').width() + $item.find('.apple-box .buttons-align-in-row a.second-button').width()) + 80 + 'px');
        }

    };

    /**
     * Helper method to calculate local form global positon
     * @param {object} gloablPosition - object conating a top and left property
     * @param {number} scaleFactor - float value between 0 and 1
     * @return {object} object conating a top and left property
     */
    var globalPosToLocalPos = function(gloablPosition) {
        var scaleFactor = 1;
        // width and hight settings
        var width = document.body.clientWidth;

        var height = sliderHight;

        var localTop;
        var localLeft;

        var viewport = vf.util.layout(true);

        // tablet or desktop
        if ('mobile' !== viewport) {

            //scaleFactor = width < 768 ? 425 / 560 : 1;
            if (768 > window.innerWidth) {
                //if (width < 768){
                scaleFactor = 425 / 560;
            }

            var globalCenterX = 2560 / 2;
            var globalCenterY = 560 / 2;
            // the disancces from the global center
            var globalFromCenterX = (gloablPosition.left - globalCenterX) * scaleFactor;
            var globalFromCenterY = (gloablPosition.top - globalCenterY) * scaleFactor;
            localTop = height - (height / 2 - globalFromCenterY);
            localLeft = width - (width / 2 - globalFromCenterX);
        } else {
            scaleFactor = width / 599;
            localTop = gloablPosition.top * scaleFactor;
            localLeft = gloablPosition.left * scaleFactor;
        }
        // return positon object
        return { 'top': localTop, 'left': localLeft };
    };

    /**
     * Calculates the hight (sliderHight) of the module (moduleHight)
     * @param {string} - viewport - either desktop or mobile
     */
    var defineSliderAndModuleHeight = function(viewport) {

        var herospaceTop = 0;
        // @TODO: Fix for herospace overlapping with VAT banner. Should be removed after VAT banner is removed
        var $vatBanner = $('.vat-edition');
        var vatBannerHeight = 0;
        if ($vatBanner.length) {
            vatBannerHeight = parseInt($vatBanner.height(), 10);
            herospaceTop = vatBannerHeight;
        };

        // Fix for IE Notification
        var $ieElevenNotification = $('#ie11-notification');
        var ieElevenNotificationHeight = 0;
        if ($ieElevenNotification.length) {
            ieElevenNotificationHeight = parseInt($ieElevenNotification.height(), 10);
            herospaceTop += ieElevenNotificationHeight;
        };

        switch (viewport) {
        case 'mobile':
            var blackBarHeight = 46;
            var actualHeight = document.body.clientWidth * 711 / 599;
            sliderHight = Math.round(actualHeight * 0.52 + 184);
            moduleHeight = sliderHight + blackBarHeight + 10;
            herospaceTop += blackBarHeight;
            break;
        case 'tablet':
            // tablet regular
            if (767 < window.innerWidth) {
                sliderHight = 560;
                moduleHeight = sliderHight - 60;
            }
            // tablet with burger
            else {
                sliderHight = 425;
                moduleHeight = sliderHight - 40;
            }
            break;
        default:
            sliderHight = 560;
            moduleHeight = sliderHight - 40;
            herospaceTop += 20;
        }

        $('.herospace2-basic').css('top', herospaceTop + 'px');

    };
    /**
     * Calculates the hight (sliderHight) of the module (moduleHight)
     * goes trough all slides and updates them accordingly
     * triggered at - resize -orientationchange and initialization
     */
    var updateItems = function() {

        var viewport = vf.util.layout(true);

        var $elems = $('.herospace2-basic').find('.bxslider').find('li');

        $elems.each(function() {
            defineSliderAndModuleHeight(viewport, $(this));
            updateItem(viewport, $(this));
            setApplePositions(viewport, $(this));
        });

        // consumer positioning
        if (!isEnterprisePage()) {
            console.log(moduleHeight);
            console.log(sliderHight);
            $('.herospace2-basic-proxy').css('height', moduleHeight);
            $('.herospace2-basic').css('height', moduleHeight);
            $('.herospace2-basic .bx-viewport').css('height', sliderHight);
        }
        // enterprise positioning
        else {
            $('.herospace2-basic').css('height', moduleHeight);
            $('.herospace2-basic .bx-viewport').css('height', sliderHight);

            // old nav
            //$('.content').css('margin-top', moduleHeight - 88);
            // new nav
            $('.content').css('margin-top', moduleHeight - 55);

            switch (viewport) {
            case 'mobile':
                if (!useDotNavi) {
                    // old nav
                    //$('.content').css('margin-top', moduleHeight - 22);
                    // new nav
                    $('.content').css('margin-top', moduleHeight - 3);
                } else {
                    // old nav
                    //$('.content').css('margin-top', moduleHeight - 56);
                    // new nav
                    $('.content').css('margin-top', moduleHeight - 37);
                }


                break;

            case 'tablet':
                // tablet regular
                if (767 < document.body.clientWidth) {

                    // old nav
                    //$('.content').css('margin-top', moduleHeight - 50);
                    //new nav
                    $('.content').css('margin-top', moduleHeight + 7);
                }
                // tablet with burger
                else {
                    // old nav
                    //$('.content').css('margin-top', moduleHeight - 55);
                    //new nav
                    $('.content').css('margin-top', moduleHeight - 50);
                }

                break;

                //$('.content').css('margin-top', moduleHeight - 59);
            default:
            }
        }
        // trigger apple posioning again - to prevent to current button alignment bug

        $elems.each(function() {
            setApplePositions(viewport, $(this));
        });
        $(window).trigger('vf::herospace2-item-resize');
    };

    /**
     * Check if is Enterprise
     * @return - {bool} if true it is an enterprise page
     */
    var isEnterprisePage = function() {
        var isEnterprise = $('body').find('.wrapper').hasClass('enterprise');
        // var isEnterprise = $('body').find('.spring').hasClass('enterprise');
        return isEnterprise;
    };

    /**
     * Creates a custom pager and adds it to the viewport
     */
    var createPager = function() {

        if (useDotNavi) {
            return;
        }

        // turn off the default pager

        var $items = $(settings.target).find('.bxslider li');

        if (1 === $items.length) {
            return;
        }


        $pager = $('<div id="bx-pager">');

        var $ul = $('<ul>');

        $items.each(function(index) {

            var $li = $('<li>');
            var $a = $('<a>');
            $a.attr('href', '#');
            $a.attr('data-slide-index', index);
            $a.attr('data-internal-campaign', $(this).attr('data-internal-campaign'));

            $a.text($(this).data('pager-title'));

            if ('ontouchstart' in document) {
                $a.addClass('touch');
            }


            $li.append($a);

            // make it 50 percent in case of  only two items
            if (2 === $items.length) {
                $li.attr('style', 'width:50%; max-width:500px');
                $li.addClass('fiftyPercent');
            }
            else if (4 === $items.length) {
                $li.attr('style', 'width:25%; max-width:250px');
                $li.addClass('twentyfivePercent');
            }

            $ul.append($li);

        });

        $(settings.target).append($pager);
        $pager.append($ul);
    };


    /**
     *
     */
    var updatePagerSize = function() {
        if (!useDotNavi) {
            $('.herospace2-basic .bx-controls').css('display', 'none');
        } else {
            $('.herospace2-basic .bx-controls').css('display', 'inline');
        }

        if ($pager === undefined) {
            return;
        }
        // reference to the list
        var $ul = $pager.find('ul');


        if ('mobile' !== vf.util.layout(true)) {
            $ul.css('width', 'auto');
            if (2 === $ul.find('li').length) {
                $ul.find('li').css('width', '50%');
            }
            else if (4 === $ul.find('li').length) {
                $ul.find('li').css('width', '25%');
            }
            else {
                $ul.find('li').css('width', '33.333333333333339%');
            }

            return;
        }


        var width = 0;
        var viewportWidth = $(document).width();
        var twoThirdWidth = Math.round(viewportWidth * 0.66666);


        $ul.find('li').each(function() {

            // causes trouble
            $(this).width(twoThirdWidth);

            width += $(this).width() + parseInt($(this).css('margin-right'));
        });

        $ul.width(width);
    };

    /**
     *
     */
    var updatePagePosition = function($slideElement, oldIndex, newIndex) {


        if (undefined === $pager) {
            return;
        }

        if ('mobile' !== vf.util.layout(true)) {
            $pager.find('ul').css('width', 'auto');
            return;
        }

        var pos = 0;

        $pager.find('li').each(function(index) {
            if (newIndex <= index) {
                return false;
            }

            pos += $(this).width() + parseInt($(this).css('margin-right'), 10);
            //pos += $(this).width() + parseInt($(this).css('margin-right'));

            return true;
        });

        $pager.scrollLeft(pos);
    };

    var setupSlider = function() {

        $bxslider = $(settings.target).find('.bxslider');
        var $elems = $('.herospace2-basic').find('.bxslider').find('li');
        useDotNavi = $bxslider.data('use-dot-navi');
        createPager();

        //direct access of single promotion via hash parameter
        var hashParam = document.URL.split('#')[1];
        if (undefined === hashParam || 0 > hashParam || ($elems.length - 1) < hashParam) {
            hashParam = 0;
        }

        var infiniteLoop = true;

        if (1 === $elems.length) {
            infiniteLoop = false;
            // disable touch
            $bxslider.on('touchstart', function(e) {
                e.stopPropagation();
            });
        }

        var customPager = '';

        if (!isEnterprisePage() || !useDotNavi) {
            customPager = '#bx-pager';
        } else {
            customPager = null;
        }

        if ('on' !== $bxslider.data('autostart')) {
            infiniteLoop = false;
        }

        $bxslider.bxSlider({
            easing: 'ease',
            infiniteLoop: infiniteLoop,
            speed: 800,
            auto: true,
            autoHover: true,
            autoStart: ('on' === $bxslider.data('autostart') ? true : false),
            minSlides: 1,
            maxSlides: 1,
            pagerCustom: customPager,
            startSlide: hashParam,
            onSlideBefore: updatePagePosition,
            onSlideAfter: sendingTracking,
            onSliderLoad: sendingTracking,
            pause: 6800
        });

        $bxslider.css('opacity', 1);

        $('.mod-footer').css('padding-bottom', '15px');

        $(window).on('load vf::resize orientationchange', updateItems);

        updateItems();
        updatePagerSize();
    };



    /**
     * required for A/B tests
     */
    var createSliderMbox = function(name) {
        var d = document.createElement('div');
        d.id = name + '_div';
        document.body.appendChild(d);
        mboxDefine(name + '_div', name);
        mboxUpdate(name);
    };


    /**
     * Breadcrumb für Montag besser ab Tablet (1024px) ausblenden
     */
    var breadcrumbTableFix = function() {

        if (isEnterprisePage()) {
            return;
        }

        if ('desktop' !== vf.util.layout(true)) {

            $('.mod-breadcrumb').hide();
        } else {
            $('.mod-breadcrumb').show();
        }
    };


    var appendIcmpParams = function() {

        var prepareStringForTracking = function(str) {

            if (undefined !== str) {
                str = str.replace(/\ /g, '-');
                str = encodeURIComponent(str);
            } else {
                str = '';
            }

            return str;
        };


        var $items = $(settings.target).find('.bxslider li');

        var regExGetParam = /\?/;

        $items.each(function(slideIndex) {
            var $item = $(this);
            $(this).find('.buttons a.btn').each(function(buttonIndex) {

                var pageName = VF.siteStructure[VF.siteStructure.length - 1];
                var slideNr = slideIndex + 1;
                var name = $item.data('pager-title');
                var callToActionNr = buttonIndex + 1;

                pageName = prepareStringForTracking(pageName);
                name = prepareStringForTracking(name);

                var trackingParams = 'icmp=' + pageName + ':' + slideNr + ':' + name + ':' + callToActionNr;

                var href = $(this).attr('href');

                var addParamsWith = '?';

                if (true === regExGetParam.test(href)) {
                    addParamsWith = '&';
                }

                /**
                 * if the button href is a tel or a mailto do not append the icmp paramater
                 */
                if (0 === href.indexOf('tel:') || 0 === href.indexOf('mailto:')) {
                    return;
                }
                $(this).attr('href', href + addParamsWith + trackingParams);
            });
        });
    };

    var init = function() {

        $content = $(settings.contentSelector);

        $content.prepend($('<div class="herospace2-basic-proxy">'));

        /* important for tracking */
        appendIcmpParams();

        $(window).on('load vf::resize', breadcrumbTableFix);
        breadcrumbTableFix();

        $(window).on('load vf::resize', updatePagerSize);
        updatePagerSize();

        window.sliderPromises = [];
        var waitForInMilliSeconds = 5000;
        var $herospaceControll = $('script.herospace-controll');

        if (0 === $herospaceControll.length || 'function' !== typeof mboxDefine) {
            setupSlider();
            return undefined;
        }


        if (undefined === $herospaceControll.data('mbox-name')) {
            return true;
        }

        if ($herospaceControll.data('mbox-timeout') && '' !== $herospaceControll.data('mbox-timeout')) {
            waitForInMilliSeconds = parseInt($herospaceControll.data('mbox-timeout'));
        }

        $herospaceControll.data('mbox-name').split(',').forEach(function(item) {
            sliderPromises.push($.Deferred()); // eslint-disable-line new-cap
            createSliderMbox($.trim(item));
        });

        //MAXIMUN 5 seconds for async slider calls
        window.setTimeout(function() {
            for (var i = 0; i < window.sliderPromises.length; i++) {
                window.sliderPromises[i].resolve();
            }
            window.sliderPromises = [];
        }, waitForInMilliSeconds);

        $.when.apply(null, window.sliderPromises).done(function() {
            setupSlider();
        });

        return true;
    };

    vf['herospace2-basic'] = {
        settings: settings,
        init: init
    };
}(vf));
