/**
 * @component dropdown-multi-select
 * @author Ali Zidan
 *
 * Javascripts
 */

(function () {
    'use strict';

    var wrapper = document.getElementsByClassName('dropdown-multi-select-wrapper')[0];

    if (wrapper) {

        var container = wrapper.getElementsByClassName('scrollContainer')[0];

        var checkboxes = container.getElementsByClassName('checkBox');

        var checkbox = null;

        var isOpened = false;

        wrapper.addEventListener('click', function () {

            isOpened = !isOpened;

            if (isOpened) {

                container.classList.add('active');
            } else {

                container.classList.remove('active');
            }
        });

        if (checkboxes && checkboxes.length) {

            function appendClickEventForCheckBox (index) {

                checkbox = checkboxes[index];

                checkbox.addEventListener('click', function (event) {

                    event.stopPropagation();

                    checkbox = checkboxes[index];

                    if (checkbox.classList.contains('checked')) {

                        checkbox.classList.remove('checked');
                    } else {

                        checkbox.classList.add('checked');
                    }
                });
            }

            for (var index = 0; index < checkboxes.length; index++) {

                appendClickEventForCheckBox(index);
            }
        }
    }
})();

