(function() {

    'use strict';

    var settings = {
        moduleSelector: '.mod-promobadge',
        contentSelector: '#content > .belt'
    };

    var $module;
    var $content;
    var moduleHeight;
    var hero;
    var heroTop;
    var forApp;

    var updateProxyHeight = function(){
        $('.promobadge-proxy').css('height', moduleHeight);
    };

    var updateHero = function(){
        hero = $('.herospace2-basic');
        if (0 < hero.length) {
            if (forApp) {
                heroTop = moduleHeight;
            } else {
                heroTop = parseInt($('.herospace2-basic').css('top'));
                heroTop = heroTop + moduleHeight;
            }
            $('.herospace2-basic').css({ top: heroTop + 'px' });
        }
    };

    var init = function(){

        $module = $(settings.moduleSelector);
        $content = $(settings.contentSelector);

        /* KISS */
        if ('flow' === $module.data('position')) {
            $module.css({
                position: 'relative',
                'background': '#007c92',
                top: '0px'
            });
            /* im Flow positionieren */
            return;
        }
        /* und kann man die Hintergrund-farbe auch anpassen? */

        $content.prepend($('<div class="promobadge-proxy">'));
        moduleHeight = parseInt($module.css('height'));
        forApp = ((-1 !== document.location.search.indexOf('forApp=true')) || ('true' === ($.cookie('forApp') || '')));

        if (forApp) {
            $module.css({ top: '0px' });
        }
        window.setTimeout(function() {
            updateHero();
        }, 5000);

        $(window).on('vf::resize', updateProxyHeight);
        updateProxyHeight();
        $(window).on('vf::resize', updateHero);
    };

    vf.promobadge = {
        settings: settings,
        init: init
    };

}(vf));
