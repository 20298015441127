$(function () {
    'use strict';

    var settings = {
        target: '.mod-ep-callback'
    };

    function callbackTrackingEvent(phoneNumber, trackingType) {
        _ddq.push([ 'hotline cta', {
            'number': phoneNumber,
            'type': trackingType
        }]);
    }

    var init = function () {

        $(settings.target + ' *[data-trackingtype]').on('click', function() {
            var phoneNumber = $(this).attr('href').replace('tel:', '');
            var trackingType = $(this).attr('data-trackingtype');
            callbackTrackingEvent(phoneNumber, trackingType);
            console.log('hotline CTA | ' + phoneNumber + ' | ' + trackingType);
        });

    };

    vf['ep-callback'] = {
        settings: settings,
        init: init
    };
}(vf));
