if ('undefined' === typeof vf) {
    vf = {};
}
(function() {
    'use strict';
    vf.BasketItem = function() {
        var item = {};

        var addTariff = function(tariffId) {
            item.TARIFFID = tariffId;
        };

        var addHardware = function(deviceId) {
            item.HARDWAREID = deviceId;
        };

        var addSecondCard = function(tariffId) {
            if (!item.SECONDCARD) {
                item.SECONDCARD = [];
            }
            var secondCard = new vf.BasketItem();

            secondCard.addTariff(tariffId);

            item.SECONDCARD.push(secondCard.getItem());
        };

        var addService = function(serviceId) {
            if (!item.SERVICES) {
                item.SERVICES = [];
            }
            item.SERVICES.push({
                'SERVICEID': serviceId
            });
        };

        var addDiscount = function(discountId) {
            if (!item.DISCOUNTS) {
                item.DISCOUNTS = [];
            }
            item.DISCOUNTS.push({
                'DISCOUNTID': discountId
            });
        };

        var mapParams = function() {
            var strParam = '';
            if (item.HARDWAREID) {
                strParam += 'deviceId=' + item.HARDWAREID;
            }
            if (item.TARIFFID) {
                if ('' !== strParam) {
                    strParam += '&';
                }
                strParam += 'tariffId=' + item.TARIFFID;
            }
            if (item.SERVICES) {
                strParam += '&';
                var sServices = '';
                $.each(item.SERVICES, function(key, val) {
                    if ('' !== sServices) {
                        sServices += ',';
                    }
                    sServices += val.SERVICEID;
                });
                strParam += 'services=' + sServices;
            }
            return strParam;
        };

        var generateDeepLink = function() {
            return mapParams(item);
        };

        /**
         * API
         */
        return {
            addTariff: function(tariffId) {
                addTariff(tariffId);
            },
            addHardware: function(deviceId) {
                addHardware(deviceId);
            },

            getItem: function() {
                return item;
            },

            serialize: function() {
                return btoa(JSON.stringify(item));
            },

            deserialize: function(strBasketItem) {
                item = JSON.parse(atob(strBasketItem));
            },

            addSecondCard: function(tariffId) {
                addSecondCard(tariffId);
            },

            addService: function(serviceId) {
                addService(serviceId);
            },

            addDiscount: function(discountId) {
                addDiscount(discountId);
            },

            reinit: function() {
                item = {};
            },

            generateDeepLink: function() {
                return generateDeepLink();
            }
        };
    };
})(vf);

(function() {
    'use strict';
    vf.Basket = function(salesChannel) {
        var basket = {
            sChan: salesChannel,
            NEW: [],
            VVL: {},
            MOBILE: [],
            HARDWAREONLY: [],
            debit: []
        };

        var getBasket = function() {
            if (undefined !== basket.NEW && 0 === basket.NEW.length) {
                delete basket.NEW;
            }
            if (undefined !== basket.MOBILE && 0 === basket.MOBILE.length) {
                delete basket.MOBILE;
            }
            if (undefined !== basket.HARDWAREONLY && 0 === basket.HARDWAREONLY.length) {
                delete basket.HARDWAREONLY;
            }
            if (undefined !== basket.VVL && jQuery.isEmptyObject(basket.VVL)) {
                delete basket.VVL;
            }
            if (undefined !== basket.debit && 0 === basket.debit.length) {
                delete basket.debit;
            }
            return basket;
        };

        return {
            getBasket: function() {
                return getBasket();
            },

            getBasketAsString: function() {
                return JSON.stringify(getBasket());
            },

            addNEW: function(basketItem) {
                basket.NEW.push(basketItem);
            },

            addVVL: function(basketItem) {
                basket.VVL = basketItem;
            },

            addDEBIT: function(basketItem) {
                basket.debit.push(basketItem);
            },

            addHARDWAREONLY: function(basketItem) {
                basket.HARDWAREONLY.push(basketItem);
            },

            clear: function() {
                basket.NEW = [];
                basket.VVL = {};
                basket.MOBILE = [];
                basket.HARDWAREONLY = [];
                basket.debit = [];
            }




        };
    };
})(vf);



/**
 * [bi how to use]
 * @type {BasketItem}
 *
bi = new vf.BasketItem();

bi.addTariff('256');

bi.addHardware('256');

bi.addSecondCard('81');

bi.addService('81');

*/
