(function() {

    'use strict';

    /**
     * A reader that does notthing then resloving promises
     */
    var proxy = (function(){

        var initialise = function(){

            var deferred = $.Deferred(); // eslint-disable-line new-cap

            deferred.resolve();

            return deferred.promise();

        };

        var getDeviceDataByVirtualId = function(){

            var deferred = jQuery.Deferred(); // eslint-disable-line new-cap

            deferred.resolve({});

            return deferred.promise();

        };

        var getName = function(){
            return 'proxy';
        };

        return {
            getName: getName,
            initialise: initialise,
            getDeviceDataByVirtualId: getDeviceDataByVirtualId
        };

    })();

    vf.connect.proxy = proxy;

}(vf));
