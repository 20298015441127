/**
 * @component ws2-notification
 * @author Carbon
 *
 * Javascripts
 */

(function() {
    'use strict';
    // write your code
})();
