(function () {//eslint-disable-line strict

    vf.anchortop = {
        settings: {
            target: '.mod-anchortop'
        },
        init: function () {
            $(window).on('scroll', vf.anchortop.toggleVisibility);
        },
        toggleVisibility: function () {
            if (($(window).scrollTop()) > $(window).height() - 200) {
                if (!$('.mod-anchortop').hasClass('anchorShown')) {
                    $('.mod-anchortop').addClass('anchorShown');
                }
            } else {
                if ($('.mod-anchortop').hasClass('anchorShown')) {
                    $('.mod-anchortop').removeClass('anchorShown');
                }
            }
        }
    };
}(vf));
