// @Examples

// Warning :
// vf["notification-message"].initializeNotification({title:'title one',content:'content shall be a littel bit gigger than the title',iconInfo: 'info',longPressTime:1000 , swipeTime:200,onClick: ()=> console.log('clicked'), closeAllText:'close me all', longPressMessage:'long pressing message'})
(function (vf) {
    'use strict';
    /**Variables**/
    /** holds default configuration setting to intialize the notification message  */
    var defaultSettings = {
        title: '',
        content: '',
        longPressTime: 3000,
        longPressMessage: 'Nach rechts ziehen um Benachrichtigung zu entfernen',
        iconInfo: 'success',
        closeAllText: 'close all',
        hideMessageSpeed: 500,
        swipeTime: 1000,
        longPressingStarted: false,
        context: null,
        //Functions
        onClick: null,
        onClose: null
    };

    /**holds current time in milliseconds when the user presses on the message*/
    var startedPressing; // eslint-disable-line

    /**holds the container that holds the messages-boxes that will be attached into it */
    var notificationsContainer = null;

    /** holds the current index of message created  */
    var currentItemCount = 0; // eslint-disable-line

    // /** holds the long pressing handler */
    // var longPressingStarted = false; // eslint-disable-line

    /**holds the icons svg info  */
    var iconInfo = {
        success: {
            class: 'icon-success-g-i-sml',
            id: '/simplicity/svgdefs.svg#i-success-g-sml'
        },
        warning: {
            class: 'icon-warning-i-sml',
            id: '/simplicity/svgdefs.svg#i-warning-sml'
        },
        error: {
            class: 'icon-error-r-i-sml',
            id: '/simplicity/svgdefs.svg#i-error-r-sml'
        },
        info: {
            class: 'icon-warning-g-i-sml',
            id: '/simplicity/svgdefs.svg#i-tooltip-lrg'
        }
    };

    /**Functions**/
    /**
     * Create Message Notification Popup
     * @param {object} settings holds the notifications settings
     */
    function initializeNotification(settings) {
        /** holds the instanse of created interval  */
        var timeInterval = null;

        /** holds inital position after clicking the message */
        var mousePosition = {
            x: 0
        };

        /** holds inital position after releasing the message */
        var newMousePosition = {
            x: 0
        };

        if (vf && vf['notification-message']) {
            vf['notification-message'].isInitialized = true;
        }

        /** Creates the initial container that holds the messages if not Exists! */
        if (!notificationsContainer) {
            /** Adding property for external users to be notified that we initialized the container */
            if (vf && vf['notification-message']) {
                vf['notification-message'].isInitialized = true;
            }
            /** initialize notification long press container*/
            $(document.body).append('<div class="notification-message-long-press"></div>');

            //Adding the long pressing content
            $('.notification-message-long-press').text(settings.longPressMessage || defaultSettings.longPressMessage || '');

            /** hide long pressing container at the begining*/
            $('.notification-message-long-press').hide();

            /** holds the main container */
            notificationsContainer = $('<div class="notification-message-container"></div>');
            notificationsContainer.append($('<div data-close-all ><span>' + (settings.closeAllText || defaultSettings.closeAllText) + '</span></div>'));
            $(document.body).append(notificationsContainer);

            // //For Debugging only
            // $(document.body)
            // .append('<h1 data-debugger></h1>');

            /** EVENT close all Click */
            $('[data-close-all] span').on('click', function () {
                destroyNotificationContainer();
                swipedMessage = null;
            });
        }

        if (settings.longPressMessage) {
            //Note:It will be Changed for All notifications long pressed
            //Modifies the long pressing content
            changeLongPressingText(settings.longPressMessage || defaultSettings.longPressMessage || '');
        }
        if (settings.closeAllText) {
            //Note:It will be Changed for All notifications long pressed
            //Modifies the long pressing content
            changeCloseAllText(settings.closeAllText || defaultSettings.closeAllText || '');
        }
        var id = (settings.id && 'string' === typeof settings.id) ? settings.id : currentItemCount;
        var notificationSingle = $('<div class="noselect notification-message-item" data-id="' + id + '" ></div>');

        /** Adding class to identify message type*/
        notificationSingle.addClass(settings.iconInfo || defaultSettings.iconInfo);
        /** Create Notifications item content */
        var notificationContent = $('<div class="notification-message-content"></div>');
        var notificationBody = $('<p></p>').append(settings.content || defaultSettings.content);
        var notificationTitle = $('<h4></h4>').append(settings.title || defaultSettings.title);
        notificationContent.append(notificationTitle); //.append(notificationBody);

        /**Creating SVG for icon that indicate the message type */
        var notificationLeft = $('<div class="notification-message-left"></div>');
        var svgIconBefore = $('<svg style="float:left" class="' + iconInfo[settings.iconInfo || defaultSettings.iconInfo].class + ' i-sml"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="' + iconInfo[settings.iconInfo || defaultSettings.iconInfo].id + '"></use></svg>');
        notificationLeft.append(svgIconBefore);
        notificationSingle.prepend(notificationLeft);

        /**Creating SVG for icon that indicate the closing icon */
        var notificationRight = $('<div class="notification-message-right"></div>');
        notificationRight.append('<svg style="float:right" class="icon-close-i-xxsml i-xxsml"><use xlink:href="/simplicity/svgdefs.svg#i-close-sml" /></svg>');

        /** holds the notification messgae item */
        notificationSingle.append(notificationContent);
        notificationSingle.append(notificationRight);
        notificationSingle.append(notificationBody);

        /** Adding the message to the container */
        // notificationsContainer.prepend(notificationSingle);
        notificationsContainer.find('[data-close-all]:first-child').after(notificationSingle);

        //EVENTS
        /** Event for closing the Icon to remove the notification  */
        var id = (settings.id && 'string' === typeof settings.id) ? settings.id : currentItemCount;
        $('.notification-message-item[data-id="' + id + '"]').find('svg:last').on('mousedown touchstart', function (e) {
            if (3 !== e.which) {
                var messageItem = $(this).closest('.notification-message-item');
                messageItem.animate({
                    borderRadius: '0px',
                    padding: '0px',
                    height: '-10px'
                }, settings.hideMessageSpeed || defaultSettings.hideMessageSpeed, function () {
                    $(this).hide();
                    $(this).remove();
                    hideCloseAll();
                });
                if (settings.onClose) {
                    if (settings.context) {
                        settings.onClose.call(settings.context);
                    } else {
                        settings.onClose();
                    }
                }
                isInitialized();

            }
            return false;
        });

        /**holds the swiped message  */
        var swipedMessage = null;

        /**Event for longPressing Down*/
        notificationSingle.on('mousedown touchstart', function (e) {
            if (3 !== e.which) {

                startedPressing = true;
                defaultSettings.longPressingStarted = false;
                //Setting the mouse position
                mousePosition.x = e.clientX || getTouchPosiotion(e);

                //Setting the pressed message into the clicked one
                swipedMessage = this;

                clearInterval(timeInterval);
                //fires after the longPressingTime passes
                timeInterval = setTimeout(function () {
                    if (startedPressing) {
                        defaultSettings.longPressingStarted = true;
                        $('.notification-message-long-press').show();
                        // $('[data-debugger]').html( longPressingStarted );
                    }

                }, settings.longPressTime || defaultSettings.longPressTime);
            }
        }); //End of mouse event down

        /**Event for swipe*/
        $(document.body).on('mousemove touchmove', function (e) {

            if (swipedMessage && mousePosition && mousePosition.x && startedPressing && (0 !== mousePosition.x)) {
                //moving the item when swapping
                $(swipedMessage).css({
                    'position': 'relative',
                    'left': ((mousePosition.x - (e.clientX || getTouchPosiotion(e))) * -1) + 'px'
                });
            }
        }); //End of mousemove event

        /**Event for longPressing Up Or releasing the notification*/
        $(document.body).on('mouseup touchend', function (e) {
            // $('[data-debugger]').html('newMousePosition:' + JSON.stringify( newMousePosition) + ', mousePosition:' + JSON.stringify( mousePosition) + ' ,longPressingStarted:' + longPressingStarted );

            //Setting the mouse axis
            newMousePosition.x = e.clientX || getTouchPosiotion(e);

            //Stop handling the Event Mousemove
            startedPressing = false;

            if (newMousePosition && mousePosition && ($(e.target).closest('.notification-message-item').html() || $(e.target).parent().closest('.notification-message-item').html())) {

                /** ensure he clicked the item with out dragging it */
                if (
                    //left direction with 10 px will be considers a click
                    (newMousePosition.x <= mousePosition.x &&
                        (newMousePosition.x >= (mousePosition.x - 10)))
                    ||
                    newMousePosition.x >= mousePosition.x &&
                    (newMousePosition.x <= (mousePosition.x + 10))

                ) {
                    //Fire the Clicking Event
                    if (!defaultSettings.longPressingStarted && 3 !== e.which) {
                        if (settings.onClick) {
                            if (settings.context) {
                                settings.onClick.call(settings.context);
                            } else {
                                settings.onClick();
                            }
                        }
                        $(swipedMessage).stop().hide();
                        hideCloseAll();
                        isInitialized();
                    }
                    // Reset the long pressing after he releases the mouse
                    defaultSettings.longPressingStarted = false;
                } else {
                    //animate according to the swipe position
                    0 < newMousePosition.x - mousePosition.x ? $(swipedMessage).stop().animate({
                        'left': '+100%'
                    }, settings.swipeTime || defaultSettings.swipeTime, animationHandler) : $(swipedMessage).stop().animate({
                        'left': '-100%'
                    }, settings.swipeTime || defaultSettings.swipeTime, animationHandler);
                    isInitialized();

                } //End of condition that defines if click or swipe

                //Hide the long pressing message
                $('.notification-message-long-press').hide();
            }

            //Handle animation hiding
            function animationHandler() {
                $(swipedMessage).remove();
                hideCloseAll();
            }

        }); //End of mouseup event
        currentItemCount++;

        /** hide or show the close all  */
        hideCloseAll();

    } //End Of initializeNotification

    /** Hide Close All */
    function hideCloseAll() {
        //handle close all appearing after all childs cleared
        if (2 > $('.notification-message-item').length) {
            $('[data-close-all]').hide();
        } else {
            $('[data-close-all]').show();
        }
    }
    /**
     * check if there at least one message or not
     */
    function isInitialized() {
        vf['notification-message'].isInitialized = 0 < $('.notification-message-item').length ? true : false;
    }

    /**
     * Closes all the open notifications
     */
    function closeAll() {
        $('.notification-message-item').find('svg:last').mousedown();
        vf['notification-message'].isInitialized = false;
    }

    function destroyNotificationContainer() {
        $('.notification-message-container').remove();
        notificationsContainer = null;
    }

    /** Get Mobile touch Position */
    function getTouchPosiotion(e) {
        var touchChanges = e.changedTouches && e.changedTouches[0] && e.changedTouches[0].clientX;
        var originalEvent = e.originalEvent && e.originalEvent.touches && e.originalEvent.touches[0] && e.originalEvent.touches[0].clientX;

        return touchChanges || originalEvent || 0;
    }

    /**
     * Change the text of close all messages
     * @param {*} text string
     */
    function changeCloseAllText(text) {
        $('[data-close-all] span').text(text || defaultSettings.longPressMessage || '');
    }

    function changeLongPressingText(longPressingText) {
        //Modifies the long pressing content
        $('.notification-message-long-press').text(longPressingText || defaultSettings.longPressMessage || '');
    }

    /**
     * Get Message Id And Check if it is exist
     * @param {string} id
     */
    function isNotificationExist(id) {
        var isExist = false;
        if (id && 'string' === typeof (id)) {

            isExist = 0 < $('.notification-message-item[data-id=' + id + ']').length;
        }
        return isExist;
    }
    function closeNotificationById(id) {
        if (id && 'string' === typeof (id)) {
            $('.notification-message-item[data-id=' + id + ']').remove();
            hideCloseAll();
        }
    }

    /** @module notification-message */
    /** @class notification-message  */
    vf['notification-message'] = {
        changeCloseAllText: changeCloseAllText,
        changeLongPressingText: changeLongPressingText,
        isInitialized: false,
        isNotificationExist: isNotificationExist,
        initializeNotification: initializeNotification,
        closeAll: closeAll,
        closeNotificationById: closeNotificationById,
        //Question is Differs from the clear all massages or not ?
        destroyNotificationContainer: destroyNotificationContainer
    };
    /** The Global Vodafone Object */
})(vf);


