/* <<<< example >>>>>> */
/*
var dynamicScriptConfig = {
	"singlepage" : {

		"5a4e01d8-7e48-4f0f-a74b-5d9d596d2ca6" : ['/simplicity/assets/js/local.js']

	}
}
*/


if ('object' === typeof (dynamicScriptConfig)) {


	// will be used to dynamically include js-files which aren't needed on every page
	// TODO: determine the configuration

	// currently there is only the tag "singlepage" : object describing uuids of pages, where a script should be included in format { <uuid1> : [<script1> , <script2>], <uuid1> : [<script1> , <script2>], ... }

    var currentUuid = $('#top').data('uuid');


    if (dynamicScriptConfig.singlepage) {

        for (var uid in dynamicScriptConfig.singlepage) {

            if (uid === currentUuid) {

                for (scriptIndex in dynamicScriptConfig.singlepage[uid]) {

                    var scriptFile = dynamicScriptConfig.singlepage[uid][scriptIndex];

                    document.write('<script type="text/javascript" src="' + scriptFile + '"></' + 'script>');

                }

            }

        }

    }

}

/* <<<< example >>>>>> */
/*
if(typeof multScripts === "undefined") {multScripts={}};
window.multScripts["mila"] = {
		sources : ['/scripts/redplusbundle.js']
}
*/

(function (multiscripts) {
    if ('object' === typeof multiscripts) {
        for (var i in multiscripts) {
            for (var j in multiscripts[i].sources) {
                document.write('<script type="text/javascript" src="' + multiscripts[i].sources[j] + '"></' + 'script>');
            }
        }
    }
}(window.multScripts || {}, undefined));
