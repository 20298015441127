(function() {
    'use strict';

    var settings = {
        target: '.mod-tooltip2',
        delay: {
            open: 0,
            close: 0
        }
    };

    var $tpl = $('<div class="tt-mover"><div class="tt-container"><div class="tt-inner"><div class="h4"></div><div class="tt-content"></div></div></div></div>');
    var $tplNoHeadline = $('<div class="tt-mover"><div class="tt-container"><div class="tt-inner"><div class="tt-content"></div></div></div></div>');

    var activeTooltip;


    /**
     * Get tooltip dom elem with head and content
    */
    var tpl = function($elemWidthTooltip){


        var headline = $elemWidthTooltip.parent('.mod-tooltip2').attr('data-headline');
        var content = $elemWidthTooltip.parent('.mod-tooltip2').attr('data-content');
        var $tooltip;

        if (headline) {
            $tooltip = $tpl.clone();
            $tooltip.find('.h4').html(headline);
        } else {
            // if there's no headline data - show no headline
            $tooltip = $tplNoHeadline.clone();
        }

        $tooltip.find('.tt-content').html(content);

        $tooltip.find('.tt-close-handle').width($elemWidthTooltip.outerWidth());
        $tooltip.find('.tt-close-handle').height($elemWidthTooltip.outerHeight());

        return $tooltip;
    };

    /**
     * Create and open tooltip
     */
    var open = function($elemWidthTooltip){

        var $tooltip = tpl($elemWidthTooltip);
        var position = $elemWidthTooltip.offset();
        var aligned = $elemWidthTooltip.parent('.mod-tooltip2').attr('data-aligned');
        var tooltipWidth = 0;

        activeTooltip = $tooltip;

        if ('mobile' !== vf.util.layout(true)){

            var width = parseInt($('.content .belt').outerWidth() / 3, 10);
            if (position.left) {
                // if width is bigger than the left position, it means that it's going to be out of visibility
                width = Math.min(width, position.left);
            }

            var $parentCol = $elemWidthTooltip.parents('.btn-wrap');
            if (undefined !== $parentCol.get(0)){
                if (width > $parentCol.width()){
                    width = $parentCol.width();
                }
            }

            tooltipWidth = width;

            $tooltip.find('.tt-container').css({
                width: tooltipWidth + 'px'
            });
        }
        else {

            // viewport width and 20 padding on right hand
            var width = parseInt($('.content .belt').outerWidth(), 10) - 20;

            var left = position.left + ($elemWidthTooltip.outerWidth() / 2);

            if ('right' === aligned) {
                tooltipWidth = left - ($elemWidthTooltip.outerWidth() / 2);
            } else {
                tooltipWidth = width - left;
            }

            $tooltip.find('.tt-container').css({
                width: tooltipWidth + 'px'
            });
        }

        $('body').append($tooltip);

        if ('right' === aligned) {
            $tooltip.addClass('right-aligned');
            $tooltip.css({
                top: position.top + 'px',
                left: (position.left - $tooltip.find('.tt-container .tt-inner').outerWidth()) + 20 + ($elemWidthTooltip.outerWidth() / 2) + 'px'
            });
        } else {
            $tooltip.removeClass('right-aligned');
            $tooltip.css({
                top: position.top + 'px',
                left: position.left - 2 + ($elemWidthTooltip.outerWidth() / 2) + 'px'
            });
        }

        if (true === vf.util.is_touch_device){
            $('div.content').one('touchstart', function(){
                window.setTimeout(function(){
                    close($elemWidthTooltip, $tooltip);
                }, settings.delay.close);
            });
        }

    };

    /**
     * Remove class from $elemWidthTooltip and remove tooltip from
     */
    var close = function($elemWidthTooltip, $tooltip){
        $elemWidthTooltip.removeClass('active');
        // removing tooltip only if it already exists
        if ($tooltip) {
            $tooltip.remove();
        }
    };

    var init = function(context){

        $(context).find('.mod-tooltip2 .tt-handle').on('click', function(event) {
            event.preventDefault();
            event.stopPropagation();
        });

        $(context).find('.mod-tooltip2 .tt-handle').on('mouseenter touchstart', function(event) {
            var $elemWidthTooltip = $(this);

            event.preventDefault();
            event.stopPropagation();

            if (true === $elemWidthTooltip.hasClass('active')){
                return;
            }

            $elemWidthTooltip.addClass('active');

            // only one tooltip can be opened at once, therefore closing previously opened tooltip
            setTimeout(function(){
                close($elemWidthTooltip, activeTooltip);
            }, 0);

            window.setTimeout(function(){open($elemWidthTooltip);}, settings.delay.open);
        }).on('mouseleave', function() {
            var $elemWidthTooltip = $(this);
            setTimeout(function(){
                close($elemWidthTooltip, activeTooltip);
            }, 0);
        });

        // loop all mod-tooltip2 that are contained in an subscribtion tile within a listitem and add padding-right to listitem
        $('.tariff-module-tile li .mod-tooltip2').each(function(){
            $(this).parent('li').css('padding-right', '25px');
        });

    };

    vf.tooltip2 = {
        settings: settings,
        init: init
    };
}(vf));
