(function () {

    'use strict';

    var settings = {
        target: '.mod-simple-promo-teaser'
    };

    /**
     * Remove inline style and destroy dotdotdot
    */
    var resetMaxHeight = function(){
        $(settings.target).each(function(){

            $(this).find('.content-box').each(function(){

                $(this).find('.body-wrap').css('max-height', '');

                // destoy dot while it (can) bring it's on window@resize handle and we don't want it and simply destroy it is much easier then trigger('update.dot') on the right time
                $(this).find('.body-wrap').trigger('destroy.dot');
            });

        });
    };

    /**
     * Loop all elems, calculate height and crop text
     */
    var setMaxHeightAndCrop = function(){
        $(settings.target).each(function(){

            $(this).find('.content-box').each(function(){
                var height = $(this).height();
                var maxHeight = parseInt($(this).css('max-height'), 10);

                // elems height is less then max-height: obviously nothing must be cropped
                if (height < maxHeight){
                    return true;
                }

                var $headline = $(this).find('.h2');
                var headlineHeight = $headline.outerHeight() + parseInt($headline.css('margin-bottom'), 10);

                var $buttons = $(this).find('.btn-wrap');
                var buttonsHeight = $buttons.outerHeight() + parseInt($buttons.css('margin-top'), 10);

                var leftHeight = maxHeight - headlineHeight - buttonsHeight - 40;

                $(this).find('.body-wrap').css('max-height', leftHeight + 'px');

                $(this).find('.body-wrap').dotdotdot({
                    watch: false
                });

                return true;

            });

        });
    };

    var handleResize = function(){
        resetMaxHeight();

        if ('mobile' !== vf.util.layout(true)){
            setMaxHeightAndCrop();
        }

    };

    var setJumpMark = function(){
        var ctas = $('.mod-simple-promo-teaser').find('a');
        ctas.each(function() {
            var jumpmark = $( this ).data( 'jumpmark' );
            if (undefined !== jumpmark && 0 < jumpmark.length && 'no-data' !== jumpmark) {
                var currentHref = $( this ).attr('href').trim();
                var href = null;
                if (0 === currentHref.indexOf('#')) {
                    href = '#' + jumpmark;
                } else {
                    var hashPos = currentHref.indexOf('#');
                    if (-1 !== hashPos) {
                        currentHref = currentHref.substr(0, hashPos);
                    }
                    href = currentHref + '#' + jumpmark;
                }
                $( this ).attr('href', href);
            }
        });

    };


    var init = function(){

        $(window).on('vf::fontLoaded vf::resize orientationchange load', handleResize);
        setJumpMark();
    };


    vf['simple-promo-teaser'] = {
        settings: settings,
        init: init
    };

}(vf));
