(function() {

    'use strict';

    var settings = {
        target: '.mod-carousel-module-large'
    };

    /**
     *
     */
    var bxOptions = {
        minSlides: 2,
        maxSlides: 3,
        infiniteLoop: false,
        hideControlOnEnd: true,
        slideWidth: 0,
        slideMargin: 0,
        moveSlides: 1,
        slideSelector: 'li.bxslider-item',
        adaptiveHeight: false,
        easing: 'ease',
        speed: 400
    };

    /**
     * Create and maintain slider
     */
    var createSlider = function($modSlider){

        var slider;

        var defaultOptions = $.extend( {}, bxOptions);
        var instanceOptions = $.extend( {}, defaultOptions);

        var mode = (undefined === $modSlider.parents('.enterprise').get(0)) ? 'consumer' : 'enterprise';

        /**
         * seperate equal height script while bxslider is not compatible with the existing one
         */
        var makeEqHeight = function(selector){

            var $elems = $modSlider.find(selector);

            var maxHeight = 0;

            $elems.css('height', '');

            $elems.each(function(){

                if (maxHeight < $(this).height()){
                    maxHeight = $(this).height();
                }

            });

            $elems.height(maxHeight);

        };

        var eqHeight = function(){

            var selectors = $modSlider.data('slider-eq-height');

            if (undefined === selectors || '' === selectors ){
                return;
            }

            selectors = selectors.split(',');

            $(selectors).each(function(index, item){
                makeEqHeight(item);
            });

            var height = $($modSlider.find(instanceOptions.slideSelector).get(0)).outerHeight();
            $modSlider.find('.bx-viewport').height(height);



        };

        /**
         * Getting padding via css is not robust enough
         */
        var getPadding = function(){

            var layout = vf.util.layout(true);

            if ( 'mobile' === layout){
                return 8;
            }
            else if ('tablet' === layout){
                return 16;
            }
            else {
                return 32;
            }

        };

        /**
         * Get consumer diff for bxslider.options
         */
        var getConsumerOptions = function(layout, padding, options){

            if ('desktop' !== layout){
                return options;
            }

            options.minSlides = 4;
            options.maxSlides = 4;
            options.slideWidth = parseInt( ($modSlider.width() - (padding * 3) ) / 4, 10);

            return options;

        };

        /**
         * Get enterprise diff for bxslider.options
         */
        var getEnterpriseOptions = function(layout, padding, options){

            if ('desktop' !== layout){
                return options;
            }

            options.minSlides = 3;
            options.maxSlides = 3;
            options.slideWidth = parseInt( ($modSlider.width() - (padding * 2) ) / 3, 10);

            return options;

        };

        /**
         * options that are shared for enterprise and consumer
         */
        var getOptions = function(){ // eslint-disable-line no-alert

            instanceOptions = $.extend( {}, defaultOptions);

            var padding = getPadding();
            instanceOptions.slideMargin = padding;

            var layout = vf.util.layout(true);

            if ( 'mobile' === layout){
                instanceOptions.minSlides = 1;
                instanceOptions.maxSlides = 2;
                instanceOptions.slideWidth = parseInt($modSlider.width() / 3 * 2, 10);

                if ('consumer' === mode){
                    instanceOptions.touchEnabled = false;
                }

            }
            else if ('tablet' === layout){
                instanceOptions.minSlides = 2;
                instanceOptions.maxSlides = 3;
                instanceOptions.slideWidth = parseInt( ($modSlider.width() - 112 - 2 * padding ) / 2, 10);
            }
            else if ('desktop' === layout){

                if ('consumer' === mode){
                    instanceOptions = getConsumerOptions(layout, padding, instanceOptions);
                }
                else if ('enterprise' === mode){
                    instanceOptions = getEnterpriseOptions(layout, padding, instanceOptions);
                }

            }

            instanceOptions.onSliderLoad = handleSliderLoadAndResize;

            instanceOptions.onSliderResize = handleSliderLoadAndResize;

            return instanceOptions;

        };

        /**
         * Handler called when carousel is in viewport
         */
        var firstAppearFx = function(){

            eqHeight();
            $modSlider.addClass('visible');

            // visible but not ready
            if (false === $modSlider.hasClass('ready')){
                window.setTimeout(firstAppearFx, 200);
                return;
            }

            // while there is a css3 transition on .ready this margin-left triggers the fly in animation
            $modSlider.find(instanceOptions.slideSelector).each(function(){
                $(this).css({
                    'margin-left': '0px'
                });
            });

        };

        /**
         *
         */
        var handleSliderLoadAndResize = function(){
            eqHeight();

            if (false === $modSlider.hasClass('ready')){

                $modSlider.css({
                    height: 'auto',
                    background: 'none'
                });

                // prepare fly in animation by setting a margin
                $modSlider.find(instanceOptions.slideSelector).each(function(){
                    $(this).css({
                        'margin-left': $modSlider.width() + 'px'
                    });
                });

                $modSlider.removeClass('loaded');
                $modSlider.addClass('ready');

            }

            /**
             * on mobile the carousel is actual disabled
             */
            if ('mobile' === vf.util.layout(true)){
                console.log('mobile');
                var options = getOptions();
                var numberOfSlides = $modSlider.find(options.slideSelector).length;

                var bxSliderWidth = numberOfSlides * options.slideWidth + numberOfSlides * options.slideMargin;

                $modSlider.find('.bx-viewport').css({
                    'overflow-y': 'hidden',
                    'overflow-x': 'scroll',
                    'height': $modSlider.find('.bx-viewport').height() + 20 + 'px'
                });

                $modSlider.find('.bxslider').css({
                    width: bxSliderWidth + 'px'
                });
            }

        };

        /**
         * On window resize reload slider with updatet options
         */
        var updateSlider = function(){

            slider.reloadSlider(
                getOptions()
            );
        };

        /**
         * Create bxslider
         */
        var setUpSlider = function(){

            $modSlider.removeClass('loading');
            $modSlider.addClass('loaded');

            slider = $modSlider.find(' .bxslider').bxSlider(
                getOptions()
            );

            $(window).on('vf::fontLoaded vf::resize orientationchange', updateSlider);

        };

        setUpSlider();

        $modSlider.one('slider@in-viewport', firstAppearFx);


    };


    var init = function(){

        var $carousels = $(settings.target);

        $carousels.each(function(){
            $(this).addClass('loading');
            createSlider($(this));
        });

        var scrollHandler = function(){
            $carousels.each(function(){

                if ( true === $(this).hasClass('visible')){

                    // is there any carousel, that has not loaded?
                    if (0 === $carousels.not('.visible').length){
                        $(window).off('scroll', scrollHandler);
                    }

                    return true;
                }
                if (true === vf.util.inViewport($(this))){
                    $(this).trigger('slider@in-viewport');
                }

                return true;
            });

        };

        $(window).scroll(scrollHandler);
        scrollHandler();

    };


    vf['carousel-module-large'] = {
        settings: settings,
        init: init
    };
}(vf));
