/**
 * @component countdown
 * @author Dennis Schnelle
 *
 * Javascripts
 */

(function () {
    'use strict';

    function appendListener (counter) {

        var buttons = document.getElementsByClassName('selectionCheck');

        buttons[counter].addEventListener('click', function () {

            var tile = buttons[counter].parentNode.parentNode;

            if (tile.classList.contains('selected')) {

                tile.classList.remove('selected');
            } else {

                tile.classList.add('selected');
            }
        });
    }

    setTimeout(function () {

        if (vf && vf['device-overview']) {

            vf['device-overview'].init(document.getElementsByClassName('device-overview-wrapper')[0]);

            var buttons = document.getElementsByClassName('selectionCheck');

            for (var counter = 0; counter < buttons.length; counter++) {

                appendListener(counter);
            }
        }
    }, 50);


})();
