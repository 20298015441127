/**
 * @module toolbar
 * @author Dennis Schnelle
 *
 * Does some tricks with the components overview window
 */

(function() {
    'use strict';

    var COMPONENTS_WRAPPER = $('.breadcrumb .mod-breadcrumb').parent().parent();

    $('html').on('click', '.component-navigator', function() {
        var index = $(this).attr('data-index');
        var $target = COMPONENTS_WRAPPER.children().eq(parseInt(index) + 1);

        console.log($target, $target.offset().top);

        $('html, body').animate({
            scrollTop: $target.offset().top - 20
        });
    });

    $('aside#jabof-debug .toggle-btn').on('click', function() {
        $('aside#jabof-debug').toggleClass('toggled');
    });
})();
