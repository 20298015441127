(function() {
    'use strict';
    vf.alert = {
        settings: {
            target: '.mod-alert',
            alertHTML: '<div class="alert {{alertType}}"><i class="i-{{alertType}}-b-sml"></i><div><h4><span class="access">Warning </span>{{title}}</h4> <p>{{message}}</p> </div> {{linkList}} </div>',
            ulHTML: '<ul class="wm-link-list">{{listItem}}</li>',
            liHTML: '<li> <a href="{{url}}"> <i class="i-dl-misc"></i> {{title}} <i class="i-arrow-right-black-lrg wm-arrow"></i> </a> </li>'
        },

        init: function(context) {
            var s = this.settings;

            $(s.target, context).addBack(s.target).each(function() {

                // Add click to links
                $(this).on('click', '.al-close', $.proxy(vf.alert.update, this));
                $(this).on('click', '.al-confirm', $.proxy(vf.alert.close, this));
            });
        },

        update: function() {

            var alert = $(this),
                message = alert.find('.al-content'),
                confirm = alert.find('.al-confirmation');

            message.hide();
            confirm.show();

        },

        close: function() {

            var alert = $(this);

            alert.addClass('alert-close');

            // setTimeout(function(){
            alert.remove();
            // },550);
        },

        /**
        * This is to add alert messages to a specific element and you have the option to select the alert type
        * and whether to replace the content of that element with the alert message or to just prepend it
        * Also you can add a list of anchors with the alert
        * example: vf.alert.insertAlert(".myclass",{title:"Warning..","Don't do this please"},true,"warning",[{title:"Download PDF", url:"/file.pdf"}])
        *
        * @method insertAlert
        * @param options {object} Main options object
        * @param options.dropTarget {string} This will be used as a selector and this represent the element which will contain the alert
        * @param options.alertTitle {string} This contains alert title. example: {title:"Warning...",message:"This will cause an error"}
        * @param options.alertMessage {string} This contains alert message. example: {title:"Warning...",message:"This will cause an error"}
        * @param options.replace {boolean} If true then the content of this element will be replaced with the alert if not then the alert will be prepended to the element
        * @param options.alertType {string} possible values are "warning","success" and "error" this will be the class name and will handle the look of the alert using CSS
        * @param options.itemsList {array} This is an array of objects which represents an item within the list. example: [{title:"Download PDF", url:"/file.pdf"}]
        */
        // {
        //  dropTarget:"",
        //  alertTitle:"",
        //  alertMessage:"",
        //  replace: false,
        //  alertType:"",
        //  itemsList:[{title:"",url:""}]
        // }
        insertAlert: function(options){
            var s = this.settings; //Module's Settings
            var o = options; //Method's Settings
            var el = $(o.dropTarget);
            var alertTitle = o.alertTitle || '';
            var alertType = o.alertType || 'warning';
            var replace = o.replace || false;
            var html = s.alertHTML;
            html = html.replace(/{{alertType}}/g, alertType).replace('{{title}}', alertTitle).replace('{{message}}', o.alertMessage);

            if (o.itemsList && 0 < o.itemsList.length){
                var items = '';
                for (var i = 0; i < o.itemsList.length; i++){
                    var item = o.itemsList[i];
                    var itemHTML = s.liHTML.replace('{{url}}', item.url).replace('{{title}}', item.title);
                    items = items + itemHTML;
                }
                var ulHTML = s.ulHTML.replace('{{listItem}}', items);
                html = html.replace('{{linkList}}', ulHTML);
            } else {
                html = html.replace('{{linkList}}', '');
            }
            if (el && 0 < $(el).length){
                if (replace){
                    $(el).html(html);
                } else {
                    $(el).prepend(html);
                }
            }

        },
        errorHandler: function(e, target){
            var statusCode = e.status;
            var errorMessage = window.globalconfig['serverCodeNumber' + statusCode];
            var errorWord = window.globalconfig.error;
            vf.alert.insertAlert({dropTarget: target, alertTitle: errorWord + ' ' + statusCode, alertMessage: errorMessage});
        }
    };
}(vf));
