(function () {//eslint-disable-line strict
    // jump mark
    var ctas = $('.text-only').find('a');
    ctas.each(function() {
        var jumpmark = $( this ).data( 'jumpmark' );
        if (undefined !== jumpmark && 0 < jumpmark.length && 'no-data' !== jumpmark) {
            var currentHref = $( this ).attr('href').trim();
            var href = null;
            if (0 === currentHref.indexOf('#')) {
                href = '#' + jumpmark;
            } else {
                var hashPos = currentHref.indexOf('#');
                if (-1 !== hashPos) {
                    currentHref = currentHref.substr(0, hashPos);
                }
                href = currentHref + '#' + jumpmark;
            }
            $( this ).attr('href', href);
        }
    });

}(vf));
