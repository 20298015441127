(function() {
    'use strict';
    vf['mph-flex-anchor'] = {
        settings: {
            target: '.mod-mph-flex-anchor',
        },

        init: function(context) {

            var s = this.settings;
            $(s.target, context).addBack(s.target).each(function() {
                var $this = $(this);

                var iWidth;
                var flexItems;
                var flexDivItems;

                var addInnerWidth = function(){

                    iWidth = document.body.clientWidth;

                    iWidth = 2 * Math.round( iWidth / 2);

                    //console.log (iWidth);
                    $this.find('.mph-flex-wrapper').css({ width: iWidth });
                };

                $(window).on('vf::resize', addInnerWidth);
                addInnerWidth();

                flexItems = $this.find('.mph-flex').length;
                $this.find('.mph-flex-wrapper').addClass( 'items' + flexItems );

                flexDivItems = $this.find('.mph-flex-divider').length;
                $this.find('.mph-flex-wrapper').addClass( 'div-items' + flexDivItems );
            });
        },
    };
}(vf));
