/**
 * @component passion-module
 * @author Timo Loeper
 *
 * Javascripts
 */

(function() {
    'use strict';
    var settings = {
        target: '.mod-passion-module'
    };

    var createEnjoyMoreOverview = function($overview) {

        var $passionResult = $overview.find('.passion-result');
        var lastTracked = [];

        var showResult = function() {
            $passionResult.show('400', function(){
                setTimeout(scrollToResult, 200);
            });
        };

        var insertResult = function($clickedTile) {
            var $clickedTileWrapper = $clickedTile.parent();
            $passionResult.find('.passion-result-headline').html($clickedTileWrapper.data('tiletitle'));
            $passionResult.find('.passion-result-description').html($clickedTileWrapper.data('tiledescription'));
            $passionResult.find('.passion-result-deeplink').attr('href', $clickedTileWrapper.data('tiledeeplink'));
            $passionResult.find('.passion-result-logo').html('<img src="' + $clickedTileWrapper.data('tilelogo') + '">');
        };

        var changeResult = function($clickedTile) {
            insertResult($clickedTile);
            showResult();
        };

        var trackResult = function($clickedTile) {
            var $tileWrapper = $clickedTile.parent();
            var tileTitle = $tileWrapper.data('tiletitle');
            var tileSKU = tileTitle.split('-')[0];
            var option = {
                sku: '',
                name: '',
                type: 'service',
                priceOnce: '0.00',
                priceMonthly: '0',
                duration: 24
            };

            var selectedOption = [option];
            var removedOption = lastTracked;

            selectedOption[0].sku = 'PYP' + tileSKU;
            selectedOption[0].name = 'Vodafone ' + tileTitle + ' inklusive';

            if (undefined === lastTracked[0] || selectedOption[0].sku !== lastTracked[0].sku) {
                _ddq.push(['switch enjoy more',
                    {
                        selectedOption: selectedOption,
                        removedOption: removedOption
                    }
                ]);
            }

            lastTracked = selectedOption;
        };

        var scrollToResult = function() {
            $('.mod-overlay .overlay .overlay-content').animate({
                scrollTop: $passionResult.offset().top
            }, 500);
        };

        var init = function() {
            $overview.find('.passion-tile a').on('click', function(event) {
                event.preventDefault();

                var $tile = $(event.target).closest('.passion-tile');
                if ($tile.hasClass('selected')) {
                    return;
                }

                $overview.find('.passion-tile.selected').removeClass('selected');
                $tile.addClass('selected');

                changeResult($(this).parent());
                trackResult($(this).parent());
            });

            $(window).on('vf::overlayopened', function(event, args) {
                if ('passionOverlay' === args.overlayId) {
                    _ddq.push(['layover', {
                        action: 'show',
                        name: 'enjoy more internal campaign'
                    }]);
                }
            });
        };

        init();
    };

    var init = function() {
        $(settings.target).each(function() {
            createEnjoyMoreOverview($(this));
        });
    };

    vf['passion-module'] = {
        settings: settings,
        init: init
    };
}(vf));
