/**
 * @component iphone-highlight-2017
 * @author Bastian Pertz
 *
 * Javascripts
 */

(function() {
    'use strict';
    // write your code
})();
