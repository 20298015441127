(function() {
    'use strict';

    vf.gigakombi = {

        discounts: {
    		'IP': 177,
            'TV': 612,
            'BR5': 618,
        },

        settings: {
            serviceURL: '/api/vfd2dyn/json/gigakombi-crypt/serviceaio.php'
        },

        isGigakombiDiscount: function(discountId) {
        	var response = (-1 < this.getDiscountIds().indexOf(discountId));
            return response;
        },

        getDiscountIds: function(){
            return [177, 612, 618];
        },

        cryptMSISDN: function(msisdn) {
            var data = {
                msisdn_c2: msisdn, // eslint-disable-line camelcase
                json: 1
            };

            var deferer = $.Deferred();// eslint-disable-line new-cap
            $.getJSON(this.settings.serviceURL, data, function(response) {
            	if (response && 'undefined' !== typeof response.cipher) {
                deferer.resolve(response);
            } else {
                deferer.reject();
            }
            }).fail(function() {
                deferer.reject();
            });
            return deferer;
        },

        getKDGLink: function(data) {
        	var url = (data && 'undefined' !== typeof data.kdglink) ? data.kdglink : '';
            return url;
        },

        sendOrderId: function(kdgCipher, olsOrderId) {
            var data = {
	            kdgCipher: kdgCipher,
	            olsOrderId: olsOrderId
	        };

            var deferer = $.Deferred();// eslint-disable-line new-cap
            $.getJSON(this.settings.serviceURL, data, function(response) {
            	if (response && 'undefined' !== typeof response.result && 'ok' === response.result) {
                deferer.resolve(response);
            } else {
                deferer.reject();
            }
            }).fail(function() {
                deferer.reject();
            });
            return deferer;
        },

    };

}(vf));
