//eslint-disable
$(function () {

    vf['hero-carousel'] = {
        settings: {
            carousel: '.mod-hero-carousel',
            blocks: '.ca-body .ca-block',
            body: '.ca-body',
            page: '.ca-page',
            pages: '.ca-pages',
            loader: '.ca-load-more',
            pagination: '.ca-pagination',
            nav: '.ca-nav a',
            prime_nav: '.ca-nav-primary',
            active_page: '.ca-active',
            show: '.ca-show'
        },

        init: function (context, events_bound) {

            var s = vf['hero-carousel'].settings;

			// check css3 3d transform support
            if (!('css3d' in s)) {
                var style = document.body.style;
                s.css3d = 'transform' in style || 'webkitTransform' in style;
            }

            $(s.carousel, context).addBack(s.carousel).each(function () {
                var carousel = $(this),
                    carousel_span = carousel.width(),
                    blocks = carousel.find(s.blocks),
                    span = 1,
                    maxHeight = -1,
                    rotation = carousel.data('auto-rotate');
                transition = carousel.data('transition');
                translation = Math.floor(carousel_span);

                blocks.innerWidth(translation);

				// settings

                var page = carousel.data('page_no');
                var _page_no = events_bound ? page : -1;
                var start_page = page ? page : 0;


                $.extend(carousel.data(), {
                    computed_span: span,
                    page_no: _page_no,
                    'page_count': Math.ceil(blocks.length) - 1,
                    translation: translation,
                    reversed: $(blocks.get().reverse()),
                    animation_span: 1,
                    touchstart: 0
                });

				// set maxHeight to tallest block + 3px to cater for shadows
                blocks.each(function () {
                    var height = $(this).outerHeight(true);
                    if (height > maxHeight) {maxHeight = height + 3;}
                });

				// set viewport dimensions
                carousel.find(s.body).height(maxHeight).width(translation);

				// set blocks initial position and visibility
                blocks.each(function (i) {
                    var block = $(this),
                        left = 0,
                        visibility = 1;

                    if (s.css3d) {left += translation;}
                    else if (i >= span) {
                        visibility = 0;
                    }

                    vf['hero-carousel'].hide(block, visibility);
                    block.css('left', left);
                });

				// set width
                if ('lrg' == vf.util.layout()) {

                    carousel.find(s.pages).find('li').css('width', 100 / (carousel.data('page_count') + 1) + '%');

                } else {

                    carousel.find(s.pages).find('li').removeAttr('style');

                }

				// hide navigation if there is only one page
                carousel.find(s.pagination + ',' + s.prime_nav).toggle(!!carousel.data('page_count'));

				// double binding safety net (true argument)
                if (!events_bound) {

                    carousel
						.on('click', s.page, $.proxy(vf['hero-carousel'].goto_page, carousel))
						.on('interval', s.page, $.proxy(vf['hero-carousel'].goto_page, carousel))
						.on('touchstart touchmove touchend', $.proxy(vf['hero-carousel'].touch, carousel))
						.find(s.nav).on('click', $.proxy(vf['hero-carousel'].get_goto_page, carousel));

					// Load first set of view more elements
                    carousel.find(s.loader).trigger('click');

                    $(window).on('vf::resize vf::lazyloaded', $.proxy(vf['hero-carousel'].init, this, context, true));

					// if auto rotate is defined, auto rotate carousel by given time interval
                    if (rotation) {
                        carousel.rotate = setInterval(function () {
                            var page = (carousel.data('page_no') + 1) % (carousel.data('page_count') + 1);

                            carousel.find(s.page).eq(page).trigger('interval');
                            carousel.data('page_no', page);
                        }, rotation);
                    }

                } else {

					// blocks.css('transform', 'translateX(0)');

                }

				// display none on the block for keyboard accessibility

				// REPLACE THIS AND FIND OUT WHY ITS NOT ANIMATING IN AND RECEIVING DISPLAY BLOCK
				//if (vf.util.layout() == 'lrg') blocks.css('display','none');

				// go to first page
                carousel.find(s.page).eq(start_page).trigger('interval');

            });
        },

		/* find the equivalent page indicator on primary nav click */
        get_goto_page: function (e) {
            var carousel = this,
                i = vf['hero-carousel'].cap(carousel.data('page_no') + $(e.currentTarget).data('direction'), 0, carousel.data('page_count')), // cap to range 0 and max page
                target = carousel.find(vf['hero-carousel'].settings.page).eq(i);

            vf['hero-carousel'].goto_page.apply(carousel, [e, target]);

        },

		/* animate carousel blocks */
        goto_page: function (e, target) {

            var carousel = this,
                s = vf['hero-carousel'].settings,
                blocks = carousel.find(s.blocks),
                span = 1,
                type = e.type;

            if ('click' == type || 'touchstart' == type) {
                clearTimeout(carousel.rotate);
            }

            if (!target) {target = $(e.currentTarget);}

            if (!target.is(s.active_page)) {
                var index = target.parent().index(),
                    page_no = carousel.data('page_no'),
                    min = index,
                    animation_in = [index, index],
                    animation_out = [(page_no - 1), index - 1],
                    animation_out_visible = [animation_out[0] + 1, animation_out[0] + carousel.data('animation_span')],
                    step = 0,
                    translation = -carousel.data('translation'),
                    transition = carousel.data('transition');


				// reverse block array if paginating to the left and recompute block index range animating out
                if (index < page_no) {
                    animation_out = [index + 1, animation_out_visible[1] + 1];
                    blocks = carousel.data('reversed');
                }

				// animate blocks individually
                blocks.each(function (i) {

                    var block = $(this),
                        i = block.index(),
                        animating_in = vf['hero-carousel'].in_range(i, animation_in);

                    if (animating_in) {
						// block.css('display', 'block');
                    }

                    if (s.css3d) {
                        var animating_out = vf['hero-carousel'].in_range(i, animation_out),
                            animation_id = block.data('animation_id'),
                            animate = 1,
                            _translation = translation;


                        if (animating_in || animating_out) {

                            if (animating_out) {

                                if (0 < index) {
                                    _translation *= 2;
                                }

                                if (index < page_no) {
                                    _translation = 0;
                                }

								// test: should the block animate?
                                if (!vf['hero-carousel'].in_range(i, animation_out_visible)) {
                                    animate = 0;
                                }
                            }

                            if (animation_id) {clearTimeout(animation_id);}

                            block.data('animation_id', setTimeout(function () {
                                block.css({
                                    transform: 'translateX(' + _translation + 'px)',
                                    transitionDuration: '.' + (5 * animate) + 's'
                                });
                            }, step * animate * transition));

                            block.toggleClass('ca-block-active', index == i);

                            step += 1 * animate;

                        }
                    } else {
                        visibility = animating_in ? 1 : 0;
                        vf['hero-carousel'].hide(block, visibility);
                    }

                });

				// set active page index
                carousel.data('page_no', index);

				// add active state to page
                carousel.find(s.active_page).removeClass('ca-active');
                target.addClass('ca-active');

            }

            e.preventDefault();
        },

		/* touch event handlers */
        touch: function (e) {
            var carousel = this,
                e = e.originalEvent;

            switch (e.type) {
            case 'touchstart':
                carousel.data('touchstart', e.touches[0].pageX);
                break;
            case 'touchmove':
                if (25 <= Math.abs(e.touches[0].pageX - carousel.data('touchstart'))) {e.preventDefault();}
                break;
            case 'touchend':
                var x = e.changedTouches[0].pageX,
                    i = carousel.data('touchstart');
                if (100 <= Math.abs(x - i)) {carousel.find(vf['hero-carousel'].settings.nav).eq(i > x ? 1 : 0).trigger('click');}
                break;
            }
        },

		/* util fn: check if x is in range of range[0](minimum) and range[1](maximum) */
        in_range: function (x, r) {
            return x >= r[0] && x <= r[1];
        },

		/* util fn: caps a value x between a(minimum) and b(maximum) */
        cap: function (x, min, max) {
            return x < min ? min : x > max ? max : x;
        },

		// Temporary fix the IE8 overlapping
        hide: function (e, visibility) {
            e.css('display', visibility ? 'block' : 'none');
            e.css('z-index', visibility);
        }
    };
}(vf));
