/**
 * @component enterprise-tile-accordion
 * @author Gilles Meyer
 *
 * Javascripts
 */

(function () {
    'use strict';

    var settings = {
        target: '.mod-ep-tile-accordion',
        itemSelector: '.mod-ep-tile-accordion__item',
        tileSelector: '.mod-ep-tile-accordion__item-tile',
        tileContentSelector: '.mod-ep-tile-accordion__item-tile-content',
        activeClass: 'mod-ep-tile-accordion__item--active',
        closeIconSelector: '.mod-ep-tile-accordion__item-content-close'
    };

    var init = function () {
        $(settings.target).each(function (index, elem) {
            setupItems(elem);
        });
    };

    var setupItems = function (container) {
        $(container).find(settings.itemSelector).each(function (index, item) {
            // If init is called twice, do not init already initialized items
            if (!$(item).data('ep-enterprise-tiles-setup')) {
                setupItem(item);
                $(item).data('ep-enterprise-tiles-setup', true);
            }
        });
    };

    var setupItem = function (item) {
        $(item).find(settings.tileSelector).on('click', onTileClick);
        $(item).find(settings.closeIconSelector).on('click', onCloseClick);
    };


    // Utility Functions
    var openItem = function (item) {
        closeOtherItems(item);
        $(item).addClass(settings.activeClass);
    };

    var closeOtherItems = function (openedItem) {
        $(openedItem).siblings().removeClass(settings.activeClass);
    };

    var closeItem = function (item) {
        $(item).removeClass(settings.activeClass);
    };

    var isItemOpen = function (item) {
        return $(item).hasClass(settings.activeClass);
    };

    var toggleItem = function (item) {
        if (isItemOpen(item)) {
            closeItem(item);
        } else {
            openItem(item);
        }
    };

    // Event Based
    var onTileClick = function (evt) {
        var $clickedItem = $(evt.currentTarget).parents(settings.itemSelector).eq(0);
        toggleItem($clickedItem);
    };

    var onCloseClick = function (evt) {
        var $clickedItem = $(evt.currentTarget).parents(settings.itemSelector).eq(0);
        closeItem($clickedItem);
    };

    window.vf['ep-tile-accordion'] = {
        init: init
    };
})();
