/**
 * @component enterprise-component-roles
 * @author gilles meyer
 *
 * Javascripts
 */

(function () {
    'use strict';

    var settings = {
        'container': '.mod-enterprise-component-roles',
        'itemSelectorShowAttribute': 'data-restrict-show',
        'itemSelectorHideAttribute': 'data-restrict-hide',
        'attrDataRole': 'data-role-data',
        'attrDataJSProcessor': 'data-js-processor',
        'attrDataAjax': 'data-content-ajax',
        'attrDataCallback': 'data-after-callback',
        'attrDataConditionProcessor': 'data-condition-processor'
    };

    function init() {
        $(settings.container).each(function (index, elem) {
            initialSetup($(elem));
            loadData($(elem));
        });
    }


    function initialSetup($container) {
        $container.on('execute', function (evt, data, customHTML) {
            execute(data, $(evt.currentTarget), customHTML);
        });
        // Can not be done right after init, except we have data written in data-role-data
        if ($container.attr(settings.attrDataRole)) {
            try {
                var dataString = $container.attr(settings.attrDataRole);
                var data = JSON.parse(dataString);
                execute(data, $container);
            } catch (e) {
                console.warn('Content Role for container could not be parsed', $container, e);
            }
        }
    }

    function loadData($container) {
        var url = $container.attr(settings.attrDataAjax);
        if (!url) {
            $container.data('loadedData', null);
        } else {
            $.get(url).done(function (data) {
                checkExecute($container, data);
            }).fail(function (e) {
                console.warn('Role Data: Could not load Ajax Data', e);
                $container.data('loadedData', null);
            });
        }
    }

    function checkExecute($container, ajaxData) {
        $container.data('loadedData', ajaxData);
        if ('undefined' !== (typeof $container.data('roleData'))) {
            execute($container.data('roleData').roleData, $container, ajaxData);
        }
    }

    function shouldWaitForAjax($container) {
        var hasAjax = !!$container.attr(settings.attrDataAjax);
        var wasLoaded = 'undefined' !== (typeof $container.data('loadedData'));

        return hasAjax && (!wasLoaded);
    }

    function executeForAll(roleData) {
        $(settings.container).each(function (index, elem) {
            execute(roleData, $(elem));
        });
    }

    function execute(roleData, $container, customHTML) {
        if (!$container) {
            return executeForAll(roleData);
        }
        customHTML = ($container && $container.data('roleData') && $container.data('roleData').customHTML) ? container.data('roleData').customHTML : customHTML;
        customHTML = ($container && $container.data('loadedData') && $container.data('loadedData')) ? $container.data('loadedData') : customHTML;
        $container.data('roleData', {
            'roleData': roleData,
            'customHTML': customHTML
        });

        if (!shouldWaitForAjax($container)) {
            processContainer($container, roleData, customHTML);
        }
        return null;
    }

    function processContainer($container, roleData, customHTML) {
        var stepFunctionString = $container.attr(settings.attrDataJSProcessor);
        var stepFunction = (stepFunctionString) ? extractFunctionFromString(stepFunctionString) : null;

        var conditionFunctionString = $container.attr(settings.attrDataConditionProcessor);
        var conditionFunction = (conditionFunctionString) ? extractFunctionFromString(conditionFunctionString) : null;

        var $innerItems = $container.find('[' + settings.itemSelectorShowAttribute + '],[' + settings.itemSelectorHideAttribute + ']');

        if (0 < $innerItems.length) {
            processItems($innerItems, roleData, stepFunction, conditionFunction);
        }

        if (customHTML) {
            var $costumHtml = $('<div></div>').html(customHTML);
            var $innerCustomHtml = $costumHtml.find('[' + settings.itemSelectorShowAttribute + '],[' + settings.itemSelectorHideAttribute + ']');

            if (0 < $innerCustomHtml.length) {
                processItems($innerCustomHtml, roleData, stepFunction, conditionFunction);
            }

            $container.append($costumHtml.html());
        }

        var afterCallbackString = $container.attr(settings.attrDataCallback);
        var afterCallbackFunction = (afterCallbackString) ? extractFunctionFromString(afterCallbackString) : null;

        if (afterCallbackFunction) {
            afterCallbackFunction($container, roleData);
        }
    }

    function processItems($items, roleData, stepFunction, conditionFunction) {
        $items.each(function (index, elem) {
            var showItem = processItem($(elem), roleData, conditionFunction);
            if (showItem && 'function' === (typeof stepFunction)) {
                stepFunction($(elem), $items, roleData);
            }
        });
    }

    function extractFunctionFromString(functionString) {
        var current = window;
        var steps = functionString.trim().split('.');
        for (var i = 0; i < steps.length; i++) {
            current = current[steps[i]];
            if (!current) {
                console.warn('Problem with ' + functionString, steps.slice(0, i + 1).join('.') + ' is not an object or function');
                return null;
            }
        }
        return current;
    }


    function processItem($item, roleData, externalConditionFunction) {
        var showCondition = $item.attr(settings.itemSelectorShowAttribute);
        var hideCondition = $item.attr(settings.itemSelectorHideAttribute);

        var conditionFunction = (showCondition) ? createConditionalFunction(showCondition, externalConditionFunction, $item) : createConditionalFunction(hideCondition, externalConditionFunction, $item);
        var result = true;
        try {
            result = conditionFunction(roleData);
        } catch (e) {
            console.warn('Condition could not be parsed', e);
        }
        if (showCondition) {
            !result && $item.remove();
        } else if (hideCondition) {
            result && $item.remove();
        }
    }

    function createConditionalFunction(condition, externalConditionFunction, $item) {

        // External Conditional Function will be used
        if (externalConditionFunction) {
            return function (data) {
                return externalConditionFunction(data, condition, $item);
            };
        }
        // eslint-disable-next-line no-new, no-new-func
        return new Function('data', 'return ' + condition);
    }


    window.vf['enterprise-component-roles'] = {
        'init': init,
        'execute': execute
    };

})();
