(function() {

    'use strict';

    var settings = {
        target: '.mod-contact-form-enterprise'
    };

    var createContactForm = function($contactForm) {

        var setError = function($input){
            $input.addClass('error');
            var errorText = $input.data('error-message');
            $input.parent().find('.additional-label').text(errorText);
            $input.parent().find('.additional-label').addClass('error');
        };

        var clearError = function($input){
            $input.removeClass('error');
            $input.parent().find('.additional-label').text('');
            $input.parent().find('.additional-label').removeClass('error');
        };

        var setLabelToTop = function($input) {
            if (undefined === $input.length){
                $input = $(this);
            }
            clearError($input);
            var $label = $input.prev('label');
            $label.addClass('focused-input');
        };
        var setLabelToInput = function() {
            if ('' === $(this).val()) {
                var $label = $(this).prev('label');
                $label.removeClass('focused-input');
            }
        };

        var validateAllFields = function(){
            var valid = true;

            $contactForm.find('input.required, select.required').each(function(){
                if ('' === $(this).val()){
                    valid = false;
                    setError($(this));
                }
            });
            return valid;
        };

        var checkIfAlreadyFilled = function(){
            $contactForm.find('input').each(function(){
                if ('' !== $(this).val()){
                    setLabelToTop($(this));
                }
            });
        };

        var init = function() {
            $contactForm.find('input').on('blur', setLabelToInput);
            $contactForm.find('input').on('focus', setLabelToTop);

            $contactForm.find('label').on('click', function(){
                $(this).next('input').trigger('focus');
            });

            $contactForm.find('select').niceSelect();
            $contactForm.find('form').submit(function(event){
                if (false === validateAllFields()){
                    event.preventDefault();
                }
            });
            checkIfAlreadyFilled();
        };
        init();
    };

    var init = function() {
        $(settings.target).each(function() {
            createContactForm($(this));
        });
    };

    vf['contact-form-enterprise'] = {
        settings: settings,
        init: init
    };
}(vf));
