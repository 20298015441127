$(function() {

    var settings = {
        target: '.mod-stage-image'
    };

    var useBgImage = function($stageImage){
        var $img = $stageImage.find('img');
        if (undefined === $img.get(0)){
            return;
        }

        $stageImage.addClass('bg-cover');
        $stageImage.css('background-image', 'url(' + $img.attr('src') + ')');
    };

    var init = function(){

        $(settings.target).each(function(){

            useBgImage($(this));
        });
    };

    vf['stage-image'] = {
        settings: {
        },
        init: init
    };

}(vf));

