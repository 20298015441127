(function() {

    'use strict';

    vf['device-tariff-tiles'] = {

        settings: {
            target: '.mod-device-tariff-tiles'
        },

        init: function(context) {

            var s = this.settings;

            $(s.target, context).addBack(s.target).each(function() {

                var parentElement = $(this);

                /**
                 * initialize General Offer Service and loadVLUX Data
                 */
                generalOfferService.btx = parentElement.data('btx') || 'newContract';
                generalOfferService.salesChannel = parentElement.data('saleschannel') || 'Online.Consumer';

                generalOfferService.loadVluxData().then( function () {
                    /**
                     * Data loaded, then let's check the offers
                     */
                    parentElement.find('.phoneContainer').each(function() {
                        vf['device-tariff-tiles'].executeTile($(this));
                    });
                });

            });

        },

        formatCurrency: function (val) {

            var ret = val;

            try {

                if ('string' === typeof val) {
                    ret = parseFloat(val);
                }
                ret = ret.toFixed(2).replace('.', ',');

            } catch (e) {}

            return ret;

        },

        executeTile: function (item, tariffId, deviceId) {

            var deviceId = parseInt(item.data('vlux-deviceid')),
                tariffId = parseInt(item.data('vlux-tariffid')),
                found = false;

            if (deviceId && tariffId) {

                /**
                 * get the offer by atomic-id
                 */
                var offer = generalOfferService.getSimHardwareOfferByAtomicDeviceIdAndSubscriptionId(
                    deviceId, tariffId
                );

                if (undefined !== offer) {

                    found = true;

                    /* Device */
                    var device = offer.offerComponents.device;
                    var deviceEnrichedData = device.getEnrichedData();

                    $(item).find('.phoneBox').addClass(device.attributes.productType);
                    $(item).find('h3').html(deviceEnrichedData.deviceLabel);
                    $(item).find('img').attr('src', device.attributes.nsfImage170_230);
                    $(item).find('img').attr('alt', deviceEnrichedData.label);

                    /* Tariff */
                    var subscriptionEnrichedData = offer.offerComponents.subscription.getEnrichedData();

                    $(item).find('.tariffName').html(subscriptionEnrichedData.label);

                    /* Prices */
                    var offerCosts = offer.offerCosts;
                    var costsAfterRuntime = offerCosts.costsAfterRuntime;
                    var costsAfterRuntimeLength = costsAfterRuntime.length;

                    var devicePrice = this.formatCurrency(offerCosts.devicePriceOnetime.value);
                    var tariffPriceDiscount = this.formatCurrency(offerCosts.monthlyTotal);

                    $(item).find('.devicePrice').html(devicePrice);
                    $(item).find('.tariffPriceDiscount').html(tariffPriceDiscount);

                    if (1 < costsAfterRuntimeLength) {

                        var tariffPriceRegular = this.formatCurrency(costsAfterRuntime[1].totalCost);

                        $(item).find('.monthDate').html(costsAfterRuntime[1].month);
                        $(item).find('.tariffPriceRegular').html(tariffPriceRegular);
                        $(item).find('.monthUntil').show();

                    } else {

                        $(item).find('.monthUntil').hide();

                    }

                }
            }

            if (!found) {
                $(item).remove();
            }
        }
    };

}(vf));
