(function () {//eslint-disable-line strict

    if (undefined === $.fn.disableSelection) {
        $.fn.disableSelection = function () {
            return this
                .attr('unselectable', 'on')
                .css('user-select', 'none')
                .on('selectstart', false);
        };
    }

    /**
     * Normalize transitionEnd Event
     */
    var transitionendEventName = (function () {

        var $element = $('<div>').get(0);

        var transitions = {
            'transition': 'transitionend',
            'OTransition': 'otransitionend',  // oTransitionEnd in very old Opera
            'MozTransition': 'transitionend',
            'WebkitTransition': 'webkitTransitionEnd'
        };

        for (var i in transitions) {
            if (transitions.hasOwnProperty(i) && $element.style[i] !== undefined) {
                return transitions[i];
            }
        }

        return false;

    })();

    /**
     * Stacking cards is handled by css classes
     * @param $firstOnStack is topmost card on stack. if not set last .card in .card-stack is used
     */
    var setCardsOnStack = function ($firstOnStack) {

        // if nothing set, use the last element as firstOnStack
        if (undefined === $firstOnStack) {
            $firstOnStack = $('.cards-stack .card:nth-last-child(1)');
        }

        $firstOnStack.addClass('firstOnStack').removeClass('secondOnStack').removeClass('thirdOnStack')
            .prev('.card').addClass('secondOnStack').removeClass('firstOnStack').removeClass('thirdOnStack')
            .prev('.card').addClass('thirdOnStack').removeClass('firstOnStack').removeClass('secondOnStack');
    };

    /**
     * Popout first card by adding class that has css3 animations
     * prepend card to stack, too
     */
    var popoutCard = function () {

        var $firstOnStack = $('.cards-stack .firstOnStack');

        $firstOnStack.removeClass('firstOnStack');
        $('.cards-stack').prepend($firstOnStack.clone());

        $firstOnStack.addClass('popout');

        setCardsOnStack($('.cards-stack .secondOnStack'));

        $('.popout').one(transitionendEventName, function () {
            $firstOnStack.remove();
        });

    };

    /**
     * Make cards equal height in mobile and tablet and bubble height to parent elements
     */
    var equalHeight = function () {
        /* fixed height in desktop in css, no height by style
        */
        if ('desktop' === vf.util.layout(true)) {
            $('.card').css({
                'height': ''
            });

            $('.reference-teaser-cards').css({
                height: ''
            });

            $('.cards-stack').css({
                height: ''
            });
            return;
        }

        var height = 0;
        $('.card .cite').each(function () {
            if (height < $(this).outerHeight() + 20) {
                height = $(this).outerHeight() + 20;
            }
        });

        $('.card').css({
            height: height + 'px'
        });

        $('.reference-teaser-cards').css({
            height: height + 18 + 'px'
        });

        $('.cards-stack').css({
            height: height + 'px'
        });

    };


    /*
     * Setup all touchevents and their handle
    */
    var enableTouch = function () {

        var snapBackThreshold = 20;
        var flyOutThreshold = 50;

        var touch = {
            start: {
                left: 0,
                top: 0
            },
            current: {
                left: 0,
                top: 0
            },
            last: {
                left: 0,
                top: 0
            },
            direction: false
        };

        var emptyTochObject = $.extend(true, {}, touch);

        /**
         * Fly out card and reorder stack
         */
        var flyOut = function ($target) {

            var animateClass = 'flyOutLeft';
            if (true === $target.hasClass('toRight')) {
                animateClass = 'flyOutRight';
            }

            $target.removeClass('touch').removeClass('toLeft').removeClass('toRight');

            var height = $target.height();
            $target.removeAttr('style').find('.card-rotate').removeAttr('style');

            // @TODO add threshold
            $target.removeClass('firstOnStack');
            $('.cards-stack').prepend($target.clone().height(height));

            $target.addClass(animateClass);

            setCardsOnStack($('.cards-stack .secondOnStack'));

            $('.' + animateClass).one(transitionendEventName, function () {
                $target.remove();
            });

        };

        /**
         * touch start delegation
         */
        $('.cards-stack').on('touchstart', '.firstOnStack', function (evt) {

            if ('desktop' === vf['reference-teaser'].viewport) {
                return;
            }

            // disable transitions by adding class
            $(evt.currentTarget).addClass('touch');

            touch = $.extend(true, {}, emptyTochObject);

            touch.start.left = event.targetTouches[0].pageX;
            touch.start.top = event.targetTouches[0].pageY;

            touch.current.left = event.targetTouches[0].pageX;
            touch.current.top = event.targetTouches[0].pageY;

        });

        /**
         * touch end delegation
         */
        $('.cards-stack').on('touchend', '.firstOnStack', function (evt) {

            if ('desktop' === vf['reference-teaser'].viewport) {
                return;
            }

            var $target = $(evt.currentTarget);

            // if card is alreay gone while touchmove passes threshold, don't handle card again here
            if (false === $target.hasClass('touch')) {

                touch = $.extend(true, {}, emptyTochObject);
                return;
            }

            var diff = Math.abs(touch.current.left - touch.start.left);

            if (snapBackThreshold > diff) {
                //clear animation classes and remove inlinestyles
                $target.removeClass('touch').removeClass('toLeft').removeClass('toRight');
                $target.removeAttr('style').find('.card-rotate').removeAttr('style');
                // call the eq height mehtod agian to avoid ugly card sizes...
                equalHeight();
            }
            else {
                flyOut($target);
            }

            touch = $.extend(true, {}, emptyTochObject);

        });

        /**
         * touched and moving
         */
        $('.cards-stack').on('touchmove', '.firstOnStack', function (evt) {

            if ('desktop' === vf['reference-teaser'].viewport) {
                return;
            }

            var $target = $(evt.currentTarget);
            var $rotate = $target.find('.card-rotate');

            touch.last.left = touch.current.left;
            touch.last.top = touch.current.top;

            touch.current.left = event.targetTouches[0].pageX;
            touch.current.top = event.targetTouches[0].pageY;

            var diff = touch.current.left - touch.start.left;

            var sign = 1;

            if (0 > diff) {
                sign = -1;
                touch.direction = 'left';
                //@TODO Naming to RotateBottomRightCorner or something like that
                $target.addClass('toLeft').removeClass('toRight');
            }
            else {
                sign = 1;
                touch.direction = 'right';
                $target.addClass('toRight').removeClass('toLeft');
            }


            var rotateDiff = Math.abs(diff);

            rotateDiff = rotateDiff / flyOutThreshold * flyOutThreshold;

            if (rotateDiff > flyOutThreshold) {
                flyOut($target);
                return;
            }

            $target.css({
                'top': '1px',
                'left': diff + 'px',
                'opacity': 1 - rotateDiff / 100
            });

            $rotate.css({
                'transform': 'rotate(' + (sign * 0.1 * rotateDiff) + 'deg)'
            });

        });

    };

    /**
     * chache viewport to prevent invoking vf.util.layout on each touchevent
     */
    var getViewport = function () {

        vf['reference-teaser'].viewport = vf.util.layout(true);

    };

    var init = function () {

        $(window).on('vf::resize vf::lazyloaded orientationchange', equalHeight);
        equalHeight();

        $(window).on('vf::resize orientationchange', getViewport);
        getViewport();

        $('.cards-controll').disableSelection();

        $('.cards-stack').addClass('animation');

        setCardsOnStack();

        $('.cards-controll').on('click', popoutCard);

        if (true === vf.util.is_touch_device) {
            enableTouch();
        }
    };

    vf['reference-teaser'] = {
        settings: {
        },
        viewport: undefined,
        init: init
    };
}(vf));
