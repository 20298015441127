$(function () {
    'use strict';

    var configParameterToSlickOptionMapping = {
        'hs': {
            'option': 'initialSlide',
            'modifier': function (val) {
                var num = parseInt(val, 10);
                if (!isNaN(num) && 0 < parseInt(val)) {
                    return num - 1;
                }
                return null;
            }
        },
        'autoplay': {
            'modifier': function (val) {
                return ('true' === val || '1' === val);
            }
        },
        'ap': {synonym: 'autoplay'}
    };

    var init = function (conf) {
        configParameterToSlickOptionMapping = Object.assign({}, configParameterToSlickOptionMapping, conf || {});
    };

    var getOption = function (optionname, value) {
        var opt = {};
        var key = null;
        var confObject = configParameterToSlickOptionMapping[optionname];

        // If true keep all as in url parameter
        if (true === confObject) {
            key = optionname;
        }

        // Synonym ?
        if ('object' === (typeof confObject) && confObject.synonym) {
            return getOption(confObject.synonym, value);
        }

        // Maybe modify the value if needed
        if ('object' === (typeof confObject) && confObject.modifier) {
            value = confObject.modifier(value);
        }

        if ('object' === (typeof confObject) && confObject.option) {
            // Key is different at Slickslider
            key = confObject.option;
        } else if ('object' === (typeof confObject) && !confObject.option) {
            // Key keeps the same as in url parameter
            key = optionname;
        }


        // Prepare object if was one correct format
        if (key) {
            opt[key] = value;
        }

        return opt;
    };

    var getOptions = function () {
        var allOpts = window.vf['url-parameter-parser'].getUrlAsObject();
        var slickOpts = {};
        for (var key in allOpts) {
            if (configParameterToSlickOptionMapping[key]) {
                slickOpts = Object.assign(slickOpts, getOption(key, allOpts[key]));
            }
        }
        return slickOpts;
    };


    vf['ep-herospace-parameter-configurator'] = {
        init: init,
        getOptions: getOptions
    };
}(vf));
