(function () {//eslint-disable-line strict

    var settings = {
        target: '.mod-iphone-highlight'
    };

    var toogleViewport = function(){

        var useViewport = vf.util.layout(true);

        if ('tablet' === useViewport){
            useViewport = 'desktop';
        }


        var sectionCurrentWidth = $(settings.target).width();

        if ('mobile' === useViewport){
            $(settings.target).css({
                left: -1 * (320 - sectionCurrentWidth) / 2 + 'px'
            });

            sectionCurrentWidth = 320;
        }
        else {

            if (928 > sectionCurrentWidth){
                $(settings.target).css({
                    'overflow-x': 'scroll'
                });
            }
            else {
                $(settings.target).css({
                    'overflow-x': ''
                });

            }

            $(settings.target).css({
                left: '0px'
            });

            sectionCurrentWidth = 928;
        }

        $(settings.target + ' .iphone-section').each(function(){
            var $section = $(this);

            var ratio = parseInt($section.attr('data-' + useViewport + '-width'), 10) / sectionCurrentWidth;

            $section.css({
                width: parseInt($section.attr('data-' + useViewport + '-width'), 10) / ratio + 'px',
                height: parseInt($section.attr('data-' + useViewport + '-height'), 10) / ratio + 'px'
            });

            $section.find(' .content-wrap').each(function(){
                var $text = $(this);

                $text.css({
                    top: parseInt($text.attr('data-' + useViewport + '-top'), 10) / ratio + 'px',
                    left: parseInt($text.attr('data-' + useViewport + '-left'), 10) / ratio + 'px',
                    width: parseInt($text.attr('data-' + useViewport + '-width'), 10) / ratio + 'px'
                });

            });

            $section.find(' .btn').each(function(){
                var $button = $(this);
                $button.css({
                    top: parseInt($button.attr('data-' + useViewport + '-top'), 10) / ratio + 'px',
                    left: parseInt($button.attr('data-' + useViewport + '-left'), 10) / ratio + 'px'
                });

            });


        });


    };

    var init = function(){

        if (undefined === $('.mod-iphone-highlight').get(0)){
            return;
        }
        /*
        $('body').css({
            'min-width': '965px'
        });
        */

        $(window).on('vf::resize orientationchange', toogleViewport);
        toogleViewport();



    };

    vf['iphone-highlight'] = {
        settings: settings,

        init: init
    };

}(vf));
