(function() {
    'use strict';
    vf['search-open'] = {

        init: function (context){

            $('.open-search').on('click', function() {

                $('.nav-primary .nav-menu li').removeClass('nav-active');
                $('.nav-secondary .nav-content').removeClass('nav-active');

                if ($(this).parent().hasClass('active')) {
                    $('.nav-primary .w-25').css( 'overflow', 'hidden' );
                    setTimeout(function() {
                        $('.nav-primary .w-25').css( 'width', '40px' );
                    }, 500);
                } else {
                    $(this).parent().css( 'width', '100%' );
                    setTimeout(function() {
                        $('.nav-primary .w-25').css( 'overflow', 'visible' );
                    }, 500);
                }
                $(this).parent().toggleClass('active');

            });

            $( window ).resize(function() {

                if (1024 < ($( window ).width())) {
                    $('.nav-primary .w-25').removeAttr( 'style' );
                    $('.nav-primary .w-25').removeClass( 'active' );
                }

            });
        }
    };
})(vf);
