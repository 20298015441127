(function () {
    vf['full-background'] = {
        settings: {
            fbContainer: '.mod-full-background',
            fbParallax: '.mod-full-background .full-background-parallax'

        },
        data: {
            fbContainerList: null,
            parallaxContainerList: null,
            windowHeight: $(window).innerHeight()
        },

        init: function (context) {
            var s = this.settings;

            this.data.fbContainerList = $(s.fbContainer, context);
            this.data.parallaxContainerList = $(s.fbParallax, context);

            this.data.parallaxContainerList.each(function () {

                var thisObj = $(this);
                var parallaxImage = thisObj.find('img').filter(':visible');

                thisObj.css({
                    'background-image': 'url(' + parallaxImage.attr('src') + ')'
                });

				// if the background-image can not be resized (in older IEs), show the dummy image as fallback
                if (document.createElement('detect').style.backgroundSize == undefined ||
					document.createElement('detect').style.backgroundAttachment == undefined) {
                    parallaxImage.css('visibility', 'visible');
					// TODO: switch visible image on resize (if there is a change from sml to lrg)
                }

				// force recalculation if images are lazyloaded
                thisObj.next().find('img').on('load', function () {

                    var bgContainerObj = $(this).closest('.mod-full-background'); //.find('.full-background-parallax');
                    vf['full-background'].getSingleContainerDimensions(bgContainerObj);

                });

            });

            $(window)
				.on('vf::resize', function () {

					// resize isn't triggered on object basis, so this is done when the window-size is changed
    vf['full-background'].getContainerDimensions();

})
				.on('scroll', function () {

					// scroll isn't triggered on object basis, so this is done when the window-size is changed
    vf['full-background'].setAllParallaxImagePositions();

});

			// init measures (with delay to react to lazyloading)
            window.setTimeout(function () {

                vf['full-background'].getContainerDimensions();

            }, 500);
        },

        getContainerDimensions: function () {

            vf['full-background'].data.windowHeight = $(window).innerHeight();

            vf['full-background'].data.fbContainerList.each(function () {


                var isChanged = vf['full-background'].getSingleContainerDimensions($(this));

                if (isChanged) {
					// force repositioning
                    vf['full-background'].setAllParallaxImagePositions();
                }

            });

        },

        getSingleContainerDimensions: function (containerObj) {

            var containerHeight = containerObj.innerHeight();
            var bgImage = containerObj.find('.full-background-bg-image').not('.full-background-parallax').find('img').filter(':visible'); // parallax images are treated seperately

            var parallaxContainer = containerObj.find('.full-background-parallax');
            var parallaxImage = parallaxContainer.find('img').filter(':visible');

			// check if the bg dimensions fit for the normal bg images (no parallax)
            if (0 < bgImage.length) {




				// first set the default
                bgImage.css({
                    height: '100%',
                    width: '100%',
                    left: 0
                });

                var bgImageHeight = bgImage.height();

				// then check if it fits
                if (bgImageHeight <= containerHeight) {


                    bgImage.css({
                        height: '100%',
                        width: 'auto',
                        position: 'relative'

                    });

					// then center the image
                    var containerWidth = containerObj.innerWidth();
                    var bgImageWidth = bgImage.width();

                    var newPosition = (containerWidth - bgImageWidth) / 2;

                    bgImage.css({
                        left: newPosition + 'px'

                    });

                }
                else {

                }
            }

			// set parallax image as background if there is one; needs to be done again on resize because the image may switch
            if (0 < parallaxImage.length) {
                parallaxContainer.css({
                    'background-image': 'url(' + parallaxImage.attr('src') + ')'
                });

				// save the parallax size data
                var oldContainerHeight = parallaxContainer.data('containerheight');
                var oldImgHeight = parallaxContainer.data('imgheight');
                var parallaxContainerHeight = parallaxContainer.outerHeight();
                var parallaxImgHeight = parallaxImage.height();

                if (oldContainerHeight === parallaxContainerHeight && oldImgHeight === parallaxImgHeight) {
					// no changes
                    return false;
                }
                else {

					// save in data-obj
                    parallaxContainer.data({
                        'containerheight': containerHeight,
                        'imgheight': parallaxImgHeight
                    });

                    return true;
                }

            }

        },

        setAllParallaxImagePositions: function () {
            vf['full-background'].data.parallaxContainerList.filter(':visible').each(function () {

                vf['full-background'].setParallaxImagePosition(this);

            });
        },

        setParallaxImagePosition: function (parallaxContainer) {

            var parallaxContainerObj = $(parallaxContainer);

            var containerTop = parallaxContainer.getBoundingClientRect().top; //parallaxContainerObj.offset().top;

            var containerHeight = parseInt(parallaxContainerObj.data('containerheight'));
            var imgHeight = parseInt(parallaxContainerObj.data('imgheight'));

            var windowHeight = vf['full-background'].data.windowHeight;

            if (0 < containerTop + containerHeight  // container is lower than upper border
				&& 0 > containerTop - windowHeight // container is higher than lower border
			) {
                var scrollLength = windowHeight + containerHeight; // this is the length where the parallax effect will be visible

                var parallaxScroll = containerTop - (((containerTop) / scrollLength) * (imgHeight - containerHeight));


                parallaxContainerObj.css(
					{ 'backgroundPosition': '0 ' + parallaxScroll + 'px' }
				)
					.find('img').css(   // move fallback image
					{ 'top': parallaxScroll + 'px' }
					);

				//parallaxContainerObj.nextAll('.belt').find('.grid div')
				//.html('<span style="background-color:white">' + scrollLength + ' / ' + containerHeight + ' / ' +  imgHeight  + ' / ' + containerTop + ' / ' + parallaxScroll + '</span>' );
            }

        }
    };
}(vf));
