/**
 * @component tariff-group-switcher
 * @author Marcel Weiler
 *
 * Javascripts
 */

(function() {
    'use strict';
    // write your code
})();
