(function () {
    vf.banner = {
        settings: {
            target: '.mod-banner'
        },

        init: function (context) {
            var s = this.settings;
            banners = $(s.target);

			// Bind events
            $(window).on('vf::resize', vf.banner.updateBannerColor);

			//startup
            vf.banner.updateBannerColor();

        },

        updateBannerColor: function () {

            if ('lrg' == vf.util.layout()) {

                banners.each(function () {

                    banner = $(this);

					// find the hex value in the data-style attribute
                    var hex = banner.data('style');
                    var style = banner.attr('style');

					// convert hex value to RGB
                    if (hex !== undefined && style == undefined) {

                        var bigint = parseInt(hex, 16);
                        var r = (bigint >> 16) & 255;
                        var g = (bigint >> 8) & 255;
                        var b = bigint & 255;
                        var a = banner.data('opacity') ? banner.data('opacity') : 0.8;
                        if ('e60000' == hex) {
                            a = 0.4;
                        }

						// add inline styles for RGBA and IE fallback filter
                        banner.attr('style', function (i, val) {
                            return 'background-color:#' + hex + ';background-color:rgba(' + r + ',' + g + ',' + b + ',' + a + ');-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";';
                        });
                    }

					// find the hex value for the text color
                    var textHex = banner.data('text');

                    if (textHex !== undefined) {
                        banner.find('.txt-wrap').css('color', '#' + textHex);
                    }

					// give background color to the all teaser
                    var backgr = banner.data('background');

                    if (backgr !== undefined) {
                        banner.parent($('.teaser')).css('background', '#' + backgr);
                    }
                });

            } else {
				// remove inline styles if mobile
                banners.removeAttr('style');
            }
        }
    };

}(vf));
