(function() {

    'use strict';

    var settings = {
        target: '.mod-handy-slider'
    };

    /**
     *
     */
    var bxOptions = {
        minSlides: 5,
        maxSlides: 5,
        infiniteLoop: false,
        hideControlOnEnd: true,
        slideWidth: 0,
        slideMargin: 0,
        moveSlides: 6,
        slideSelector: 'li.bxslider-item',
        adaptiveHeight: false,
        easing: 'ease',
        speed: 400,
        preloadImages: 'all'
    };

    /**
     * Create and maintain slider
     */
    var createSlider = function($modSlider){

        var slider;

        var defaultOptions = $.extend( {}, bxOptions);
        var instanceOptions = $.extend( {}, defaultOptions);

        var mode = (undefined === $modSlider.parents('.enterprise').get(0)) ? 'consumer' : 'enterprise';

        var viewport; // eslint-disable-line no-unused-vars

        /**
         * seperate equal height script while bxslider is not compatible with the existing one
         */
        var makeEqHeight = function(selector){

            var $elems = $modSlider.find(selector);

            var maxHeight = 0;

            $elems.css('height', '');

            $elems.each(function(){

                if (maxHeight < $(this).height()){
                    maxHeight = $(this).height();
                }

            });

            $elems.height(maxHeight);

        };

        var eqHeight = function(){

            var selectors = $modSlider.data('slider-eq-height');

            if (undefined === selectors || '' === selectors ){
                return;
            }

            selectors = selectors.split(',');

            $(selectors).each(function(index, item){
                makeEqHeight(item);
            });

            // now all items have the same height
            var height = $($modSlider.find(instanceOptions.slideSelector).get(0)).outerHeight();
            $modSlider.find('.bx-viewport').height(height);

            // now the controlls should be centered, again
            var padding = parseInt($modSlider.find('.bx-viewport').css('padding-top'), 10) + parseInt($modSlider.find('.bx-viewport').css('padding-bottom'), 10);

            var $controlls = $modSlider.find('.bx-prev, .bx-next');
            var controllHeight = $controlls.height();
            var offsetTop = ((height + padding) / 2) - (controllHeight / 2);

            $controlls.css({
                'margin-top': '0px',
                'top': offsetTop + 'px'
            });

        };

        /**
         * Getting padding via css is not robust enough
         */
        var getPadding = function(){

            var layout = vf.util.layout(true);

            if ( 'mobile' === layout){
                return 8;
            }
            else if ('tablet' === layout){
                return 16;
            }
            else {
                return 32;
            }

        };

        var handleSlideBefore = function($slideElement, oldIndex, newIndex){

            var pos = $modSlider.find('.bx-mobile-indicator-position').width() * newIndex;

            $modSlider.find('.bx-mobile-indicator-position').css('left', pos + 'px' );
        };


        /**
         * Handler called when carousel is in viewport
         */
        var firstAppearFx = function(){

            $modSlider.addClass('visible');

            // visible but not ready
            if (false === $modSlider.hasClass('ready')){
                window.setTimeout(firstAppearFx, 200);
                return;
            }

            // while there is a css3 transition on .ready this margin-left triggers the fly in animation
            $modSlider.find(instanceOptions.slideSelector).each(function(){
                $(this).css({
                    'margin-left': '0px'
                });

            });

            $modSlider.find('.bx-controls').css({
                display: 'block'
            });

        };

        /**
         *
         */
        var handleSliderLoadAndResize = function(){

            viewport = vf.util.layout(true);

            eqHeight();

            if (false === $modSlider.hasClass('ready')){
                $modSlider.css({
                    height: 'auto',
                    background: 'none'
                });

                // prepare fly in animation by setting a margin
                $modSlider.find(instanceOptions.slideSelector).each(function(){
                    $(this).css({
                        'margin-left': $modSlider.width() + 'px'
                    });
                });

                $modSlider.removeClass('loaded');
                $modSlider.addClass('ready');

                if (true === vf.util.inViewport($(this))){
                    $(this).trigger('slider@in-viewport');
                }
            }

        };

        /**
         * options that are shared for enterprise and consumer
         */
        var getOptions = function(){ // eslint-disable-line no-alert

            instanceOptions = $.extend( {}, defaultOptions);

            var padding = getPadding();
            instanceOptions.slideMargin = padding;

            var layout = vf.util.layout(true);

            if ( 'mobile' === layout){
                instanceOptions.minSlides = 2;
                instanceOptions.maxSlides = 3;
                instanceOptions.slideWidth = parseInt($modSlider.width() / 5 * 2, 10);
            }
            else if ('tablet' === layout){
                instanceOptions.minSlides = 4;
                instanceOptions.maxSlides = 5;
                instanceOptions.slideWidth = parseInt( ($modSlider.width() - 112 - 4 * padding ) / 4, 10);
            }
            else if ('desktop' === layout){
                if ('consumer' === mode){
                    instanceOptions.minSlides = 6;
                    instanceOptions.maxSlides = 6;
                }
                else {
                    instanceOptions.minSlides = 5;
                    instanceOptions.maxSlides = 5;
                }

                instanceOptions.slideWidth = parseInt($modSlider.width() - (padding * (instanceOptions.minSlides - 1) ) / instanceOptions.minSlides, 10);

            }

            instanceOptions.onSliderLoad = handleSliderLoadAndResize;

            instanceOptions.onSliderResize = handleSliderLoadAndResize;

            instanceOptions.onSlideBefore = handleSlideBefore;

            return instanceOptions;

        };

        var updateMobileIndicator = function(){
            var options = getOptions();

            var numberOfSlides = $modSlider.find(options.slideSelector).length;

            $modSlider.find('.bx-mobile-indicator').css({
                width: $modSlider.find('.bx-viewport').width() + 'px',
                left: '10px'
            });

            $modSlider.find('.bx-mobile-indicator-position').width(
                $modSlider.find('.bx-mobile-indicator').width() / Math.ceil(numberOfSlides / options.minSlides)
            );
        };

        var createMobileIndicator = function(){

            $modSlider.find('.bx-controls').after(
                '<div class="bx-mobile-indicator"><div class="bx-mobile-indicator-position"></div></div>'
            );

            updateMobileIndicator();
        };

        /**
         * On window resize reload slider with updatet options
         */
        var updateSlider = function(){

            slider.reloadSlider(
                getOptions()
            );
            updateMobileIndicator();
        };

        /**
         * Create bxslider
         */
        var setUpSlider = function(){

            $modSlider.removeClass('loading');
            $modSlider.addClass('loaded');

            slider = $modSlider.find(' .bxslider').bxSlider(
                getOptions()
            );

            createMobileIndicator();

            $(window).on('vf::resize orientationchange', updateSlider);

        };

        setUpSlider();

        $modSlider.one('slider@in-viewport', firstAppearFx);


    };

    var addData = function(reader, $carousel){

        if (true === $carousel.hasClass('data-none')){
            return;
        }

        var virtualidRegEx = /\?d=([0-9]+)/;

        $carousel.find('.bxslider-item').each(function(){
            var $item = $(this);
            var tariffId = $carousel.data('tariff');

            var virtualid = false;

            var href = $item.find('a').attr('href');

            var guessVirtualId = href.match(virtualidRegEx);
            if (null !== guessVirtualId){
                virtualid = guessVirtualId[1];
            }

            if (false === virtualid){
                var deviceName = $.trim($item.find('.headline').text());
                virtualid = reader.getVirtualIdByDeviceName(deviceName);
            }

            if (false === virtualid || undefined === virtualid){
                $item.remove();
                return true;
            }

            var data = reader.getDeviceDataByVirtualId(tariffId, virtualid);

            $item.find('.img-wrap img').attr(
                'src',
                data.imageSrc
            );

            $item.find('.headline').html(data.name);
            $item.find('.price').html(data.price + ' &euro;');

            return true;

        });


    };

    var getReader = function($carousels){

        var reader = false;

        $carousels.each(function(){

            var $carousel = $(this);

            if (true === $carousel.hasClass('data-none')){
                reader = vf.connect.proxy;
                return false;
            }
            if (true === $carousel.hasClass('data-vlux')){
                reader = vf.connect.vlux;
                return false;
            }
            else if (true === $carousel.hasClass('data-epeng')){
                reader = vf.connect.epeng;
                return false;
            }

            return true;
        });

        return reader;
    };

    var prepare = function($carousels){


        $carousels.each(function(){

            var $carousel = $(this);

            if (true === $carousel.hasClass('data-none')){
                return true;
            }

            var priceInfo = $carousel.data('price_info');

            if (undefined !== priceInfo){
                $carousel.find('.short_text').text(priceInfo);
            }

            var tariffId = $carousel.data('tariff');

            if (undefined === tariffId){
                //console.log('no tariff, no data')
            }

            $carousel.find('.bxslider-item').each(function(){
                $(this).attr('data-tariff', tariffId);
            });


            return true;

        });
    };

    var init = function(){

        var $carousels = $(settings.target);

        prepare($carousels);

        var reader = getReader($carousels);
        if ('vlux' !== reader.getName()){

            reader.initialise()
            .then(function(){

                $carousels.each(function(){

                    var $carousel = $(this);

                    $carousel.addClass('loading');

                    addData(reader, $carousel);
                    createSlider($carousel);

                });
            });

        }
        else {

            var content = 'global';
            if ('data' === $carousels.attr('data-row')){
                content = $carousels.attr('data-row');
            }

            reader.initialise(content)
            .then(function(){
                $carousels.each(function(){

                    var $carousel = $(this);

                    var tariffId = $carousel.attr('data-tariff');
                    var limit = $carousel.find('ul.bxslider').data('limit');

                    var slotmachineHref = $carousel.attr('data-slotmachine-href');
                    reader.getDevices(tariffId, content, slotmachineHref, limit)
                    .then(function(devices){

                        $(devices).each(function(index, device){

                            var $device = $carousel.find('li[data-id="' + device.id + '"]');
                            $device.addClass('resolved');

                            var imgSrc = '//vodafone.de' + device.imageSrc;
                            imgSrc = imgSrc.split('vodafone.de').pop();

                            $device.find('.img-wrap img').attr(
                                'src',
                                '//eweb4.vfd2-testnet.de' + imgSrc
                            );

                            if (true === $carousel.hasClass('data-vlux-data')){
                                //$device.find('.img-wrap').addClass('small');
                                $device.find('.img-wrap').attr(
                                    'height',
                                    '200px'
                                );
                            }


                            $device.find('.headline').html(device.name);
                            $device.find('.price').html(device.price + ' &euro;');

                        });

                        $carousel.find('li:not(.resolved)').remove();

                        createSlider($carousel);

                    });

                });
            });

        }


        var scrollHandler = function(){

            $carousels.each(function(){

                if ( true === $(this).hasClass('visible')){

                    // is there any carousel, that has not loaded?
                    if (0 === $carousels.not('.visible').length){
                        $(window).off('scroll', scrollHandler);
                    }

                    return true;
                }
                if (true === vf.util.inViewport($(this))){
                    $(this).trigger('slider@in-viewport');
                }

                return true;
            });

        };

        $(window).on('scroll', scrollHandler);
        scrollHandler();

    };


    vf['handy-slider'] = {
        settings: settings,
        init: init
    };
}(vf));
