/**
 * @component enjoy-more-overview
 * @author Timo Loeper
 *
 * Javascripts
 */

(function() {
    'use strict';
    var settings = {
        target: '.mod-enjoy-more-overview'
    };

    var createEnjoyMoreOverview = function($overview) {

        var jsonUrl = '/scripts/vodafonepass-applist.json';
        var $overviewResults = $overview.find('.overview-results');

        var jsonItems = {};

        var getJson = function(){
            // !IMPORTANT: check that you can cross domain origin
            return $.getJSON(jsonUrl);
        };

        var showResults = function() {
            $overviewResults.show();
        };

        var insertResults = function(passTab) {
            var passName = 'ChatPass';
            if (undefined !== passTab.data('additional') && '' !== passTab.data('additional')) {
                passName = passTab.data('additional');
            }
            var appendHTML = '';
            $.each(jsonItems.passlist, function(key, pass) {
                if (pass.passname === passName) {
                    $.each(pass.apps, function(passKey, app) {
                        //appendHTML += '<div class="app-logo-wrapper"><div class="logo"><img src="https://eweb4.vfd2-testnet.de' + app.imgLogo + '"></div></div>';
                        appendHTML += '<div class="partnericon-tile-wrapper"><div class="partnericon-tile" style="background-image:url(' + app.imgLogo + ');"></div><div class="partnericon-label">' + app.appname + '</div></div>';
                    });
                }
            });
            $overviewResults.html(appendHTML);
        };

        var getLogos = function() {
            var jsonRequest = getJson();
            jsonRequest.done(function(items) {
                jsonItems = items;
                insertResults($overview.find('.mod-tabs ul li').first());
                showResults();
            });
        };

        var init = function() {
            getLogos();
            $overview.find('.mod-tabs ul li').on('click', function() {
                insertResults($(this));
            });
        };

        init();
    };

    var init = function() {
        $(settings.target).each(function() {
            createEnjoyMoreOverview($(this));
        });
    };

    vf['enjoy-more-overview'] = {
        settings: settings,
        init: init
    };
}(vf));
