(function () {//eslint-disable-line strict

    var hasHerospaceFullsize = false;

    var $herospaceFullsize = null;

    var bgSrc = {
        mobile: false,
        tabletAndDesktop: false
    };

    var setBgImage = function(viewport) {
        if (undefined === viewport || 'string' !== typeof viewport) {
            viewport = vf.util.layout(true);
        }

        var src = ('mobile' === viewport) ? bgSrc.mobile : bgSrc.tabletAndDesktop;

        $herospaceFullsize.css('background-image', 'url(' + src + ')');

    };


    var preflight = (function() { // eslint-disable-line no-unused-vars
        $herospaceFullsize = $('#herospace-fullsize');

        if (undefined === $herospaceFullsize.get(0)) {
            return;
        }

        bgSrc.mobile = $herospaceFullsize.attr('data-bg-image-sml');
        bgSrc.tabletAndDesktop = $herospaceFullsize.attr('data-bg-image-lrg');

        if ($herospaceFullsize && bgSrc.mobile && bgSrc.tabletAndDesktop) {
            hasHerospaceFullsize = true;
        }

        // Add bg image as soon as possible
        vf.util.viewport().done(function(viewport) {
            setBgImage(viewport);
        });

        $('body').addClass('contains-herospace-fullsize');

        $('.content > .belt').remove();
        $('.content > .div-gradient').remove();

    })();

    var reset = function() {
        $('#herospace-fullsize').css({
            top: '',
            height: ''
        });


        $('#herospace-fullsize .content-wrapper').css({
            height: ''
        });


        $('footer').css({
            position: '',
            'top': ''
        });

    };

    var toogleFullscreen = function() {
        reset();

        if ('mobile' === vf.util.layout(true)) {
            return;
        }

        var height = $(window).height();

        var offset = Math.ceil($('header').height());

        $('.linkboxes').css('top', '');

        $('#herospace-fullsize').css({
            top: -1 * (offset - 20) + 'px',  // fuer GN18 20 abgezogen
            height: height + 'px'
        });

        $('#herospace-fullsize .content-wrapper').css({
            height: height + 'px'
        });

        $('footer').css({
            position: 'relative',
            'top': -1 * (offset + 100) + 'px'   // fuer GN18 von 120 auf 100
        });

        $('#top').css({
            'max-height': $('#content').height() + $('footer').height() + 'px',
            'overflow': 'hidden'
        });

        // while top of linkboxes is 65 percent it could be partly out of viewport
        var linkboxesOffset = $('.linkboxes').offset();

        if (linkboxesOffset.top + $('.linkbox').outerHeight() + 30 > height) {
            $('.linkboxes').css('top', $('.linkboxes').position().top + 'px');

            height = (linkboxesOffset.top + $('.linkbox').outerHeight() + 30);

            $('#herospace-fullsize').css({
                'height': height + 'px'
            });

        } else {
            $('.linkboxes').css('top', '');
        }

    };

    var init = function() {

        if (true !== hasHerospaceFullsize) {
            return;
        }

        $(window).on('load vf::resize orientationchange', toogleFullscreen);

        $(window).on('vf::resize orientationchange', setBgImage);
        setBgImage();

    };

    vf['herospace-fullsize'] = {
        settings: {},
        init: init
    };

}(vf));
