/**
 * @component enterprise-progress-bar
 * @author Alexander Rust
 *
 * Javascripts
 */

(function() {
    'use strict';
    // write your code
})();
