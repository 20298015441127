(function() {

    'use strict';

    var settings = {
        target: '.mod-slideshare'
    };

    var resizeFrameProportional = function($frame, proportion){
        $frame.attr('height', ($frame.outerWidth(true) * proportion) + 'px');
    };

    var getFrameRatio = function($slideshare){
        var $frame = $slideshare.find('iframe');
        var frameHeight = 0;
        var frameWidth = 0;

        if ('' !== $slideshare.data('height')){
            // 45 = player interface height
            frameHeight = $slideshare.data('height') + 45;
        } else {
            frameHeight = $frame.attr('height');
        }

        if ('' !== $slideshare.data('width')){
            frameWidth = $slideshare.data('width');
        } else {
            frameWidth = $frame.attr('width');
        }

        return frameHeight / frameWidth;
    };

    var resizeFrame = function(){

        $(settings.target).each(function(index, slideshare){
            var $frame = $(slideshare).find('iframe');

            var ratio = getFrameRatio($(slideshare));

            $frame.attr('width', '100%');

            resizeFrameProportional($frame, ratio);
            $(window).on('resize', function() {
                resizeFrameProportional($frame, ratio);
            });
        });
    };

    var init = function(){
        resizeFrame();
    };

    vf.slideshare = {
        settings: settings,
        init: init
    };
}(vf));
