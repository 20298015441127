/**
 * @component ws2-promoteaser
 * @author Jochen Dreess
 *
 * Javascripts
 */

(function() {
    'use strict';
    // write your code
})();
