/**
 * @component enjoy-more-search
 * @author Timo Loeper
 *
 * Javascripts
 */

(function() {
    'use strict';
    var settings = {
        target: '.mod-enjoy-more-search'
    };

    var createEnjoyMoreSearch = function($search) {

        var jsonUrl = '/scripts/vodafonepass-applist.json';
        var searchResultDIV = $search.find('.search-result');

        var setSelectedResult = function(selectedItem) {
            // searchResultDIV.find('.app-result .app-icon').html('<img src="https://eweb4.vfd2-testnet.de' + selectedItem.appIcon + '">');
            searchResultDIV.find('.app-result .app-name').text(selectedItem.appName);
            var decodedAppDescription = $('<div/>').html(selectedItem.appDescription).text();
            searchResultDIV.find('.app-result .app-description').html(decodedAppDescription);

            searchResultDIV.find('.pass-result .pass-icon').css('display', 'none');
            searchResultDIV.find('.pass-result .pass-icon.i-' + selectedItem.passIdentifier).css('display', 'block');
            searchResultDIV.find('.pass-result .pass-name').html(selectedItem.passName);
            var decodedPassDescription = $('<div/>').html(selectedItem.passDescription).text();
            searchResultDIV.find('.pass-result .pass-description').html(decodedPassDescription);
            searchResultDIV.find('.pass-result .pass-deeplink .pass-deeplink-btn').attr('href', selectedItem.passDeepLink);
        };

        var getCorrectJSONFormat = function(rawJSON){
            var json = [];
            $.each(rawJSON, function( index, pass ) {
                if (undefined !== pass.apps) {
                    $.each(pass.apps, function(indexPass, app){
                        if (undefined !== app.appname) {
                            var passIdentifier = pass.passname.replace('Pass', '').toLowerCase();
                            passIdentifier = passIdentifier.replace('-', '');
                            var newItem = {
                                passName: pass.passname,
                                passIdentifier: passIdentifier,
                                passDescription: pass.description,
                                passIcon: pass.passIcon,
                                passDeepLink: pass.deeplinkURL,
                                appName: app.appname,
                                appDescription: app.description,
                                appIcon: app.imgIcon,
                                appLogo: app.imgLogo
                            };
                            json.push(newItem);
                        }
                    });
                }
            });
            return json;
        };

        var enableAutoComplete = function($input, json) {
            var options = {
                data: getCorrectJSONFormat(json),
                getValue: 'appName',
                list: {
                    match: {
                        enabled: true,
                        method: function(element, phrase) {
                            if (0 === element.search(phrase)) {
                                return true;
                            } else {
                                return false;
                            }
                        },
                    },
                    maxNumberOfElements: 5,
                    onChooseEvent: function() {
                        changeInputButton('search');
                        setSelectedResult($input.getSelectedItemData());
                        searchResultDIV.animate({'opacity': 'show', 'paddingTop': 0}, 2000);
                    }
                },
                template: {
                    type: 'custom',
                    method: function(value, item) {
                        var result = '';
                        var additionalClass = 'no-flag';
                        // not needed, when no icon
                        if (1 === 2 && '' !== item.code) {
                            result = '<img src="http://media.vodafone.de/www/images/app/' + (item.code).toLowerCase() + '_flag.png" width="19px" class="flag">';
                            additionalClass = '';
                        }
                        return result + '<span class="' + additionalClass + '">' + value + '</span>';
                    }
                },

                theme: 'square'
            };

            $input.easyAutocomplete(options);
        };

        var enableInput = function($input) {
            $input.prop('disabled', false);
            $input.removeClass('disabled');
        };

        var getJson = function(){
            // !IMPORTANT: check that you can cross domain origin
            return $.getJSON(jsonUrl);
        };

        var enableSelect = function(){
            var jsonRequest = getJson();
            jsonRequest.done(function(items) {
                enableInput($search.find('.app-search'));
                enableAutoComplete($search.find('.app-search'), items.passlist);
            });
        };

        var removeContent = function() {
            searchResultDIV.find('.app-result .app-icon').html('');
            searchResultDIV.find('.app-result .app-name').text('');
            searchResultDIV.find('.app-result .app-description').html('');

            searchResultDIV.find('.pass-result .pass-name').html('');
            searchResultDIV.find('.pass-result .pass-description').html('');
            searchResultDIV.find('.pass-result .pass-deeplink .pass-deeplink-btn').attr('href', '/#');
        };

        var changeInputButton = function(state) {
            var $svgIconWrapper = $search.find('.search-field .input-button');
            if ('close' === state) {
                // set to search
                $svgIconWrapper.find('.close-icon').hide();
                $svgIconWrapper.find('.group-search').show();
            } else {
                // set to close
                $svgIconWrapper.find('.group-search').hide();
                $svgIconWrapper.find('.close-icon').show();
            }
        };

        var removeButtonClicked = function() {
            $search.find('.search-field input').val('');
            searchResultDIV.fadeOut();
            removeContent();
            changeInputButton('close');
        };

        $search.find('.search-field .input-button').on('click', removeButtonClicked);

        var init = function() {
            enableSelect();
        };

        init();
    };

    var init = function() {
        $(settings.target).each(function() {
            createEnjoyMoreSearch($(this));
        });
    };

    vf['enjoy-more-search'] = {
        settings: settings,
        init: init
    };
}(vf));
