(function() {

    vf['smooth-anchor-scroll'] = {
        settings: {
            target: '.mod-smooth-anchor-scroll'
        },
        init: function(context) {

            var s = this.settings;

            $(s.target, context).addBack(s.target).each(function() {

                if ($(this).attr('href') && 0 < $(this).attr('href').length){
                    $(this).on('click', function(e){
                        e.preventDefault();
                        var targetObj = $( $(this).attr('href') );
                        vf['smooth-anchor-scroll'].scrollTo(targetObj);
                        return false;
                    });
                }
                $(this).find('a[href^=\'#\']').on('click', function(e){
                    e.preventDefault();
                    if ($(this).attr('href') && 0 < $(this).attr('href').length){
                        var targetObj = $( $(this).attr('href') );
                        vf['smooth-anchor-scroll'].scrollTo(targetObj);
                    }
                    return false;
                });
            });

        },
        scrollTo: function(targetObj) {

            var scrollObj = $('html, body');

            if (targetObj && targetObj.offset()){
                var targetOffset = targetObj.offset();
                var newScrollPos = targetOffset.top;
                scrollObj.animate({scrollTop: newScrollPos + 'px'}, 500);
            }

        }
    };

}(vf));
