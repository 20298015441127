(function() {
    'use strict';
    vf['mph-toast'] = {
        settings: {
            target: '.mod-mph-toast',
        },

        init: function(context) {

            var s = this.settings;
            $(s.target, context).addBack(s.target).each(function() {
                var $this = $(this);
                var toastHeight;

                var addToastHeight = function(){
                    toastHeight = $this.css('height');
                    $this.css({ height: toastHeight, bottom: '-' + toastHeight });
                };

                addToastHeight();

                var toast = function(){
                    $this.css({ display: 'block' });
                    $this.animate({
                        bottom: '0px'
                    }, 500);
                };

                setTimeout(function() {
                    toast();
                }, 1000);

                $(this).find('.close-icon').on('click', function() {
                    $this.animate({
                        bottom: '-' + toastHeight
                    }, 500);
                });
            });
        },
    };
}(vf));
