// Disable Tracking for SPA
$(function(){
    'use strict';
    if (window.VF && VF.tealiumUseDCF && !VF.customTracking && 'object' === typeof _ddq) {
        _ddq.push(['pageview', VF]);
    }

    $('a:not(.custom-tracking)').on('click', function() {
        if ($(this).attr('href') && 0 === $(this).attr('href').indexOf('tel:')) {
            var telNumber = $(this).attr('href').replace('tel:', '');
            _ddq.push([
                'hotline cta',
                {
                    'number': telNumber
                }
            ]);
        }
    });
});

