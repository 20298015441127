(function () {
    vf.loginStatus = {

        init: function () {
        },

        // create event polyfill support IE, Firefox
        createNewEvent: function (eventName) {
            var event = null;
            if ('function' === typeof (Event)) {
                event = new Event(eventName);
            } else {
                event = document.createEvent('Event');
                event.initEvent(eventName, true, true);
            }
            return event;
        }
    };
}(vf));

window['login-status-change'] = vf.loginStatus.createNewEvent('login-status-change');
