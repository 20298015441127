(function() {

    'use strict';

    // open a seperate namespace
    vf.connect = {

    };

    // what attributes should be provided form epeng/vlux
    vf.connect.deviceDataPattern = {
        id: undefined,
        name: undefined,
        imageSrc: undefined,
        link: undefined,
        price: undefined,
        activationCharge: undefined,
        monthlyCharge: undefined
    };

    vf.connect.tariffDataPattern = {
        id: undefined,
        subId: undefined,
        name: undefined,
        activationCharge: undefined,
        monthlyCharge: undefined,
        detailLink: undefined
    };

    /* will work only if epeng and vlux is ready*/
    vf.connect.getReader = function(){

        if (undefined !== $('.enterprise').get(0)){
            return vf.connect.epeng;
        }

        return vf.connect.vlux;

    };

    var deviceRegEx = /d=(\d+)/;
    var tariffRegEx = /t=(\d+)/;

    vf.connect.getDeviceIdFromEpengString = function(epengStr){

        var deviceid = deviceRegEx.exec(epengStr);

        if (null !== deviceid && undefined !== deviceid[1]){
            return parseInt(deviceid[1], 10);
        }

        return undefined;

    };

    vf.connect.getTarifIdIdFromEpengString = function(epengStr){

        var tarifid = tariffRegEx.exec(epengStr);

        if (null !== tarifid && undefined !== tarifid[1]){
            return tarifid[1];
        }

        return undefined;

    };

    var $vluxEpengMapping;

    vf.connect.getEpengDeviceIdByVluxVirtualId = function(virtualid){

        if (undefined === $vluxEpengMapping){
            $vluxEpengMapping = $('.vlux-epeng-mapping');

            if (0 === $vluxEpengMapping.length){
                return undefined;
            }
        }

        var $device = $vluxEpengMapping.find('[data-vlux-virtualid=' + virtualid + ']');

        if (0 === $device.length){
            return undefined;
        }

        var epengStr = $device.data('epeng-str');

        var deviceId = vf.connect.getDeviceIdFromEpengString(epengStr);

        return deviceId;

    };

}(vf));
