(function () {

    'use strict';

    var transferUrlParameters = function (index, elem) {
        var whitelist = $(elem).attr('data-allowedparameters');
        if (whitelist) {
            var $iframe = $(elem).find('iframe');
            if ($iframe && 0 < $iframe.length) {
                var iframeUrl = $iframe.attr('src');
                var newUrl = window.vf['url-parameter-parser'].mergeUrls(iframeUrl, window.location.search, whitelist.split(' '));
                if (newUrl !== iframeUrl) {
                    $iframe.prop('src', newUrl);
                }
            }
        }
    };

    var transferUrlParametersForEachIFrame = function () {
        if (!window.vf['url-parameter-parser']) {
            console.warn('Cannot use iframe Parameter transfer because "url-parameter-parser" component was not found');
            return null;
        }
        var selector = vf['iframe-resize'].settings.target;
        $(selector).each(transferUrlParameters);
        return null;
    };


    /**
     * This dependens on
     * https://github.com/davidjbradshaw/iframe-resizer
     *
     * The page that contains the iframe needs a script, wich is in core/vendor
     * And the iframe itself has to contain iframeResizer.contentWindow.min.js
     *
     * also as optional addon it uses the "url-parameter-parser" to transfer GET Parameters to the iframe src on start
     * the "url-parameter-parser" should be included in the core.js and is in assets/javascript/_local
     */
    var init = function () {

        iFrameResize({
            enablePublicMethods: true,
            checkOrigin: false
        });

        transferUrlParametersForEachIFrame();

    };


    vf['iframe-resize'] = {
        settings: {
            target: '.mod-iframe-resize'
        },

        init: init
    };

}(vf));
