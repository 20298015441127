(function() {

    vf['select-input'] = {
        settings: {
            target: '.mod-select-input'
        },

        init: function(context) {
            var s = this.settings;

            $(s.target, context).addBack(s.target).each(function() {

			// store
                var target = $(this),
                    selectBox = target.find('select'),
                    firstOption = selectBox.find('option:first-child');

			// detect select change
                selectBox.on('change', function(){

			// fire function
                    vf['select-input'].optionSelect(target, selectBox);

                });

            });

        },

        optionSelect: function(target, selectBox) {

			// store
            var fmSelectInput 	= target.find('.fm-select-input'),
                input 			= fmSelectInput.find('input');

			// test if last option is selected
            if (0 < selectBox.find('option:selected:last-child').length) {

				// add class to show
                fmSelectInput.addClass('active');

				// add validation
                input.addClass('fm-required');

				// if another option is selected (to remove if visable)
            } else if (fmSelectInput.hasClass('active')) {

				// if yes remove validation
                input.removeClass('fm-required');

				// if yes remove active class
                fmSelectInput.removeClass('active');

            };
        }
    };

}(vf));
