/**
 * @component dropdown-multi-select
 * @author Ali Zidan
 *
 * Javascripts
 */

(function () {
    'use strict';

    var wrappers = document.getElementsByClassName('dropdown-multi-select-wrapper2');

    function hideCard(cards, cardIndex) {

        cards[cardIndex].addEventListener('click', function (event) {

            event.stopPropagation();

            cards[cardIndex].style.display = 'none';
        });
    }

    function appendClickEventForCheckBox(index, wrapperIndex) {

        var container = wrappers[wrapperIndex];

        var checkboxes = container.getElementsByClassName('checkBox');

        var checkbox = checkboxes[index];

        checkbox.addEventListener('click', function (event) {

            event.stopPropagation();

            checkbox = checkboxes[index];

            if (checkbox.classList.contains('checked')) {

                checkbox.classList.remove('checked');
            } else {

                var cardsWrapper = container.parentNode.parentNode.parentNode.getElementsByClassName('cardsWrapper')[0];

                if (cardsWrapper) {

                    cardsWrapper.innerHTML += '<div class="selection from-dropdown">Option {{what ever}} has been added</div>';
                }

                var cards = container.parentNode.parentNode.parentNode.getElementsByClassName('from-dropdown');

                for (var cardIndex = 0; cardIndex < cards.length; cardIndex++) {

                    hideCard(cards, cardIndex);
                }

                checkbox.classList.add('checked');
            }
        });
    }

    function appendListenerToDropDownWrapper(dropDownWrapper) {

        var head = dropDownWrapper.getElementsByClassName('tg-head')[0];

        var body = dropDownWrapper.getElementsByClassName('tg-body')[0];

        if (head && body && !head.classList.contains('tg-active')) {

            head.classList.add('tg-active');

            body.classList.add('tg-display');
        } else if (head && body && head.classList.contains('tg-active')) {

            head.classList.remove('tg-active');

            body.classList.remove('tg-display');
        }
    }

    function appendListenerToWrapper(wrapperIndex) {

        var wrapper = wrappers[wrapperIndex];

        var container = wrapper.getElementsByClassName('scrollContainer')[0];

        var checkboxes = container.getElementsByClassName('checkBox');

        var dropDownWrapper = document.getElementsByClassName('drop-down-warapper')[0];

        dropDownWrapper.addEventListener('click', function (event) {

            event.stopPropagation();

            if (dropDownWrapper) {

                appendListenerToDropDownWrapper(dropDownWrapper);
            }
        });

        var isOpened = false;

        wrapper.addEventListener('click', function (event) {

            event.stopPropagation();

            isOpened = !isOpened;

            if (isOpened) {

                container.classList.add('active');
            } else {

                container.classList.remove('active');
            }
        });

        if (checkboxes && checkboxes.length) {

            for (var checkBoxIndex = 0; checkBoxIndex < checkboxes.length; checkBoxIndex++) {

                appendClickEventForCheckBox(checkBoxIndex, wrapperIndex);
            }
        }

    }

    for (var wrapperIndex = 0; wrapperIndex < wrappers.length; wrapperIndex++) {

        if (wrappers[wrapperIndex]) {

            appendListenerToWrapper(wrapperIndex);
        }
    }
})();

