(function() {

    'use strict';

    vf.shiptostore = {
        settings: {
            target: '.mod-ship-to-store',
        },

        init: function(context) {
            var s = this.settings;
            $(s.target, context).addBack(s.target).each(function() {
                var $this = $(this);

                var pane = $this.find('.scroll-pane');
                pane.jScrollPane();

            });
        },
    };
}(vf));
