(function() {

    'use strict';

    var settings = {
        target: '.mod-herospace2-video',
    };


    var videoSliderCounter = 0;
    var isIOS = /iPad|iPhone|iPod/.test(navigator.platform);
    var isIPhone = /iPhone|iPod/.test(navigator.platform);
    var isIPad = /iPad/.test(navigator.platform);
    var isMobileDevice = navigator.userAgent.match(/Android/i)
        || navigator.userAgent.match(/webOS/i)
        || navigator.userAgent.match(/iPhone/i)
        || navigator.userAgent.match(/iPad/i)
        || navigator.userAgent.match(/iPod/i)
        || navigator.userAgent.match(/BlackBerry/i)
        || navigator.userAgent.match(/Windows Phone/i);

    var layout = vf.util.layout(true);

    var createCanvasVideo = function($herospaceItem, index) {

        // check if content is provided
        if (1 > $herospaceItem.find('.text-box').text().trim().length) {
            $herospaceItem.find('.text-box').remove();
        }

        var resizeHeight = function(){
            layout = vf.util.layout(true);
            var canvasWidth = $herospaceItem.width();
            $herospaceItem.find('.herospace2-video-mobile-canvas').width(canvasWidth);

            $herospaceItem.find('.herospace2-video').height($herospaceItem.height());
            $herospaceItem.find('.herospace2-video').width('auto');

            if ($herospaceItem.width() >= $herospaceItem.find('.herospace2-video').width()) {
                $herospaceItem.find('.herospace2-video').width($herospaceItem.width());
                $herospaceItem.find('.herospace2-video').height('auto');
            }


            $herospaceItem.find('.herospace2-video').css('position', 'absolute');

            resizeMobileTextHeight();
            $herospaceItem.find('.herospace2-video').get(0).play();
        };

        var resizeCanvasHeight = function(){
            layout = vf.util.layout(true);
            resizeMobileTextHeight();
        };

        var resizeMobileTextHeight = function(){
            if ('mobile' === layout || isIPhone) {
                var textBoxHeight = $herospaceItem.find('.hero2-content .text-box').outerHeight();
                var totalHeight = $herospaceItem.height();

                var newHeight = (totalHeight - textBoxHeight);

                $herospaceItem.find('.herospace2-video').css('height', newHeight);
                $herospaceItem.find('.herospace2-video').css('min-height', newHeight);

                $herospaceItem.find('.herospace2-video-mobile-canvas').css('height', newHeight);
                $herospaceItem.find('.herospace2-video-mobile-canvas').css('min-height', newHeight);
            }
        };

        var init = function() {
            if (isIOS) {
                videoSliderCounter++;

                $herospaceItem.find('.herospace2-video').addClass('herospace2-video-index-' + index);
                $herospaceItem.find('.herospace2-video-mobile-canvas').addClass('herospace2-canvas-video-index-' + index);

                if ('undefined' !== typeof CanvasVideoPlayer) {

                    var canvasVideo = new CanvasVideoPlayer({
                        videoSelector: '.herospace2-video-index-' + index,
                        canvasSelector: '.herospace2-canvas-video-index-' + index,
                        autoplay: true,
                        makeLoop: true,
                        pauseOnClick: false,
                        audio: false,
                        loop: true
                    });
                    if (isIPad) {
                        if (1 === videoSliderCounter) {
                            setInterval(function(){
                                //$('.herospace2-video-index-' + index)[0].play();
                                canvasVideo.play();
                            }, 6000);
                        } else {
                            $(window).on('vf::herospace2-item-slide', function(){
                                //$('.herospace2-video-index-' + index)[0].play();
                                canvasVideo.play();
                            });
                        }
                    }

                    $(window).on('vf::herospace2-item-resize vf::resize orientationchange', resizeCanvasHeight);

                }

            } else {
                // Use HTML5 video
                $herospaceItem.find('.herospace2-video-mobile-canvas').css('display', 'none');
                $(window).on('vf::herospace2-item-resize vf::resize orientationchange', resizeHeight);

                var canvasWidth = $herospaceItem.width();
                $herospaceItem.find('.herospace2-video-mobile-canvas').width(canvasWidth);
                $herospaceItem.find('.herospace2-video').height($herospaceItem.height());
                $herospaceItem.find('.herospace2-video').width('auto');
            }

            if (isMobileDevice) {
                $herospaceItem.find('.video-responsive').find('video').hide(); // hide video by default
                $herospaceItem.find('.video-responsive').find('canvas').hide(); // hide canvas by default

                var fallback;

                var imgFallback = $herospaceItem
                    .find('.video-responsive')
                    .find('video')
                    .find('source')
                    .attr('data-img-fallback');

                if (imgFallback) {
                    if (0 < imgFallback.length) {
                        fallback = imgFallback;
                    }
                }

                var gifFallback = $herospaceItem
                    .find('.video-responsive')
                    .find('video')
                    .find('source')
                    .attr('data-gif-fallback');

                if (gifFallback) {
                    if (0 < gifFallback.length) {
                        fallback = gifFallback;
                    }
                }

                $herospaceItem.find('.video-responsive').css({
                    height: '100%'
                });

                $herospaceItem.find('.video-responsive').append(
                    $('<div />').css({
                        backgroundImage: 'url(' + fallback + ')',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        width: '100%',
                        height: '100%'
                    }).addClass('herospace2-video-fallback')
                );
            }
        };
        init();
    };

    var init = function() {
        $(settings.target).each(function(index, herospaceItem) {
            createCanvasVideo($(herospaceItem), index);
        });
    };

    vf['herospace2-video'] = {
        settings: settings,
        init: init
    };

}(vf));
