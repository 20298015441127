(function () {//eslint-disable-line strict

    var settings = {
        target: '.mod-carousel-three',
        itemsPerPage: 3,
        bxOptions: {
            minSlides: 2,
            maxSlides: 3,
            infiniteLoop: false,
            hideControlOnEnd: true,
            slideWidth: 0,
            slideMargin: 0,
            moveSlides: 1,
            slideSelector: 'div.slide',
            adaptiveHeight: false
        }
    };

    var createSlider = function($modSlider) {

        var slider;

        var options = $.extend({}, settings.bxOptions);

        // if only three or less slides -> don't use a slider
        if (3 >= $modSlider.find(options.slideSelector).length) {
            return;
        }

        var makeEqHeight = function(selector) {

            var $elems = $modSlider.find(selector);

            var maxHeight = 0;

            $elems.css('height', '');

            $elems.each(function() {

                if (maxHeight < $(this).height()) {
                    maxHeight = $(this).height();
                }

            });

            $elems.height(maxHeight);

        };

        var eqHeight = function() {
            var selectors = $modSlider.data('slider-eq-height');

            if (undefined === selectors || '' === selectors) {
                return;
            }

            selectors = selectors.split(',');


            $(selectors).each(function(index, item) {
                makeEqHeight(item);
            });

            $modSlider.find('.bx-viewport').height($($modSlider.find(options.slideSelector).get(0)).outerHeight());
        };

        var getOptions = function() {

            /*
                items width and margin is caluctated by getting css grid's properties
                while these are disabled when bxslider is active, we need to actived them, do calc and deactived them again
            */

            options.slideWidth = getItemWidth();
            options.slideMargin = getItemMargin();

            // in desktop always three tiles are displayed and three will moved together
            if ('desktop' === vf.util.layout(true)) {

                options.minSlides = settings.itemsPerPage;
                options.maxSlides = settings.itemsPerPage;
                options.moveSlides = settings.itemsPerPage;

            }
            // in table always two tiles are displayed complete and one is cut; move slide by one tile each step
            else if ('tablet' === vf.util.layout(true)) {

                options.minSlides = settings.itemsPerPage - 1;
                options.maxSlides = settings.itemsPerPage;
                options.moveSlides = 1;

            }

            // in table always one tiles are displayed complete and one is cut; move slide by one tile each step
            else if ('mobile' === vf.util.layout(true)) {

                options.minSlides = 1;
                options.maxSlides = 2;
                options.moveSlides = 1;

            }


            options.onSliderLoad = function() {
                eqHeight();
            };

            return options;
        };

        var getSliderWidth = function() {
            return $modSlider.width();
        };

        var getItemWidth = function() {

            if ('desktop' === vf.util.layout(true)) {

                var width = (getSliderWidth() - (settings.itemsPerPage - 1) * getItemMargin()) / settings.itemsPerPage;
                return parseInt(width, 10);
            }

            if ('tablet' === vf.util.layout(true)) {
                var width = $modSlider.width() / 100 * 80;

                width = (width - 2 * getItemMargin()) / 2;

                return parseInt(width, 10);
            }

            if ('mobile' === vf.util.layout(true)) {
                var width = $modSlider.width() / 100 * 80;

                width = (width - getItemMargin());

                return parseInt(width, 10);
            }

            return (getSliderWidth() - (settings.itemsPerPage - 1) * getItemMargin()) / settings.itemsPerPage;
        };

        var getItemMargin = function() {
            return 20;

        };

        var setUpSlider = function() {
            $modSlider.addClass('active');

            slider = $modSlider.find(' .bxslider').bxSlider(
                getOptions()
            );
        };

        var updateSlider = function() {

            slider.reloadSlider(
                getOptions()
            );

        };

        $(window).on('vf::resize orientationchange', updateSlider);
        setUpSlider();

    };


    var init = function() {

        $(settings.target).each(function() {
            createSlider($(this));
        });
    };

    vf['carousel-three'] = {
        settings: settings,
        init: init
    };

}(vf));
