/**
 * read file from certain path and returns a JSON file
 * @param {string} file 
 * @param {*} callback 
 */
function readTextFile(file, callback) {
  var rawFile = new XMLHttpRequest();
  rawFile.overrideMimeType("application/json");
  rawFile.open("GET", file, true);
  rawFile.onreadystatechange = function () {
    if (rawFile.readyState === 4 && rawFile.status == "200") {
      callback(rawFile.responseText);
    }
  }
  rawFile.send(null);
}
/**
 * check if the user is logged in from a mobile device or tablet
 * @returns boolean
 */
function isMobileTablet() {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return true;
  }
  else if (/Mobile|Android|webOS|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
    return true;
  }
  return false;
}
/**
 * check if the user is logged in from an iPad
 * @returns boolean
 */
function isIpad() {
  const ua = window.navigator.userAgent;
  if (ua.indexOf('iPad') > -1) {
      return true;
  }
  if (ua.indexOf('Macintosh') > -1) {
      try {
          document.createEvent("TouchEvent");
          return true;
      } catch (e) {}
  }

  return false;
}
/**
 * read CMS json file from path depending on the environment
 * Then check if the user is logging in from the web or app and redirecting accordingly
 * @param {string} fileName 
 */
function redirectionToGenericLink(fileName) {
  var hostName = window.location.hostname;
  var filePath;
  if (hostName.includes("vodafone.de")) {
    filePath = "https://" + hostName + "/config/universalUrls-deWording.json";
  }
  else {
    filePath = "https://" + hostName + "/simplicity/apps/ecare/config/universalUrls-deWording.json";
  }
  readTextFile(filePath, function (text) {
    var data = JSON.parse(text);
    if (isMobileTablet() || isIpad()) {
      window.location.href = data[fileName].app['std-headline'];
    }
    else {
      window.location.href = data[fileName].web['std-headline'];
    }
  });
};