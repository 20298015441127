(function() {

    vf['text-limit'] = {
        settings: {
            target: '.mod-text-limit'
        },

        init: function(context) {
            var s = this.settings;

            $(s.target, context).addBack(s.target).each(function() {

                $(this).ready($.proxy(vf['text-limit'].charCount, this)());

            });

        },

        charCount: function() {

			// store
            var module = $(this),
                target = module.find(module.data('text-target')),
                limit = module.data('text-limit');

			// loop over target(s)
            target.each(function(i) {

				// count
                var count = $(target[i]).text().length;

				// if more than limit
                if (count > limit) {

					// remove and add dots
                    $(target[i]).text($(target[i]).text().substr(0, limit)).append('...');

                };

            });

        }
    };

}(vf));
