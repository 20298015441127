( function() {
    'use strict';
    var settings = {
        target: '.mod-callback',
        itemsPerPage: 1,
        now: new Date(),
        key: '1475236069158', /* the encryption code timestamp */
        fromMail: 'JNqRBNMTlZKDDBNPEkAPH', /*
             * the sender is needed for the
             * service
             */
        toMail: 'GNPT[IUiJT]CnRNFACMO\'oGKM', /* the receptient for the mail */
        postUrl: 'DSVPPoxrXaSnYKCCFLLFiEIsTDBvEOKRB[U', /*
             * the form action
             * target
             */
        charSet: 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789@%,.-;:!()=?/+*|{}[]\'',
            /* the allowed charset for the decryption */
            /* crypt method to enable generating new key value pairs */
            /*
             * crypt : function(data) { var value = data; var key =
             * vf["mod-callback"].settings.key; var masked = ""; var txtPos,
             * txtPosS, summary, i; while (key.length < value.length) { key = key +
             * key; } for ( i = 0; i < value.length; i++) { txtPos =
             * this.charSet.indexOf(value.charAt(i)); txtPosS =
             * this.charSet.indexOf(key.charAt(i)); if ((txtPos > -1) && (txtPosS >
             * -1)) { summary = txtPos + txtPosS; if (summary >=
             * this.charSet.length) { summary = summary - this.charSet.length; }
             * masked = masked + this.charSet.charAt(summary); } else { masked =
             * masked + "_"; } } return masked; },
             */
            /* decryption of the given values */
        decrypt: function(data) {
            var Vtext = data;
            var key = vf.callback.settings.key;
            var encoded = '';
            var txtPos,
                txtPosS,
                summary,
                i;
            while (key.length < Vtext.length) {
                key = key + key;
            }
            for ( i = 0; i < Vtext.length; i++) {
                txtPos = this.charSet.indexOf(Vtext.charAt(i));
                txtPosS = this.charSet.indexOf(key.charAt(i));
                if ((-1 < txtPos) && (-1 < txtPosS)) {
                    summary = txtPos - txtPosS;
                    if (0 > summary) {
                        summary = summary + this.charSet.length;
                    }
                    encoded = encoded + this.charSet.charAt(summary);
                } else {
                    encoded = encoded + ' ';
                }
            }
            return encoded;
        }
    };
        /** form sent required fields are in the form form[name="callbackForm"]') */
    var sendmail = function() {
        var tel1 = $('#Telefonnummer').val(),
            tel2 = $('#callbacknummer').val();
        $('#Telefonnummer,#callbacknummer').remove();
        $('#myM').val('Vodafone-Rufnummer:' + tel1 + '\r\nRueckrufnummer:' + tel2);

        $.post(vf.callback.form.attr('action'), vf.callback.form.serialize()).success(function(d) {
            vf.callback.displayResult(d);
        });
        return true;
    };
        /** function to show and hide unfitting contents */
    var checkAppearance = function() {
            // console.log(this);
        var now = this.settings.now;
        var weekday = now.getDay();
        var from = new Date(now.getFullYear(), now.getMonth(), now.getDate(), $('#bHourFrom').val().split(':')[0], $('#bHourFrom').val()
            .split(':')[1], $('#bHourFrom').val().split(':')[2]);
        var to = new Date(now.getFullYear(), now.getMonth(), now.getDate(), $(
            '#bHourTo').val().split(':')[0], $('#bHourTo').val().split(':')[1], $('#bHourTo').val().split(
            ':')[2]);
        ;
        var f = $('form[name="callbackForm"]');
        if (now >= from && now <= to && 0 !== weekday) {
            $('.callback-inactive').remove();
            this.preSetValues();
        } else {
            $('.callback-inactive').removeClass('displayNone');
            $('.callback-active, form[name="callbackForm"]').remove();
        }
        return true;
    };
        /** updating the form fields before execution of any code */
    var preSetValues = function() {
        $('input[name="Mitteilung"]').attr('id', 'myM');
        $('form[name="callbackForm"] input[name="To"]').val(this.settings.decrypt(this.settings.toMail));
        $('form[name="callbackForm"] input[name="From"]').val(this.settings.decrypt(this.settings.fromMail));
        $('form[name="callbackForm"]').attr('action', this.settings.decrypt(this.settings.postUrl));
        if (0 === $('form[name="callbackForm"] input[name="Subject"]').val().length) {
            $('form[name="callbackForm"] input[name="Subject"]').val($('meta[name="hierarchyNames"]').prop('content'));
        }
        $('head').append('<style type="text/css">.mod-callback .tg-label{position:relative;}   .ng-invalid {    background: #fef0f0 none repeat scroll 0 0;}.ng-touched.ng-invalid {   border: 1px solid #e60000;}.fm-formerror{width:90%;float:none;padding-top:5px;position:relative;}.fm-formerror svg{float:left; padding-right: 5px;}.fm-formerror p{color: red;}');
        return true;
    };
    var displayResult = function(d) {
        $('.callback-active').html(d.content);
        return true;
    };
        /**
         * base method to init all behaviour and apply validation for any form field
         * where it is required
         */
    var createcallback = function($modcallback) {
        var callback = vf.callback;
        var options = $.extend({}, settings);
        var getOptions = function() {
            return options;
        };
        var t = '#Telefonnummer';
        var b = '#callbacknummer';
        var bHf = '#bHourFrom';
        var bHt = '#bHourTo';
        var now = new Date();
        vf.callback.form = $('form[name="callbackForm"]');
        var checkValid = function(o) {
            if ($(o).data('pattern')) {
                var p = new RegExp($(o).data('pattern'));
                if (!p.test($(o).val())) {
                    $(o).addClass('ng-invalid');
                    $(o).next('.fm-formerror').removeClass('displayNone');
                    return false;
                } else {
                    $(o).removeClass('ng-invalid');
                    $(o).next('.fm-formerror').addClass('displayNone');
                    return true;
                }
            }
        };

        var addtouched = function(d) {
            $(d).addClass('ng-touched');
        };
        $('#Telefonnummer').on('keyup', function() {
            addtouched(this);
        });
        $('#callbacknummer').on('keyup', function() {
            addtouched(this);
        });
        $('#Telefonnummer').on('blur', function(d) {

            if (0 < $(this).val().length) {
                if (checkValid(this)) {
                    $('#cbmBtn').attr('disabled', false);
                } else {
                    $('#cbmBtn').attr('disabled', 'disabled');
                }
            }
        });
        $('#cbmBtn').on('click', function(e) {
            var form = $('form[name="callbackForm"]');
            if ($('#Telefonnummer').data('pattern')) {
                var p = new RegExp($(t).data('pattern'));
                if (!p.test($(t).val())) {
                    $('#Telefonnummer').addClass('ng-invalid');
                    return false;
                }
            }
            if (0 < $('#callbacknummer').val().length) {
                var p = new RegExp($(b).data('pattern'));
                if (!p.test($('#callbacknummer').val())) {
                    $('#callbacknummer').addClass('ng-invalid');
                    $('#callbacknummer').next('.fm-formerror').removeClass('displayNone');
                    return false;
                } else {
                    $('#callbacknummer').next('.fm-formerror').addClass('displayNone');
                }
            }
            vf.callback.sendmail();
                // console.log(vars);
        });
        var setUpcallback = function() {
            $modcallback.addClass('active');
            callback.checkAppearance();
        };
        setUpcallback();
    };
    var init = function() {
        $(settings.target).each(function(i, o) {
            createcallback($(o));
        });
    };

    vf.callback = {
        settings: settings,
        init: init,
        sendmail: sendmail,
        checkAppearance: checkAppearance,
        preSetValues: preSetValues,
        displayResult: displayResult

    };

}(vf));
