(function() {
    'use strict';
    vf['mph-hero'] = {
        IMAGE_WIDTH_DESKTOP: 2560,
        IMAGE_HEIGHT_DESKTOP: 432,
        IMAGE_WIDTH_MOBILE: 599,
        IMAGE_HEIGHT_MOBIL: 816,
        MAX_MOBILE_WIDTH: 599,

        settings: {
            target: '.mod-mph-hero',
        },

        init: function() {

            var that = this;
            window.addEventListener('resize', function (){
                that.updateNowAndLater();
            });
            window.addEventListener('load', function () {
                that.updateNowAndLater();
            });
            that.updateNowAndLater();
        },

        /**
         * update now
         */
        updateNowAndLater: function () {
            var that = this;
            that.updateSlides();
            setTimeout(function() {
                that.updateSlides();
            }, 500);
        },

        /**
         * slide update hanlder
        */
        updateSlides: function (){

            var sliderHeight = document.querySelector(this.settings.target).clientHeight;

            var allSlides = document.querySelectorAll('.mph-hero');

            for (var i = 0; i < allSlides.length; i++){
                if (allSlides[i].classList.contains('apple')){

                    this.updateAppleSlide(allSlides[i], sliderHeight);

                } else {
                    this.updateRegularSlide(allSlides[i], sliderHeight);
                }

            }
        },

        /**
         *
         * @param {*} regularSlide
         * @param {*} sliderHeight
         */
        updateRegularSlide: function (regularSlide, sliderHeight){

            var badgesBoxes = regularSlide.querySelectorAll('.badges-box');

            if (!badgesBoxes){
                return;
            }

            for (var i = 0; i < badgesBoxes.length; i++){
                var myBox = badgesBoxes[i];

                var localPosBadge = this.globalPosToLocalPos({
                    leftDesktop: myBox.getAttribute('data-badge-desktop-x'),
                    topDesktop: myBox.getAttribute('data-badge-desktop-y'),
                    leftMobile: myBox.getAttribute('data-badge-mobile-x'),
                    topMobile: myBox.getAttribute('data-badge-mobile-y'),
                }, sliderHeight);

                localPosBadge = this.adjustPositionigDependingOnMovePoint(myBox, localPosBadge, myBox.getAttribute('data-badge-move-point-desktop'), myBox.getAttribute('data-badge-move-point-mobile'));


                if (localPosBadge){
                    myBox.style.left = localPosBadge.left + 'px';
                    myBox.style.top = localPosBadge.top + 'px';
                }
            }
        },

        /**
         * Helper method to calculate local form global positon
         * @param {object} gloablPosition - object conating a top and left property
         * @param {number} sliderHeight - the current height of the slider
         * @return {object} object conating a top and left property
         */
        globalPosToLocalPos: function(gloablPosition, sliderHeight) {
            var scaleFactor = 1;
            var innerWidth = window.innerWidth;
            //var width = document.body.scrollWidth;
            var width = document.body.scrollWidth <= this.IMAGE_WIDTH_DESKTOP ? document.body.scrollWidth : this.IMAGE_WIDTH_DESKTOP;
            var height = sliderHeight;
            var localTop;
            var localLeft;

            if (this.MAX_MOBILE_WIDTH < innerWidth) {

                // if small tablet
                if (768 > width) {
                    scaleFactor = height / this.IMAGE_HEIGHT_DESKTOP;
                }

                var globalCenterX = this.IMAGE_WIDTH_DESKTOP / 2;
                var globalCenterY = this.IMAGE_HEIGHT_DESKTOP / 2;

                var globalFromCenterX = (gloablPosition.leftDesktop - globalCenterX) * scaleFactor;
                var globalFromCenterY = (gloablPosition.topDesktop - globalCenterY) * scaleFactor;
                localTop = height - (height / 2 - globalFromCenterY);
                localLeft = width - (width / 2 - globalFromCenterX);
            } else {

                // mobile
                scaleFactor = width / this.IMAGE_WIDTH_MOBILE;
                localTop = gloablPosition.topMobile * scaleFactor;
                localLeft = gloablPosition.leftMobile * scaleFactor;
            }
            // return positon object
            if (gloablPosition.leftDesktop && gloablPosition.topDesktop ){
                return { 'top': localTop, 'left': localLeft };
            }
            return undefined;
        },


        /** adjustPositionigDependingOnMovePoint */
        adjustPositionigDependingOnMovePoint: function (appleContainer, localPos, movePointDesktop, movePointMobile ){

            var containerWidth = appleContainer.offsetWidth;
            var containerHeight = appleContainer.offsetHeight;
            // user here inner width
            var width = window.innerWidth;

            if (this.MAX_MOBILE_WIDTH < width) {
                        // desktop
                this.adjustPositions(movePointDesktop, localPos, containerWidth, containerHeight);
            } else {
                        // mobile
                this.adjustPositions(movePointMobile, localPos, containerWidth, containerHeight);
            }
            return localPos;
        },

        /** helper for above  */
        adjustPositions: function (mp, localPos, containerWidth, containerHeight ){
            switch (mp){
            case 'tl':
                // do nothing (default);
                break;
            case 'tc':
                localPos.left -= containerWidth / 2;
                break;
            case 'tr':
                localPos.left -= containerWidth;
                break;
            case 'ml':
                localPos.top -= containerHeight / 2;
                break;
            case 'mc':
                localPos.top -= containerHeight / 2;
                localPos.left -= containerWidth / 2;
                break;
            case 'mr':
                localPos.top -= containerHeight / 2;
                localPos.left -= containerWidth;
                break;
            case 'bl':
                localPos.top -= containerHeight;
                break;
            case 'bc':
                localPos.top -= containerHeight;
                localPos.left -= containerWidth / 2;
                break;
            case 'br':
                localPos.top -= containerHeight;
                localPos.left -= containerWidth;
                break;
            }
            return localPos;
        },

        // only for Apple slides

        /** positioning and button alignment for a singel slide */
        updateAppleSlide: function (appleSlide, sliderHeight){

            var badgesBox = appleSlide.querySelector('.apple-container');
            if (!badgesBox){
                return;
            }

            // read global positioning
            var localPos = this.globalPosToLocalPos({
                leftDesktop: appleSlide.getAttribute('data-container-desktop-x'),
                topDesktop: appleSlide.getAttribute('data-container-desktop-y'),
                leftMobile: appleSlide.getAttribute('data-container-mobile-x'),
                topMobile: appleSlide.getAttribute('data-container-mobile-y'),
            }, sliderHeight);

            // apple container
            var appleContainer = appleSlide.querySelector('.apple-container');

            // align buttons
            this.alignButtons(appleContainer.querySelectorAll('.ws2-btn'), 'true' === appleSlide.getAttribute('data-stack-buttons-desktop'), 'true' === appleSlide.getAttribute('data-stack-buttons-mobile') );

            // adjust postioning depending on the move point
            localPos = this.adjustPositionigDependingOnMovePoint(appleContainer, localPos, appleSlide.getAttribute('data-move-point-desktop'), appleSlide.getAttribute('data-move-point-mobile'));

            //
            appleContainer.style.left = localPos.left + 'px';
            appleContainer.style.top = localPos.top + 'px';


            appleContainer.style.opacity = '1';

        },

        /**
         * helper function
         * @param {*} buttons
         * @param {*} stackDesktop
         * @param {*} stackMobile
         */
        alignButtons: function (buttons, stackDesktop, stackMobile){
            var s = this;
            var myButtonWidth = 0;

            for (var i = 0; i < buttons.length; i++){
                //var desktopViewPort = s.MAX_MOBILE_WIDTH < document.body.clientWidth;
                var desktopViewPort = s.MAX_MOBILE_WIDTH < window.innerWidth;

                if (!desktopViewPort && stackMobile || desktopViewPort && stackDesktop) {
                    buttons[i].style.display = 'inherit';
                    if (buttons[i].offsetWidth > myButtonWidth ){
                        myButtonWidth = buttons[i].offsetWidth;
                    }
                } else {
                    buttons[i].style.display = 'inline-block';
                    buttons[i].style.width = 'initial';
                    buttons[i].style.marginBottom = '0px';
                }
            }
            // remove the button margin if there is only one button
            if (1 === buttons.length) {
                buttons[0].style.marginRight = '0px';
            } else {
                if (!desktopViewPort && stackMobile || desktopViewPort && stackDesktop) {
                    buttons[0].style.marginBottom = '10px';
                }
            }

            // make the buttons have the same width
            if (!desktopViewPort && stackMobile || desktopViewPort && stackDesktop) {

                for (var i = 0; i < buttons.length; i++){
                    buttons[i].style.textAlign = 'center';
                    buttons[i].style.width = '100%';
                }
            }
        },
    };
}(vf));
